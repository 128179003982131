import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate /* useLoaderData */ } from 'react-router-dom';
import Select from 'react-select';

import Page from '../components/Page';
import useDataService from '../hooks/useDataService';
import {
  Input,
  InputLine,
  ModalButton,
  ModalButton2,
} from '../components/modals/Modal';
import ItemPhoto from '../components/modals/ItemPhoto';
import { classNames, SELECT_PROPS, sortObjNameCaseIns } from '../utils';
import { nameToElementMap, TopBackButton } from '../components/icons';
import { useForm } from '../hooks/useForms';

export function ItemNotFound({ errorCode = 404 }) {
  return (
    <Page className="px-5 py-6 flex flex-col">
      <div className="shrink-0">
        <TopBackButton />
      </div>
      <div className="flex-1 flex flex-col items-center justify-center">
        <div
          className="top-0 w-64 h-64 rounded-md bg-no-repeat bg-center bg-cover"
          style={{ backgroundImage: 'url(/notFound.png)' }}
        />
        <h6 className="text-2xl font-medium mb-3 text-indigo-400">Oops!</h6>
        <p className="text-indigo-900 dark:text-indigo-100">
          Sorry, Item not found.
        </p>
        <p className="text-lg mt-3 font-bold opacity-70 text-indigo-300 dark:text-indigo-400">
          {errorCode}
        </p>
      </div>
    </Page>
  );
}

export default function Item() {
  const navigate = useNavigate();
  const {
    state,
    deleteUploadedImage,
    addItem: editItem,
    deleteItem,
    isPageLoading,
    addMessage,
  } = useDataService();
  const { itemId } = useParams();
  const [editView, setEditView] = useState(false);
  // const { contacts } = useLoaderData();

  const item = useMemo(() => {
    return state.items?.[itemId];
  }, [state.items, itemId]);

  const containers = useMemo(() => {
    const _containers = Object.values(state.containers || {});
    _containers.sort(sortObjNameCaseIns);
    return _containers;
  }, [state.containers]);

  const [
    updatedItem,
    setUpdatedItem,
    handleChange,
    handleImageChange,
    setInitialItem,
  ] = useForm(item, 'image');

  useEffect(() => {
    if (!updatedItem && item) setInitialItem(item);
  }, [updatedItem, item, setInitialItem]);

  const resetItem = () => {
    setUpdatedItem(item);
    setEditView(false);
  };

  const onSaveImage = async (imageUrl) => {
    if (imageUrl) {
      // delete old image
      await deleteUploadedImage(item.image);

      // update item with new image
      const _updatedItem = { ...updatedItem, image: imageUrl };
      editItem(_updatedItem);
      setUpdatedItem(_updatedItem);
      // addMessage('Image uploaded and updated', 200);
    }
  };

  const onSave = async () => {
    editItem({ ...updatedItem, updatedAt: +Date.now() });
    setEditView(false);
    navigate(-1);
  };
  const handleChangeBox = (selected) => {
    setUpdatedItem({
      ...updatedItem,
      container: selected?.id || '',
      lastMoved: +Date.now(),
    });
    setEditView(true);
  };

  const handleDelete = () => {
    const onConfirm = () => {
      if (item) deleteItem(item);
      navigate(-1);
    };
    addMessage({
      message: 'Are you sure to delete this item? You cannot undo this.',
      onConfirm,
      confirmText: 'Delete',
    });
  };

  if (isPageLoading) {
    return <Page className="px-5 py-6 flex flex-col" />;
  }

  if (!item) {
    return <ItemNotFound />;
  }

  const _container = state.containers?.[updatedItem?.container];
  const Icon = nameToElementMap[_container?.icon];

  return (
    <Page className="px-5 max-w-screen-md w-full mx-auto">
      <div className="sticky top-6 z-[2]">
        <TopBackButton />
      </div>
      <h1
        className={classNames(
          'sticky top-0 z-[1] text-lg pb-6 pt-6 font-bold text-center',
          'bg-white dark:bg-gray-900 dark:text-gray-200'
        )}
      >
        Item Details
      </h1>
      <div className="mt-1 mb-4 flex gap-4">
        <ModalButton2 red onClick={handleDelete}>
          Delete
        </ModalButton2>
      </div>
      <InputLine label="Image Capture">
        <ItemPhoto
          name={'image'}
          value={updatedItem?.image || ''}
          onChange={handleImageChange}
          onSave={onSaveImage}
          editMode
        />
      </InputLine>
      <InputLine label="Item name" htmlFor="name">
        <Input
          id="name"
          name="name"
          value={updatedItem?.name || ''}
          onChange={handleChange}
          placeholder="Enter the item name"
          viewMode={!editView}
          required
        />
      </InputLine>

      <InputLine label="Notes" htmlFor="notes">
        <Input
          id="notes"
          name="notes"
          type="textarea"
          value={updatedItem?.notes || ''}
          onChange={handleChange}
          placeholder="Other details about this item"
          emptyPlaceholder="No notes"
          viewMode={!editView}
        />
      </InputLine>

      <InputLine label="Item stored in" htmlFor="container">
        {editView ? (
          <div className="mt-2">
            <Select
              className="basic-single"
              isDisabled={false}
              name="container"
              inputId="container"
              options={containers}
              value={_container}
              onChange={handleChangeBox}
              placeholder={editView ? 'Select a box' : 'No box selected'}
              {...SELECT_PROPS}
            />
          </div>
        ) : _container ? (
          <div className="flex flex-wrap gap-2 mt-2">
            <Tag {..._container} active={_container.id}>
              {Icon && <Icon className="w-5 h-5 ml-1 mr-2" />}
              <span className="mr-1">{_container.name}</span>
            </Tag>
          </div>
        ) : (
          <div
            className={classNames(
              'mt-1 py-2 text-lg border-b dark:text-gray-100 dark:border-gray-700',
              'italic text-gray-400'
            )}
          >
            {updatedItem?.container
              ? updatedItem?.container
              : 'Not inside a box'}
          </div>
        )}
      </InputLine>

      {/*<InputLine label="Tags" htmlFor="tags">*/}
      {/*  <Input*/}
      {/*    id="tags"*/}
      {/*    name="tags"*/}
      {/*    value={updatedItem?.tags || ''}*/}
      {/*    onChange={handleChange}*/}
      {/*    placeholder="Enter tags separated by commas"*/}
      {/*    emptyPlaceholder="No tags"*/}
      {/*    viewMode={!editView}*/}
      {/*  />*/}
      {/*</InputLine>*/}

      <div
        className={classNames(
          'flex gap-4 sticky -bottom-2 py-4 px-5 -mx-5 -mb-4'
          // 'bg-gradient-to-t from-black/10 to-black/0'
        )}
      >
        {editView ? (
          <>
            {' '}
            <ModalButton green onClick={onSave}>
              Update
            </ModalButton>
            <ModalButton2 onClick={resetItem}>Cancel</ModalButton2>
          </>
        ) : (
          <ModalButton blue onClick={() => setEditView(true)}>
            Edit
          </ModalButton>
        )}
      </div>
    </Page>
  );
}

export function Tag({
  children,
  active,
  onClick = () => {},
  name,
  id,
  className,
}) {
  return (
    <div
      onClick={onClick(id)}
      tabIndex={-1}
      className={classNames(
        'p-1.5 border rounded-lg inline-flex items-center justify-center',
        (typeof active === 'function' ? active() : active === id)
          ? 'text-orange-400 border-orange-500 dark:text-orange-500 dark:border-orange-400 hover:bg-orange-100 dark:hover:bg-orange-900'
          : 'text-gray-700 border-gray-400 dark:text-gray-500 dark:border-gray-500 hover:bg-gray-100 dark:hover:bg-gray-800',
        className
      )}
    >
      {children}
    </div>
  );
}

export const OldContainers = ({
  containers,
  updatedItem,
  setUpdatedItem,
  setEditView,
}) => {
  const handleChangeContainer = (cId) => () => {
    setUpdatedItem({
      ...updatedItem,
      container: updatedItem.container === cId ? '' : cId,
    });
    setEditView(true);
  };

  return (
    <div className="flex flex-wrap gap-2 mt-2">
      {containers.map((c) => (
        <Tag
          key={c.id}
          name={c.name}
          id={c.id}
          active={updatedItem?.container}
          onClick={handleChangeContainer}
        >
          {c.name}
        </Tag>
      ))}
    </div>
  );
};
