import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useLoaderData, useLocation } from 'react-router-dom';

import Page from '../components/Page';
import useDataService from '../hooks/useDataService';
import AddBox from '../components/modals/AddBox';
import AddItem from '../components/modals/AddItem';
import { TopBackButton } from '../components/icons';
import {
  classNames,
  BOX_TYPES,
  sortObjNameCaseIns,
  sortByLatestModified,
} from '../utils';
import PrimaryTab from '../components/boxes/PrimaryTab';
import ContentsTab from '../components/boxes/ContentsTab';

const TABS = {
  details: 1,
  contents: 2,
};

export async function loader({ request }) {
  const url = new URL(request.url);
  let tab = url.searchParams.get('tab');
  if (tab) tab = parseInt(tab);
  return { tab };
}

export function BoxNotFound() {
  return (
    <Page className="px-5 py-6 flex flex-col">
      <div className="shrink-0">
        <TopBackButton />
      </div>
      <div className="flex-1 flex flex-col items-center justify-center">
        <div
          className="top-0 w-64 h-64 rounded-md bg-no-repeat bg-center bg-cover"
          style={{ backgroundImage: 'url(/notFound.png)' }}
        />
        <h6 className="text-2xl font-medium mb-3 text-indigo-400">Oops!</h6>
        <p className="text-indigo-900 dark:text-indigo-100">
          Sorry, storage id not found.
        </p>
        <p className="text-lg mt-3 font-bold opacity-70 text-indigo-300 dark:text-indigo-400">
          404
        </p>
      </div>
    </Page>
  );
}

export default function Box() {
  const {
    state,
    setModalContent,
    setModals,
    isPageLoading,
    useAdvancedFeatures: advanced,
  } = useDataService();
  const { boxId } = useParams();
  const { tab } = useLoaderData();
  const location = useLocation();
  const [activeTab, setTab] = useState(tab || TABS.details);

  const changeTab = (num) => {
    const { history, location } = window;
    history.replaceState({}, '', `${location.pathname}?tab=${num}`);
    setTab(num);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let _tab = params.get('tab');
    if (!_tab) return setTab(TABS.details);
    setTab(parseInt(_tab));
  }, [location]);

  const [box, items, superContainers, locations, boxes] = useMemo(() => {
    const _box = state.containers?.[boxId];
    if (!_box) return [];

    const _items = Object.values(state.items || {}).filter(
      (item) => item.container === boxId && !item.hidden
    );
    _items.sort(sortByLatestModified);
    const _containers = Object.values(state.containers || {});

    const superContainers = _containers.filter(
      (c) => c.type === BOX_TYPES.superContainer && c.id !== boxId
    );
    superContainers.sort(sortObjNameCaseIns);
    const locations = _containers.filter((c) => c.type === BOX_TYPES.location);
    locations.sort(sortObjNameCaseIns);

    let _boxes = null;
    if (_box.type === BOX_TYPES.location)
      _boxes = _containers.filter((c) => c.location === _box.id);
    else if (_box.type === BOX_TYPES.superContainer || (advanced && !_box.type))
      _boxes = _containers.filter((c) => c.container === _box.id);

    return [_box, _items, superContainers, locations, _boxes];
  }, [state.containers, state.items, boxId, advanced]);

  const boxType = box?.type;
  useEffect(() => {
    if (activeTab === TABS.contents) {
      if (boxType === BOX_TYPES.superContainer || (!boxType && advanced)) {
        setModals([
          { modal: <AddBox />, id: 'addBox', label: 'Add a Box' },
          { modal: <AddItem />, id: 'addItem', label: 'Add an Item' },
        ]);
        return () => {
          setModals(null);
          setModalContent(null);
        };
      } else if (boxType === BOX_TYPES.location) {
        setModalContent(<AddBox />);
        return () => setModalContent(null);
      } else {
        setModalContent(<AddItem />);
        return () => setModalContent(null);
      }
    }
  }, [setModalContent, setModals, activeTab, boxType, advanced]);

  const isContainer = box?.type === BOX_TYPES.superContainer;
  const isLocation = box?.type === BOX_TYPES.location;
  const type = isLocation ? 'Location' : isContainer ? 'Container' : 'Box';

  if (isPageLoading) {
    return <Page className="px-5 py-6 flex flex-col" />;
  }

  if (!box) {
    return <BoxNotFound />;
  }

  return (
    <Page className="px-5 pb-4 box-page max-w-screen-md w-full mx-auto">
      <div className="sticky top-6 z-[2]">
        <TopBackButton />
      </div>
      <div
        className={classNames(
          'sticky top-0 z-[1] text-lg font-bold text-center',
          'bg-gray-100 dark:bg-gray-800 dark:text-gray-200',
          'flex gap-2 -mt-[26px] pt-14 -mx-5 px-5 border-b dark:border-gray-600'
        )}
      >
        <Tab
          isActive={activeTab === TABS.details}
          onClick={() => changeTab(TABS.details)}
        >
          {type} Details
        </Tab>
        <Tab
          isActive={activeTab === TABS.contents}
          onClick={() => changeTab(TABS.contents)}
        >
          {isContainer
            ? 'Boxes in this Container'
            : isLocation
              ? 'Boxes at this Location'
              : 'Items in this box'}
        </Tab>
      </div>

      {activeTab === TABS.details && (
        <PrimaryTab
          type={type}
          box={box}
          items={items}
          boxes={boxes}
          isLocation={isLocation}
          superContainers={superContainers}
          locations={locations}
        />
      )}

      {activeTab === TABS.contents && (
        <ContentsTab
          box={box}
          onChangeTab={() => changeTab(TABS.details)}
          items={items}
          boxes={boxes}
          type={type}
        />
      )}
    </Page>
  );
}

function Tab({ isActive, onClick, children }) {
  return (
    <h1
      tabIndex={-1}
      className={classNames(
        'px-3 pb-3 pt-2 whitespace-nowrap rounded-t-lg relative top-[2px]',
        isActive
          ? 'bg-white dark:bg-gray-900 border-t border-l border-r dark:border-gray-600 shrink-0'
          : 'shrink overflow-hidden text-ellipsis'
      )}
      onClick={onClick}
    >
      {children}
    </h1>
  );
}
