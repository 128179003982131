import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useLocalStorage from '../hooks/useLocalStorage';
import useDataService from '../hooks/useDataService';
import { classNames, isSubcriptionRecurring } from '../utils';
import { useForm } from '../hooks/useForms';

import Page from '../components/Page';
import {
  Content,
  Section,
  ToggleSection,
  Heading,
  Button,
  Input,
  EditButton,
  ProfileImage,
  BLink,
  formatDate,
  Invoices,
} from '../components/profile';
import { getLongestExpiryDate } from '../utils';
import usePageTitle from '../hooks/usePageTitle';

function Profile() {
  usePageTitle('Box it up - Profile page');
  const [isAiMode, setIsAiMode] = useState(false);
  const [store, find] = useLocalStorage();
  const {
    state: { profile, _tier },
    ui: { isDarkMode, featureFlagAI },
    setDarkMode,
    editProfile,
  } = useDataService();
  const navigate = useNavigate();

  const email = useMemo(() => {
    const user = find('user');
    return atob(user).split(':')[0];
  }, [find]);

  const [
    _profile,
    set_profile,
    handleChange,
    ,
    init_set_profile,
    ,
    ,
    handleConfirm,
  ] = useForm(profile || { init: false });
  const [isEditing, setIsEditing] = useState({});

  const toggleEdit = (key) => () =>
    setIsEditing((s) => ({ ...s, [key]: !s[key] }));

  const toggleSave = (key) => () => {
    editProfile(_profile);
    setIsEditing((s) => ({ ...s, [key]: !s[key] }));
  };
  const updateProfile = (key, value) => {
    const newProfile = { ..._profile, [key]: value };
    set_profile(newProfile);
    editProfile(newProfile);
  };

  const toggleDarkMode = (value) => {
    store('isDarkMode', value);
    setDarkMode(value);
  };

  const logout = () => {
    store('user', '');
    setTimeout(() => {
      navigate('/login');
    }, 500);
  };

  useEffect(() => {
    if (!_profile.init && profile) {
      init_set_profile({ ...profile, init: true });
    }
  }, [profile, _profile, init_set_profile]);

  const [expiryTime, currentSub] = getLongestExpiryDate(profile?.subscriptions);

  return (
    <Page
      id={'profile-page'}
      className="pt-7 sm:pb-7 bg-gray-100 dark:bg-gray-800"
    >
      <div
        className={classNames(
          'container mx-auto shadow rounded-t-2xl sm:rounded-lg max-w-4xl',
          'bg-white dark:bg-gray-900'
        )}
      >
        <div className="px-4 py-6 sm:px-6 flex">
          <ProfileImage name={_profile.name} />
          <div>
            <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
              Hello {_profile.name || 'There'}!
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              Personal details and application.
            </p>
          </div>
        </div>
        <div className="border-t border-gray-100 dark:border-gray-700">
          <dl className="divide-y divide-gray-100 dark:divide-gray-700">
            <ToggleSection
              ariaLabel={'Toggle dark mode'}
              enabled={isDarkMode || false}
              setEnabled={toggleDarkMode}
              label="Use Dark Mode"
            />
            {featureFlagAI && (
              <ToggleSection
                ariaLabel={'Toggle AI enabled'}
                enabled={isAiMode}
                setEnabled={setIsAiMode}
                label="Use AI user interface"
              />
            )}
            <Section>
              <Heading>Email address</Heading>
              <Content>{email}</Content>
              <Button onClick={logout}>Logout</Button>
              <Heading className="hidden md:block" />
              <Content className="uppercase">
                {profile?.emailConfirmed === false
                  ? 'Not verified'
                  : profile?.emailConfirmed
                    ? 'Verified'
                    : ''}
              </Content>
            </Section>
            <Section>
              <Heading>Name</Heading>
              {isEditing.name ? (
                <Input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onKeyDown={handleConfirm(toggleSave('name'))}
                  defaultValue={_profile.name}
                  focusOnInit
                />
              ) : (
                <Content>{_profile.name}</Content>
              )}
              <EditButton
                onEdit={toggleEdit('name')}
                onSave={toggleSave('name')}
                isEditing={isEditing.name}
              />
            </Section>
            <Section>
              <Heading>My Subscription</Heading>
              <Content>
                {!_tier
                  ? 'loading…'
                  : _tier.type === 'paid'
                    ? 'Paid tier'
                    : 'Free tier'}
              </Content>
              {(!_tier || _tier.type !== 'paid') && (
                <Button to="/pricing">Upgrade</Button>
              )}
              {!!expiryTime && (
                <>
                  <Heading className="mt-6 sm:mt-0">
                    {isSubcriptionRecurring(currentSub.id)
                      ? 'Subscription Renewal Date'
                      : 'Paid Plan End Date'}
                  </Heading>
                  <Content>{formatDate(expiryTime * 1000)}</Content>
                  <Button to="https://billing.stripe.com/p/login/28o8zWbrefFz2di3cc">
                    Edit subscription
                  </Button>
                </>
              )}
            </Section>
            <Section>
              <Heading className="leading-6">Invoices</Heading>
              {!expiryTime ? (
                <Content>None</Content>
              ) : (
                <Invoices subscriptions={profile?.subscriptions} />
              )}
            </Section>
            <ToggleSection
              ariaLabel={'Use QR codes throughout the app'}
              enabled={_profile?.useQrCode || false}
              setEnabled={(value) => updateProfile('useQrCode', value)}
              label="Use QR codes"
            >
              <Heading className="hidden md:block" />
              <Content>
                I am able print my own QR codes and stick them onto boxes.
              </Content>
            </ToggleSection>
            <ToggleSection
              ariaLabel={'Experimental features toggle'}
              enabled={_profile?.advanced || false}
              setEnabled={(value) => updateProfile('advanced', value)}
              label="Show experimental features"
            >
              <Heading className="hidden md:block" />
              <Content>
                Show experimental, unstable features. Not recommended for
                majority of users.
              </Content>
            </ToggleSection>
            <Section>
              <Heading>Attributions</Heading>
              {isEditing.attribution && (
                <Content className="pr-16">
                  <BLink
                    href="https://www.flaticon.com/free-icons/nothing"
                    title="nothing icons"
                  >
                    Nothing icons created by LAFS - Flaticon
                  </BLink>
                  <BLink
                    href="https://www.flaticon.com/free-icons/empty"
                    title="empty icons"
                  >
                    Empty icons created by Ghozi Muhtarom - Flaticon
                  </BLink>
                  <BLink
                    href="https://www.flaticon.com/free-icons/magic"
                    title="magic icons"
                  >
                    Magic icons created by Freepik - Flaticon
                  </BLink>
                  <BLink
                    href="https://www.flaticon.com/free-icons/party"
                    title="party icons"
                  >
                    Party icons created by Smashicons - Flaticon
                  </BLink>
                  <BLink
                    href="https://www.flaticon.com/free-animated-icons/email"
                    title="email animated icons"
                  >
                    Email animated icons created by Freepik - Flaticon
                  </BLink>
                </Content>
              )}
              <Button onClick={toggleEdit('attribution')}>
                {isEditing.attribution ? 'Hide' : 'Show'}
              </Button>
            </Section>
          </dl>
        </div>
      </div>
    </Page>
  );
}

export default Profile;
