import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';

import useScrollTracker from '../hooks/useScrollTracker';
import useDataService from '../hooks/useDataService';

import Header from '../components/Header';
import SearchBar from '../components/SearchBar';
import CategoryTabs, { categories } from '../components/CategoryTabs';
import ItemList from '../components/ItemList';
import GalleryList from '../components/GalleryList';
import Page from '../components/Page';
import HomeModal from '../components/modals/HomeModal';
import QRDecode from '../components/QRDecode';
import { searchItems, sortByLatestModified } from '../utils';
import useAnalytics from '../hooks/useAnalytics';

export async function loader({ request }) {
  const url = new URL(request.url);
  const q = url.searchParams.get('q');
  const view = url.searchParams.get('view');
  return { q, view };
}

function Home() {
  const { q, view } = useLoaderData();
  const { logEvent, logOnce } = useAnalytics();
  const [scrollRef, scrollTracker] = useScrollTracker();

  const [query, setQuery] = useState(q || '');
  const [activeCat, setActiveCat] = useState(categories[0]);
  const [isListingView, changeIsListingView] = useState(view !== 'gallery');

  const handleQueryChange = (value) => {
    setQuery(value);
    logOnce('home_search');
    const method = value ? 'replaceState' : 'pushState';
    window.history[method](
      {},
      '',
      `/?q=${value}&view=${isListingView ? 'list' : 'gallery'}`
    );
  };

  const setListingView = useCallback(() => {
    changeIsListingView(true);
    logOnce('view_change', { view: 'list' });
    window.history.replaceState({}, '', `/?q=${query}&view=list`);
  }, [logOnce, query]);

  const setGalleryView = useCallback(() => {
    changeIsListingView(false);
    logOnce('view_change', { view: 'gallery' });
    window.history.replaceState({}, '', `/?q=${query}&view=gallery`);
  }, [logOnce, query]);

  const {
    state,
    setModalContent,
    openModal,
    addCloseModalListener,
    removeCloseModalListener,
  } = useDataService();
  const items = useMemo(() => {
    const _items = Object.values(state.items || {}).filter(
      (item) => !item.hidden
    );
    _items.sort(sortByLatestModified);
    return _items.map((item) => ({
      ...item,
      _containerName: state.containers[item.container]?.name,
      _containerIdNumber: state.containers[item.container]?.idNumber,
    }));
  }, [state.items, state.containers]);

  const filteredItems = useMemo(() => {
    return searchItems(items, query, activeCat.dataFilter);
  }, [items, query, activeCat.dataFilter]);

  useEffect(() => {
    setModalContent(<HomeModal />);
    return () => setModalContent(null);
  }, [setModalContent]);

  const handleQRClick = () => {
    logEvent('home_QR_click');
    setModalContent(<QRDecode />);
    const listener = () => {
      setTimeout(() => setModalContent(<HomeModal />), 100);
      removeCloseModalListener(listener);
    };
    addCloseModalListener(listener);
    setTimeout(() => openModal(), 10);
  };

  return (
    <Page ref={scrollRef} id={'homepage'} className="pb-4">
      <Header scroll={scrollTracker} />
      <SearchBar
        scroll={scrollTracker}
        value={query}
        onChangeValue={handleQueryChange}
        onClick={handleQRClick}
      />
      <CategoryTabs
        scroll={scrollTracker}
        setListingView={setListingView}
        setGalleryView={setGalleryView}
        isListingView={isListingView}
        activeCat={activeCat}
        setActiveCat={setActiveCat}
      />
      {isListingView ? (
        <ItemList items={filteredItems} minItems={8} />
      ) : (
        <GalleryList items={filteredItems} />
      )}
    </Page>
  );
}

export default Home;
