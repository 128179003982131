import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import Toggle from './Toggle';
import { Envelope, PaperClipIcon, PencilIcon } from './icons';
import { classNames, isSubcriptionRecurring } from '../utils';

export function ProfileImage({ imageUrl, name = 'Stranger' }) {
  if (imageUrl) {
    return (
      <img
        className="mr-4 inline-block h-14 w-14 rounded-full"
        src={imageUrl}
        alt=""
      />
    );
  }
  return (
    <span className="mr-4 inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-500">
      <span className="text-xl font-medium leading-none text-white uppercase">
        {name.replace(/ /g, '').substring(0, 2)}
      </span>
    </span>
  );
}

export const ToggleSection = ({
  enabled,
  setEnabled,
  ariaLabel,
  children,
  label,
}) => (
  <div className="px-4 py-6 grid grid-cols-2 sm:grid-cols-3 sm:gap-4 sm:px-6">
    <Heading>{label}</Heading>
    <dd className="mt-0 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 text-right sm:text-left">
      <Toggle enabled={enabled} setEnabled={setEnabled} label={ariaLabel} />
    </dd>
    {children}
  </div>
);

export const Button = ({ onClick, children, to }) => {
  const Tag = to ? NavLink : 'button';
  return (
    <Tag
      type="button"
      to={to}
      className="absolute right-4 px-3 h-8 top-1/2 -translate-y-1/2 flex justify-center items-center text-orange-500"
      onClick={onClick}
    >
      {children}
    </Tag>
  );
};

export const EditButton = ({ onEdit, onSave, isEditing }) => {
  return (
    <button
      type="button"
      className="absolute right-4 w-8 h-8 top-1/2 -translate-y-1/2 flex justify-center items-center text-orange-500"
      onClick={() => (isEditing ? onSave() : onEdit())}
    >
      {isEditing ? 'Save' : <PencilIcon className="w-4 h-4" />}
    </button>
  );
};

export const Input = ({ focusOnInit, ...props }) => {
  const ref = useRef();
  useEffect(() => {
    if (focusOnInit) {
      ref.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <input
      ref={ref}
      className="border-b py-1 mt-1 mr-2 text-lg focus:border-orange-500 focus:border-b-2 focus:ring-0 focus:outline-0"
      {...props}
    />
  );
};

export const Section = ({ children }) => (
  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 relative">
    {children}
  </div>
);

export const Heading = ({ children, className }) => (
  <dt
    className={classNames(
      'text-sm font-medium text-gray-900 dark:text-gray-300',
      className
    )}
  >
    {children}
  </dt>
);
export const Content = ({ children, className }) => (
  <dd
    className={classNames(
      'mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0',
      'dark:text-gray-200',
      className
    )}
  >
    {children}
  </dd>
);
export const ALink = ({ children, className, ...props }) => (
  <a
    {...props}
    className="block font-medium text-orange-400 hover:text-orange-600"
  >
    {children}
  </a>
);

export const BLink = ({ children, className, ...props }) => (
  <a
    {...props}
    className="block underline font-medium text-gray-400 hover:text-gray-600 mb-3"
  >
    {children}
  </a>
);

export const RowFile = ({ children, size, onClick }) => (
  <>
    <div className="flex w-0 flex-1 items-center">
      <PaperClipIcon
        className="h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
      <div className="ml-4 flex min-w-0 flex-1 gap-2">
        <span className="truncate font-medium">{children}</span>
        <span className="flex-shrink-0 text-gray-400">{size}</span>
      </div>
    </div>
    <div className="ml-4 flex-shrink-0">
      <ALink href="/" onClick={onClick}>
        Download
      </ALink>
    </div>
  </>
);

export const Row = ({ children }) => (
  <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
    {children}
  </li>
);

export const Table = ({ children }) => (
  <dd className="mt-2 text-sm text-gray-900 dark:text-gray-200 sm:col-span-2 sm:mt-0">
    <ul className="divide-y divide-gray-100 dark:divide-gray-700 rounded-md border border-gray-200 dark:border-gray-600">
      {children}
    </ul>
  </dd>
);

export const TableExample = () => (
  <Table>
    <Row>
      <RowFile size={'2.4mb'}>resume_back_end_developer.pdf</RowFile>
    </Row>
    <Row>
      <RowFile size={'4.5mb'}>coverletter_back_end_developer.pdf</RowFile>
    </Row>
  </Table>
);

export const Invoices = ({ subscriptions }) => {
  const subs = Object.keys(subscriptions).map((key) => ({
    ...subscriptions[key],
    id: key,
  }));
  return (
    <Table>
      {subs.map((sub) => (
        <Row key={sub.id}>
          <div className="flex w-0 flex-1 items-center">
            <Envelope
              className="h-5 w-5 flex-shrink-0 text-orange-400"
              aria-hidden="true"
            />
            <div className="ml-4 flex min-w-0 flex-1 gap-2">
              <span className="truncate font-medium hover:overflow-visible">
                {isSubcriptionRecurring(sub.id)
                  ? 'Subscription'
                  : 'Standard 1 year'}
              </span>
              <span className="flex-shrink-0 text-gray-400">
                {formatDate(sub.period_start * 1000)}
              </span>
            </div>
          </div>
          <div className="ml-4 flex-shrink-0">sent via email</div>
        </Row>
      ))}
    </Table>
  );
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
};
