import React, { useState, useEffect, useMemo } from 'react';

import useDataService from '../../hooks/useDataService';
import {
  BottomBtnsContainer,
  Input,
  InputLine,
  ModalButton,
  ModalButton2,
} from './Modal';
import { uid } from 'uid';
import { useForm } from '../../hooks/useForms';
import { classNames, genQRCodeStr, BOX_TYPES } from '../../utils';
import QRCodeComponent from '../QRCodeComponent';
import {
  Bath,
  CubeIcon,
  Misc,
  ClotheHanger,
  Temporary,
  Medicine,
  Makeup,
  CodeIcon,
  LocationIcon,
  LocationHome,
  LocationMap,
  LocationEarth,
  WarehouseSm,
  WarehouseMd,
  WarehouseLg,
  WarehouseFull,
} from '../icons';
import { useNavigate, useParams } from 'react-router-dom';

export default function StorageModal({ type: superType, onCancel = () => {} }) {
  const {
    state: { _tier, profile },
    closeModal,
    uploadImage,
    addContainer,
    ui: { tempModalId },
    addCloseModalListener,
    removeCloseModalListener,
    getQRUserId,
    totalRegularBoxes,
    addMessage,
  } = useDataService();
  const profileUseQrCode = profile?.useQrCode || false;
  const { boxId } = useParams();
  const navigate = useNavigate();
  const superKey = superType === BOX_TYPES.location ? 'location' : 'container';

  const qrStr = useMemo(
    () => genQRCodeStr(getQRUserId(), tempModalId),
    [getQRUserId, tempModalId]
  );

  useEffect(() => {
    const listener = () => {
      onCancel();
      removeCloseModalListener(listener);
    };
    addCloseModalListener(listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData, handleChange, , , , , , change] = useForm(
    { qrCodeStr: qrStr, [superKey]: boxId },
    'imageUrl'
  );

  const handleChangeIcon = (icon) => () =>
    setFormData({
      ...formData,
      icon: formData.icon === icon ? '' : icon,
    });

  const handleChangeIdNumber = (e) => {
    const { value } = e.target;
    if (value.length > 4) return;
    setFormData({
      ...formData,
      idNumber: value,
    });
  };

  const handleChangeType = (type) => () =>
    setFormData({
      ...formData,
      type: formData.type === type ? '' : type,
    });

  const onAddContainer = async () => {
    setIsProcessing(true);
    const imageUrl = formData.imageUrl;

    if (_tier.maxBoxes > 0 && totalRegularBoxes() + 1 > _tier.maxBoxes) {
      addMessage({
        message: 'Free quota reached',
        desc: 'You have reached the maximum number of boxes (max 2.) for your tier. Please upgrade to add more boxes.',
        onConfirm: () => {
          closeModal();
          navigate('/profile#upgrade');
        },
        confirmText: 'Upgrade',
      });
      setIsProcessing(false);
      return;
    }

    //upload image to s3
    let cloudImageUrl = '';
    if (imageUrl) {
      cloudImageUrl = await uploadImage(imageUrl, uid());
    }

    addContainer({
      id: tempModalId,
      name: formData.name,
      image: cloudImageUrl,
      icon: formData.icon || '',
      createdAt: +Date.now(),
      updatedAt: +Date.now(),
      useQrCode: formData.useQrCode || false,
      idNumber: formData.idNumber || '',
      qrCodeStr: qrStr,
      type: formData.type || '',
      notes: formData.notes,
      location: formData.location,
      container: formData.container,
    });

    setTimeout(() => {
      setIsProcessing(false);
      closeModal();
    }, 10);
  };

  const icons = useIconContainer({
    onClick: handleChangeIcon,
    active: formData.icon,
    type: formData.type,
  });

  const { type } = formData;

  return (
    <>
      <h2 className="text-2xl font-bold mb-5 dark:text-gray-200">
        Enter New Storage or Location
      </h2>

      <InputLine label="Type">
        <div className="flex items-stretch gap-2 mt-2">
          <RadioButton
            onClick={handleChangeType}
            current={type || ''}
            value={''}
          >
            Normal Box
          </RadioButton>
          {!superType && (
            <RadioButton
              onClick={handleChangeType}
              current={type}
              value={BOX_TYPES.location}
            >
              Location
            </RadioButton>
          )}
          {!superType && (
            <RadioButton
              onClick={handleChangeType}
              current={type}
              value={BOX_TYPES.superContainer}
            >
              Container of boxes
            </RadioButton>
          )}
        </div>
        {!superType && (
          <p className="mt-1 text-sm text-gray-500">
            {BOX_TYPES.location === type
              ? 'info: Location is where the box is situated'
              : BOX_TYPES.superContainer === type
                ? 'info: Which shelving unit / place in the room the box is'
                : ''}
          </p>
        )}
      </InputLine>

      <InputLine
        label={`${
          type === 'location'
            ? 'Location'
            : type === ''
              ? 'Box'
              : "Container of boxes'"
        } name`}
        htmlFor="name"
      >
        <Input
          id="name"
          name="name"
          value={formData.name || ''}
          onChange={handleChange}
          placeholder="Enter the storage name"
          required
        />
      </InputLine>

      <InputLine label="Icon">
        <div className="flex flex-wrap gap-2 my-2">{icons}</div>
      </InputLine>

      {formData.type !== 'location' && !profileUseQrCode && (
        <InputLine label="QR config">
          <div className="flex items-stretch gap-2 mt-2">
            <RadioButton
              value={true}
              current={formData.useQrCode}
              onClick={() => () => change('useQrCode', true)}
            >
              use QR code
            </RadioButton>
            <RadioButton
              value={false}
              current={formData.useQrCode || false}
              onClick={() => () => change('useQrCode', false)}
            >
              use ID numbers
            </RadioButton>
          </div>
        </InputLine>
      )}

      {formData.type !== 'location' && (
        <InputLine label={formData.useQrCode ? 'QR code' : 'ID Number'}>
          <div className="flex items-center justify-center gap-2">
            {profileUseQrCode || formData.useQrCode ? (
              <QRCodeComponent
                value={qrStr}
                className="w-[180px] h-[180px]"
                rectClassName="fill-white"
              />
            ) : (
              <Input
                id="idNumber"
                name="idNumber"
                value={formData.idNumber || ''}
                onChange={handleChangeIdNumber}
                placeholder="#1.."
                maxLength={4}
                className="w-24 text-[48px] text-center placeholder:italic border-b"
                overrideClassName={classNames(
                  'mt-1 block px-3 py-2',
                  'w-24 text-[48px] text-center placeholder:italic border-b'
                )}
              />
            )}
          </div>
        </InputLine>
      )}

      <InputLine label="Notes" htmlFor="notes">
        <Input
          id="notes"
          name="notes"
          type="textarea"
          value={formData.notes || ''}
          onChange={handleChange}
          placeholder="Other notes about this box"
        />
      </InputLine>

      {/*<InputLine label="Image URL">*/}
      {/*  <Input*/}
      {/*    id="image"*/}
      {/*    name="image"*/}
      {/*    value={formData.image || ''}*/}
      {/*    onChange={handleChange}*/}
      {/*    placeholder="Enter image URL"*/}
      {/*  />*/}
      {/*</InputLine>*/}

      <BottomBtnsContainer>
        <ModalButton
          disabled={isProcessing || !formData.name}
          onClick={onAddContainer}
        >
          Save
        </ModalButton>
        <ModalButton2 disabled={isProcessing} onClick={closeModal}>
          Cancel
        </ModalButton2>
      </BottomBtnsContainer>
    </>
  );
}

function RadioButton({ value, current, onClick, children }) {
  const isActive = value === current;
  const Button = isActive ? ModalButton : ModalButton2;
  return <Button onClick={onClick(value)}>{children}</Button>;
}

export const useIconContainer = ({ onClick, active, type = '' }) => {
  const Container = ({ name, className, children }) => (
    <IconContainer
      name={name}
      onClick={onClick}
      active={active}
      className={className}
    >
      {children}
    </IconContainer>
  );

  if (type === 'location') {
    return (
      <>
        <Container name="location" className="w-12 h-12">
          <LocationIcon className="w-8 h-8" />
        </Container>
        <Container name="lHome" className="w-12 h-12">
          <LocationHome className="w-8 h-8" />
        </Container>
        <Container name="lMap" className="w-12 h-12">
          <LocationMap className="w-8 h-8" />
        </Container>
        <Container name="lEarth" className="w-12 h-12">
          <LocationEarth className="w-8 h-8" />
        </Container>
      </>
    );
  }

  if (type === 'superContainer') {
    return (
      <>
        <Container name="warehouseSm" className="w-16 h-16">
          <WarehouseSm className="w-12 h-12" />
        </Container>
        <Container name="warehouseMd" className="w-16 h-16">
          <WarehouseMd className="w-12 h-12" />
        </Container>
        <Container name="warehouseLg" className="w-16 h-16">
          <WarehouseLg className="w-12 h-12" />
        </Container>
        <Container name="warehouseFull" className="w-16 h-16">
          <WarehouseFull className="w-12 h-12" />
        </Container>
      </>
    );
  }

  return (
    <>
      <Container name="misc">
        <Misc className="w-6 h-6" />
      </Container>
      <Container name="bath">
        <Bath className="w-6 h-6" />
      </Container>
      <Container name="container">
        <CubeIcon className="w-6 h-6" />
      </Container>
      <Container name="clotheHanger">
        <ClotheHanger className="w-6 h-6" />
      </Container>
      <Container name="temporary">
        <Temporary className="w-6 h-6" />
      </Container>
      <Container name="medicine">
        <Medicine className="w-6 h-6" />
      </Container>
      <Container name="makeup">
        <Makeup className="w-6 h-6" />
      </Container>
      <Container name="test">
        <CodeIcon className="w-6 h-6" />
      </Container>
    </>
  );
};

export function IconContainer({
  children,
  active,
  onClick,
  name,
  className: size,
}) {
  return (
    <div
      onClick={onClick(name)}
      tabIndex={-1}
      className={classNames(
        'w-10 h-10 border rounded flex items-center justify-center',
        active === name
          ? 'text-orange-400 border-orange-500 dark:text-orange-500 dark:border-orange-400 hover:bg-orange-100 dark:hover:bg-orange-900'
          : 'text-gray-700 border-gray-400 dark:text-gray-500 dark:border-gray-500 hover:bg-gray-100 dark:hover:bg-gray-800',
        size ? size : 'w-10 h-10'
      )}
    >
      {children}
    </div>
  );
}

// optional button to add an image
// choose an icon
// QR code generation
