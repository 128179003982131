import React, { useState, useRef, useEffect } from 'react';
import { retrieve, store } from '../../hooks/useLocalStorage';
import { redirect, useLoaderData, useNavigate } from 'react-router-dom';

import {
  API_ENDPOINT,
  classNames,
  endpoints,
  errorLog,
  snag,
} from '../../utils';
import {
  ArrowDown,
  ArrowUp,
  CheckIcon,
  ArrowRight,
} from '../../components/icons';
import {
  Corner,
  Panel,
  BackButton,
  CornerContainer,
  LoginBtn,
  RoundBtn,
  Input,
  PasswordBtn,
  Label,
  FieldLine,
  signupData,
  ForgotPasswordBtn,
  is8Char,
  FieldError,
} from '../../components/login';
import { useForm } from '../../hooks/useForms';
import usePageTitle from '../../hooks/usePageTitle';
import useAnalytics from '../../hooks/useAnalytics';

export async function loader({ request }) {
  const user = retrieve('user');
  const url = new URL(request.url);
  const initForgotPassword = !!url.searchParams.get('reset-password');

  // check that user is logged in
  if (!user) {
    return { initForgotPassword };
  }
  const res = await fetch(endpoints.auth, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/ld+json',
      authorization: `Basic ${user}`,
    },
  });

  if (res.ok) {
    return redirect('/');
  }

  return { initForgotPassword };
}

export default function Login() {
  usePageTitle('Box it up - Login or Sign up');
  const { logEvent } = useAnalytics();
  const { initForgotPassword } = useLoaderData();
  const [form, , handleChange, , , , , , change] = useForm({});
  const [currentTab, setTab] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  // const [acceptedTerms, setAcceptedTerms] = useState(false);

  const navigate = useNavigate();
  const scrollRef = useRef();

  const handleLogin = async (e) => {
    change('error-login', '');
    setSubmitting(true);
    const key = 'email-login';
    const isValid = $id(key).checkValidity();
    if (!isValid) {
      $id(key).reportValidity();
      setSubmitting(false);
      return;
    }

    const email = form['email-login'];
    const password = form['password-login'];
    const user = btoa(`${email}:${password}`);
    const res = await fetch(endpoints.db, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/ld+json',
        authorization: `Basic ${user}`,
      },
    });

    if (!res.ok) {
      change('error-login', 'Either your email or password is wrong');
      setSubmitting(false);
      logEvent('login', { attempted: 'failed' });
      return;
    }
    //else
    store('user', user);
    logEvent('login');
    navigate('/onboarding');
    setSubmitting(false);
  };

  const passwordLLength = form['password-login']?.length;
  const password2LLength = form['password-verify']?.length;
  const emailV = $id('email-login')?.checkValidity();
  const emailV2 = $id('email-verify')?.checkValidity();
  useEffect(() => {
    if (form['error-login-0'] && is8Char(passwordLLength))
      change('error-login-0', '');
    if (form['error-signup-0'] && emailV) change('error-signup-0', '');
    if (form['error-signup-2'] && is8Char(passwordLLength))
      change('error-signup-2', '');

    const error1 = form['error-signup-1'];
    const error3 = form['error-signup-3'];
    if (error1 && !error1.includes('match') && emailV2)
      change('error-signup-1', '');
    if (error3 && !error3.includes('match') && is8Char(password2LLength))
      change('error-signup-3', '');

    const isSameEmail = form['email-login'] === form['email-verify'];
    const isSamePass = form['password-login'] === form['password-verify'];
    if (error1 && error1.includes('match') && isSameEmail)
      change('error-signup-1', '');
    if (error3 && !error3.includes('match') && isSamePass)
      change('error-signup-3', '');
  }, [passwordLLength, password2LLength, emailV, emailV2, change, form]);

  const handleSignup = async (e) => {
    change('error-signup', '');

    let isValid = [
      'email-login',
      'email-verify',
      'password-login',
      'password-verify',
    ]
      .map((key, i) => {
        let v = true;
        const errorKey = `error-signup-${i}`;
        if (key.includes('email')) {
          v = $id(key).checkValidity();
          if (!v) change(errorKey, 'Please enter a valid email.');
          else change(errorKey, '');
        } else {
          const value = form[key];
          v = !!value && is8Char(value);
          if (!v) change(errorKey, 'Password must be at least 8 characters.');
          else change(errorKey, '');
        }
        return v;
      })
      .reduce((a, b) => a && b);

    if (isValid) {
      let v = form['email-login'] === form['email-verify'];
      if (!v) change('error-signup-1', 'Emails do not match.');
      isValid = v && isValid;
      v = form['password-login'] === form['password-verify'];
      if (!v) change('error-signup-3', 'Passwords do not match.');
      isValid = v && isValid;
    }

    if (!isValid) {
      logEvent('sign_up', { attempted: 'failed_validation' });
      return;
    }

    let res;
    const email = form['email-login'];
    const password = form['password-login'];
    try {
      res = await fetch(`${API_ENDPOINT.stage}/api/user`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
    } catch (e) {
      errorLog('Error:', e);
      return;
    }

    if (!res.ok) {
      switch (res.status) {
        case 400: {
          change(
            'error-signup',
            'Could not create user, email and password are required'
          );
          break;
        }
        case 422: {
          change(
            'error-signup',
            'Email already exists, try logging in or reset password'
          );
          // show reset password link
          break;
        }
        default: {
          change(
            'error-signup',
            'Could not create user, please try again later'
          );
          errorLog('Error:', res);
        }
        // no default
      }
      return;
    }

    const user = btoa(`${email}:${password}`);
    logEvent('signup');
    store('user', user);
    navigate('/onboarding');
  };

  const handleChangeTab = (tab) => {
    setTab(tab);
    if (tab === 1)
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth',
      });
    else scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const listener = (e) => {
      const el = e.target;
      if (el.scrollTop === 0) setTab(0);
      else if (el.scrollTop + el.offsetHeight === el.scrollHeight) setTab(1);
    };
    const el = scrollRef.current;
    el?.addEventListener('scroll', listener);
    return () => {
      el?.removeEventListener('scroll', listener);
    };
  }, []);

  const [showForgotPassword, setShowForgotPassword] = useState(
    initForgotPassword || false
  );
  const toggleForgotPassword = () => {
    if (form['error-login-0']) change('error-login-0', '');
    setShowForgotPassword((v) => !v);
  };
  const handleForgotPassword = async () => {
    try {
      const res = await fetch(`${API_ENDPOINT.prod}/api/forgot-password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/ld+json',
        },
        body: JSON.stringify({ email: form['email-login'] }),
      });
      if (!res.ok) {
        snag('Could not send reset password email');
        return;
      }

      logEvent('forgot_password');
      navigate('/forgot-password');
    } catch (error) {
      errorLog('Error encounter while forgot password:', error);
    }
  };

  return (
    <div className="min-h-svh h-dvh bg-orange-200 bg-gradient-to-br from-red-300 to-yellow-200">
      <div
        id="main-login-content"
        ref={scrollRef}
        className={classNames(
          'w-full h-full flex flex-col items-center p-4',
          'overflow-y-scroll scroll-pt-4',
          'transition-transform duration-[0.8s] ease-in-out',
          'md:justify-center'
        )}
      >
        <Panel
          className={classNames(
            'pt-8 md:transition md:duration-500',
            currentTab === 1
              ? 'md:absolute md:opacity-0 md:pointer-events-none md:-translate-x-full'
              : ''
          )}
        >
          <BackButton className="md:hidden" onClick={() => navigate(-1)} />
          <h2
            className={classNames(
              'relative text-3xl font-bold mb-6 flex flex-col justify-center h-[20dvh] transition-opacity text-gray-900',
              currentTab === 0 ? '' : 'opacity-0'
            )}
          >
            <span
              className={classNames(
                showForgotPassword ? 'absolute opacity-0' : '',
                'transition-opacity'
              )}
            >
              Login
            </span>
            <span
              className={classNames(
                showForgotPassword ? '' : 'absolute opacity-0',
                'transition-opacity'
              )}
            >
              Reset Password
            </span>
            <div className="items-center my-4 hidden md:flex">
              <p className="block text-base text-gray-900 font-medium">
                Don't have an account yet,{' '}
                <a
                  className="text-orange-500 hover:underline inline-flex items-center"
                  href="/public"
                  onClick={(e) => {
                    setTab(1);
                    e.preventDefault();
                  }}
                >
                  sign up
                  <ArrowRight className="ml-1 w-4 h-4 stroke-[3px] relative top-[1px]" />
                </a>
              </p>
            </div>
          </h2>
          <div
            className={classNames(
              'transition-opacity duration-500',
              currentTab === 0 ? '' : 'opacity-0'
            )}
          >
            <div className="mb-4 relative">
              <Label htmlFor="email-login">Email</Label>
              <Input
                type="email"
                id="email-login"
                name="email-login"
                value={form['email-login'] || ''}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="mb-4 relative">
              <div className="flex justify-between items-center">
                <Label htmlFor="password-login">
                  {!showForgotPassword && 'Password'}
                </Label>
                <span
                  tabIndex={-1}
                  className="cursor-pointer text-sm text-orange-500 hover:underline"
                  onClick={toggleForgotPassword}
                >
                  {showForgotPassword ? 'Back to sign in' : 'Forgot password'}
                </span>
              </div>
              {showForgotPassword && (
                <ForgotPasswordBtn
                  className="absolute"
                  onClick={handleForgotPassword}
                >
                  Forgot password
                </ForgotPasswordBtn>
              )}
              <Input
                type={form[`viewPassword`] ? 'text' : 'password'}
                id="password-login"
                name="password-login"
                value={form['password-login'] || ''}
                onChange={handleChange}
                placeholder="Enter your password"
                className={classNames(
                  showForgotPassword ? 'opacity-0 pointer-events-none' : ''
                )}
                required
              />
              <PasswordBtn
                viewPassword={form[`viewPassword`]}
                onClick={() => change(`viewPassword`, !form[`viewPassword`])}
              />
              <FieldError form={form} index={0} type="login" />
            </div>
            {form['error-login'] && (
              <div className="mt-2 text-center relative z-[1]">
                <p className="text-sm text-red-500">{form['error-login']}</p>
              </div>
            )}
            <LoginBtn
              disabled={isLoginDisabled(form, currentTab) || isSubmitting}
              looksDisabled={currentTab === 1 || isSubmitting}
              onClick={handleLogin}
              className={classNames(
                'hidden md:flex w-full my-6',
                showForgotPassword ? 'opacity-0 pointer-events-none' : ''
              )}
            >
              Login
            </LoginBtn>
          </div>
        </Panel>
        <div
          className={classNames(
            'flex flex-col h-[35dvh] w-full relative px-6 pt-6 md:hidden'
          )}
        >
          <CornerContainer top>
            <Corner className={classNames('w-full')} />
            <RoundBtn
              onClick={() => handleChangeTab(1)}
              className={classNames(
                'bottom-[10px] right-[6px] ',
                currentTab === 1 ? 'opacity-0' : 'opacity-100'
              )}
            >
              <ArrowDown className="w-4 h-4 stroke-[3px]" />
            </RoundBtn>
          </CornerContainer>
          <CornerContainer>
            <Corner
              className={classNames('w-full')}
              style={{ transform: 'rotateX(180deg) rotateY(180deg)' }}
            />
            <RoundBtn
              onClick={() => handleChangeTab(0)}
              className={classNames(
                'top-[10px] left-[6px]',
                currentTab === 0 ? 'opacity-0' : 'opacity-100'
              )}
            >
              <ArrowUp className="w-4 h-4 stroke-[3px]" />
            </RoundBtn>
          </CornerContainer>
          <div className="relative flex flex-col">
            <LoginBtn
              disabled={isLoginDisabled(form, currentTab) || isSubmitting}
              looksDisabled={currentTab === 1 || isSubmitting}
              isInvisible={currentTab === 1}
              onClick={handleLogin}
              className={classNames(
                showForgotPassword ? 'opacity-0 pointer-events-none' : '',
                'flex w-[60%] self-end',
                currentTab === 0 ? '' : 'opacity-0'
              )}
            >
              Login
            </LoginBtn>
            <h2
              className={classNames(
                'text-3xl font-bold flex items-center transition-opacity duration-500 text-orange-400',
                'absolute -top-5 right-0 p-5 ',
                currentTab === 0 ? 'opacity-0 pointer-events-none' : ''
              )}
              onClick={() => currentTab === 1 && handleChangeTab(0)}
            >
              Login
            </h2>
          </div>
          <div
            className="h-[8dvh]"
            onClick={() => currentTab === 1 && handleChangeTab(0)}
          />
          <h2
            className={classNames(
              'relative text-3xl font-bold mb-6 flex items-end mt-[2dvh] h-[10dvh]',
              currentTab === 0 ? 'text-orange-400' : 'text-gray-900'
            )}
            onClick={() => currentTab === 0 && handleChangeTab(1)}
          >
            Sign up
          </h2>
        </div>
        <Panel
          className={classNames(
            'pb-8 md:transition md:duration-500',
            currentTab === 0
              ? 'md:absolute md:opacity-0 md:pointer-events-none md:translate-x-full'
              : ''
          )}
        >
          <h2
            className={classNames(
              'text-3xl font-bold my-6 flex-col justify-center transition-opacity text-gray-900',
              'hidden md:flex'
            )}
          >
            Sign up
            <div className="items-center my-4 flex">
              <p className="block text-base text-gray-900 font-medium">
                Already have an account,{' '}
                <a
                  className="text-orange-500 hover:underline inline-flex items-center"
                  href="/public"
                  onClick={(e) => {
                    setTab(0);
                    e.preventDefault();
                  }}
                >
                  login
                  <ArrowRight className="ml-1 w-4 h-4 stroke-[3px] relative top-[1px]" />
                </a>
              </p>
            </div>
          </h2>
          <div
            className={classNames(
              currentTab === 0 ? 'opacity-0' : 'opacity-100'
            )}
          >
            {signupData(form).map(({ id, ...f }, i) => (
              <FieldLine key={id} label={f.label} htmlFor={id}>
                <Input
                  type={f.type}
                  id={id}
                  name={f.name}
                  value={form[f.name] || ''}
                  onChange={handleChange}
                  placeholder={f.placeholder}
                  required
                />
                {f.hasBtn && (
                  <PasswordBtn
                    viewPassword={form[`viewPassword-${i}`]}
                    onClick={() =>
                      change(`viewPassword-${i}`, !form[`viewPassword-${i}`])
                    }
                  />
                )}
                <FieldError form={form} index={i} />
              </FieldLine>
            ))}
            {form['error-signup'] && (
              <div className="my-3 text-center">
                <p className="text-sm text-red-500">{form['error-signup']}</p>
              </div>
            )}
            <div className="mt-1">
              <p
                className={classNames(
                  'text-sm flex items-center gap-2',
                  is8Char(form['password-login']) ? 'text-green-600' : ''
                )}
              >
                {is8Char(form['password-login']) ? (
                  <CheckIcon className="w-3 h-3 stroke-[3px]" />
                ) : (
                  '-'
                )}{' '}
                8 characters minimum
              </p>
            </div>
            <LoginBtn
              disabled={isSignupDisabled(form, currentTab)}
              looksDisabled={currentTab === 0}
              onClick={handleSignup}
              className="flex w-full mt-6"
            >
              Sign up
            </LoginBtn>
          </div>
        </Panel>
      </div>
    </div>
  );
}

const $id = (id) => document.getElementById(id);

const isSignupDisabled = (form, currentTab) => currentTab === 0;
const isLoginDisabled = (form, currentTab) => currentTab === 1;
