import React from 'react';
import { classNames, PAGE_SCROLL } from '../utils';
import { GalleryIcon, ListIcon } from './icons';

const not_in_a_box_filter = (data) =>
  data.filter(({ container }) => !container);

export const categories = [
  { id: 0, label: 'Recently Added', dataFilter: undefined },
  { id: 1, label: 'Not in a box', dataFilter: not_in_a_box_filter },
  /*, 'Clothes', 'Bathroom', 'Facial Care'*/
];

function CategoryTabs({
  scroll,
  setListingView,
  setGalleryView,
  isListingView,
  activeCat,
  setActiveCat,
}) {
  return (
    <div
      className={classNames(
        'pt-4 flex px-5 space-x-2 w-full overflow-x-scroll text-sm flex-shrink-0 sticky z-[1] top-[72px] pb-3',
        'bg-white dark:bg-gray-900',
        scroll >= PAGE_SCROLL.categories ? 'drop-shadow' : ''
      )}
    >
      <Button isSlim isActive={!isListingView} onClick={setGalleryView}>
        <GalleryIcon className="w-[20px]" />
      </Button>
      <Button isSlim isActive={isListingView} onClick={setListingView}>
        <ListIcon className="w-[20px]" />
      </Button>
      {categories.map((category) => (
        <Button
          key={category.id}
          isActive={activeCat.id === category.id}
          onClick={() => setActiveCat(category)}
        >
          {category.label}
        </Button>
      ))}
    </div>
  );
}

export function Button({ isActive, children, isSlim, className, ...props }) {
  return (
    <button
      {...props}
      className={classNames(
        'py-2 whitespace-nowrap',
        isActive
          ? 'bg-orange-200 hover:bg-orange-300 dark:bg-orange-500 dark:hover:bg-orange-600 dark:text-white'
          : 'bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-200',
        isSlim ? 'px-2 rounded-lg' : 'px-4 rounded-2xl',
        className
      )}
    >
      {children}
    </button>
  );
}

export default CategoryTabs;
