import { useCallback, useState } from 'react';

export const useForm = (initialState, imageField = 'imageUrl') => {
  const [formData, setFormData] = useState(initialState);
  const [init, setInit] = useState(!!initialState);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = (e) => {
    if (!init) return;
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChange2 = (name, value) => {
    if (!init) return;
    setFormData((_formData) => ({
      ..._formData,
      [name]: value,
    }));
  };

  const handleConfirm = (onConfirm) => (e) => {
    if (!init || e.key !== 'Enter') return;
    const { name } = e.target;
    onConfirm(name);
  };

  const handleImageChange = useCallback(
    (value) => {
      if (!init) return;
      setFormData((formData) => ({
        ...formData,
        [imageField]: value,
      }));
    },
    [init, imageField]
  );

  const setInitialState = useCallback((data) => {
    setFormData(data);
    setInit(true);
  }, []);

  return [
    formData,
    setFormData,
    handleChange,
    handleImageChange,
    setInitialState,
    isProcessing,
    setIsProcessing,
    handleConfirm,
    handleChange2,
  ];
};
