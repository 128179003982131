import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { uid } from 'uid';
import Select from 'react-select';

import useDataService from '../../hooks/useDataService';
import {
  Input,
  InputLine,
  ModalButton,
  ModalButton2,
  BottomBtnsContainer,
} from './Modal';
import ItemPhoto from './ItemPhoto';
import { useForm } from '../../hooks/useForms';
import { log, SELECT_PROPS, sortObjNameCaseIns } from '../../utils';
import { ProcessingScreen } from '../Page';

export default function HomeModal({ onCancel = () => {} }) {
  const {
    state,
    state: { _tier },
    closeModal,
    addItem,
    ui: { isModalOpen },
    addCloseModalListener,
    removeCloseModalListener,
    totalItems,
    addMessage,
  } = useDataService();
  const { boxId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const listener = () => {
      onCancel();
      removeCloseModalListener(listener);
    };
    addCloseModalListener(listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [formData, setFormData, handleChange, handleImageChange] = useForm(
    { container: boxId },
    'imageUrl'
  );
  const [isProcessing, setIsProcessing] = useState(false);

  const containers = useMemo(() => {
    const _containers = Object.values(state.containers || {});
    _containers.sort(sortObjNameCaseIns);
    return _containers;
  }, [state.containers]);

  const handleChangeBox = (selected) =>
    setFormData({
      ...formData,
      container: selected?.id || '',
      lastMoved: +Date.now(),
    });

  const onAddItem = async () => {
    setIsProcessing(true);
    let imageUrl = formData.imageUrl;
    if (!imageUrl) {
      log('No image to upload, no image taken.');
    }

    if (_tier.maxItems > 0 && totalItems() + 1 > _tier.maxItems) {
      addMessage({
        message: 'Free quota reached',
        desc: 'You have reached the maximum number of items (max 100.) for your tier. Please upgrade to add more items.',
        onConfirm: () => {
          closeModal();
          navigate('/profile#upgrade');
        },
        confirmText: 'Upgrade',
      });
      setIsProcessing(false);
      return;
    }

    addItem({
      id: uid(),
      name: formData.name,
      container: formData.container,
      image: imageUrl || 'https://via.placeholder.com/150',
      createdAt: +Date.now(),
      lastMoved: formData.lastMoved,
      tags: [],
      notes: formData.notes,
    });

    setTimeout(() => {
      setIsProcessing(false);
      setFormData({ ...formData, name: '', imageUrl: '', notes: '' });
      closeModal();
    }, 0);
  };

  const _container = state.containers?.[formData?.container];

  return (
    <>
      <ProcessingScreen isVisible={isProcessing} />
      <h2 className="text-2xl font-bold mb-5 dark:text-gray-200">
        Enter New Item
      </h2>

      <InputLine label="Image Capture">
        {isModalOpen && (
          <ItemPhoto
            name="imageUrl"
            value={formData.imageUrl || ''}
            onChange={handleImageChange}
          />
        )}
      </InputLine>

      <InputLine label="Item name" htmlFor="name">
        <Input
          id="name"
          name="name"
          value={formData.name || ''}
          onChange={handleChange}
          placeholder="Enter the item name"
          required
        />
      </InputLine>

      <InputLine label="Notes" htmlFor="notes">
        <Input
          id="notes"
          name="notes"
          type="textarea"
          value={formData.notes || ''}
          onChange={handleChange}
          placeholder="Other details about this item"
        />
      </InputLine>

      <InputLine label="Place stored" htmlFor="container">
        <div className="mt-2">
          <Select
            className="basic-single"
            isDisabled={false}
            name="container"
            inputId="container"
            options={containers}
            value={_container}
            onChange={handleChangeBox}
            {...SELECT_PROPS}
          />
        </div>
      </InputLine>

      {/*<InputLine label="Tags" htmlFor="tags">*/}
      {/*  <Input*/}
      {/*    id="tags"*/}
      {/*    name="tags"*/}
      {/*    value={formData.tags || ''}*/}
      {/*    onChange={handleChange}*/}
      {/*    placeholder="Enter tags separated by commas"*/}
      {/*  />*/}
      {/*</InputLine>*/}

      <BottomBtnsContainer>
        <ModalButton
          onClick={onAddItem}
          disabled={isProcessing || !(formData.name || formData.imageUrl)}
        >
          Save
        </ModalButton>
        <ModalButton2 onClick={closeModal} disabled={isProcessing}>
          Cancel
        </ModalButton2>
      </BottomBtnsContainer>
    </>
  );
}
