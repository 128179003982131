import React, { useMemo } from 'react';

import { retrieve } from '../../hooks/useLocalStorage';
import { classNames } from '../../utils';
import Page from '../../components/Page';
import usePageTitle from '../../hooks/usePageTitle';

export default function ForgotPassword() {
  usePageTitle('Box it up - Reset Password Email Sent');
  const isDarkMode = useMemo(() => retrieve('isDarkMode'), []);

  return (
    <div className={classNames(isDarkMode ? 'dark' : 'light', 'h-full w-full')}>
      <Page className="px-5 py-6 flex flex-col h-full dark:bg-gray-950">
        <div className="flex-1 flex flex-col items-center justify-center text-center">
          <div
            className={classNames(
              'top-0 w-32 h-32 rounded-md bg-no-repeat bg-center bg-cover'
            )}
            style={{ backgroundImage: 'url(/email.gif)' }}
          />
          <h6
            className={classNames(
              'mt-8 text-2xl font-medium mb-3 text-orange-600'
            )}
          >
            Reset Password <br /> Email Sent!
          </h6>
          <p className="mt-6 text-sm leading-6 dark:text-gray-200">
            Check your email for instructions
            <br /> on how to reset your password.
          </p>
        </div>
      </Page>
    </div>
  );
}
