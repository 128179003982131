import React, { useState } from 'react';
import { useNavigate, redirect } from 'react-router-dom';
import useLocalStorage, { retrieve } from '../../hooks/useLocalStorage';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { classNames, errorLog } from '../../utils';
import usePageTitle from '../../hooks/usePageTitle';
import useAnalytics from '../../hooks/useAnalytics';

export async function loader() {
  const isOnBoarded = retrieve('onboardingCompleted');
  // check that user is logged in
  if (isOnBoarded === true) {
    return redirect('/');
  }
  return null;
}

export default function Onboarding() {
  usePageTitle('Box it up - Onboarding');
  const { logEvent } = useAnalytics();
  const [error, setError] = useState(false);
  const [working, setWorking] = useState(false);
  const navigate = useNavigate();
  const [storeLocally] = useLocalStorage();
  const askForPermission = async () => {
    setWorking(true);
    try {
      // Request user media for video
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      // go to next page
      stream.getTracks().forEach((track) => track.stop());
      // save the result of the permission to the local storage
      storeLocally('hasCameraPermission', true);

      navigate('/');
    } catch (err) {
      setError(true);
      storeLocally('hasCameraPermission', false);
      errorLog('Error accessing the camera:', err);
      logEvent('failed_camera_setup');
    } finally {
      setWorking(false);
      storeLocally('onboardingCompleted', true);
    }
  };

  return (
    <div className="min-h-svh h-dvh flex items-center justify-center">
      <div className="absolute inset-0 bg-gradient-to-br from-red-400 to-yellow-400 opacity-85" />
      <div
        className="absolute inset-0 bg-center bg-no-repeat bg-cover opacity-100"
        style={{ backgroundImage: `url('/bg-boxitup2.svg')` }}
      />
      <div className="z-[1] p-8 max-w-lg text-white">
        <h1 className="text-2xl mb-4">Camera permission</h1>
        <p className="mb-4">
          Just one more step! <span className="font-medium">BoxItUp</span> needs
          your permission to access the camera so you can take pictures of your
          items.
        </p>
        <p>Do you consent to allowing camera access?</p>
        <div className="mt-6 hidden md:flex md:justify-end">
          <button
            onClick={askForPermission}
            disabled={error || working}
            className={classNames(
              'px-5 py-1 bg-white rounded-lg',
              error
                ? 'cursor-not-allowed opacity-70 text-gray-400'
                : 'cursor-pointer text-orange-400 hover:text-orange-600',
              error || working ? 'opacity-70' : ''
            )}
          >
            Allow camera access
          </button>
        </div>
      </div>
      <div className="absolute bottom-0 right-0 w-2/3 sm:w-1/2 md:hidden">
        <button
          onClick={askForPermission}
          disabled={error || working}
          className={classNames(
            'block w-full min-h-16 pr-3 pl-5 py-5 bg-white rounded-tl-full',
            error
              ? 'cursor-not-allowed opacity-70 text-gray-400'
              : 'cursor-pointer text-orange-400 hover:text-orange-600',
            error || working ? 'opacity-70' : ''
          )}
        >
          Allow camera access
        </button>
      </div>
      <ErrorMessage hasError={error} onClick={() => navigate('/')} />
    </div>
  );
}

function ErrorMessage({ hasError, onClick }) {
  if (!hasError) {
    return null;
  }

  return (
    <div className="absolute rounded-md bg-yellow-50 p-4 z-20 mx-3 max-w-md">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            Failed to access the camera
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              We were not able to access your camera device. You can enable
              camera access later but won't be able to take picture of your
              stuff or scan QR codes.
            </p>
          </div>
          <div className="text-right">
            <button
              onClick={onClick}
              className={classNames(
                'mt-4 px-5 py-1 rounded text-sm font-medium text-yellow-800',
                'shadow-sm bg-yellow-400 hover:bg-yellow-500 hover:text-yellow-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
              )}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
