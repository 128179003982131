import React, { useCallback, useEffect, useRef, useState } from 'react';
import { uid } from 'uid';

import { classNames, errorLog, STREAM_CONSTRAINTS } from '../../utils';
import { ModalButton, ModalButton2 } from './Modal';
import { useDataService } from '../../hooks/useDataService';

export default function ItemPhoto({
  value,
  onChange,
  onSave,
  editMode = false,
}) {
  const {
    uploadImage,
    addMessage,
    ui: { isModalOpen },
  } = useDataService();
  const videoRef = useRef(null);
  const streamRef = useRef(null); // To store the stream
  const canvasRef = useRef(null);
  const isCameraOn = useRef({ value: false });

  const [isLiveVideoOn, setLiveVideo] = useState(undefined);
  const [_current, setCurrent] = useState('');
  const [error, setError] = useState(null);
  const [disableAllBtns, setDisableAllBtns] = useState(false);

  const startCamera = useCallback(async () => {
    if (isCameraOn.value) return;
    try {
      // Request user media for video
      isCameraOn.value = true;
      const stream =
        await navigator.mediaDevices.getUserMedia(STREAM_CONSTRAINTS);
      stream.addEventListener('inactive', () => {});
      stream.addEventListener('active', () => {});
      streamRef.current = stream; // Store the stream reference
      videoRef.current.srcObject = stream; // Set the video source to the stream
      videoRef.current.play();

      setLiveVideo(true);
    } catch (err) {
      setError('Failed to access the camera. Please allow camera permissions.');
      isCameraOn.value = false;
      errorLog('Error accessing the camera:', err);
    }
  }, [isCameraOn]);

  useEffect(() => {
    function _closeCamera() {
      if (streamRef.current) {
        streamRef.current.getTracks()?.forEach((track) => track.stop());
      }
    }

    if (isModalOpen) {
      startCamera();
    } else {
      _closeCamera();
    }
    return () => _closeCamera();
  }, [isModalOpen, startCamera]);

  const retake = () => {
    setLiveVideo(true);
    onChange('');
  };

  const cancel = () => {
    setLiveVideo(true);
    closeCamera();
  };

  const closeCamera = () => {
    isCameraOn.value = false;
    setLiveVideo(undefined);
    if (streamRef.current) {
      streamRef.current.getTracks()?.forEach((track) => track.stop());
    }
  };

  const screenshot = useCallback(() => {
    const videoEl = videoRef.current;
    if (!canvasRef.current || !videoEl) return;

    const width = videoEl.offsetWidth;
    const height = videoEl.offsetHeight;
    canvasRef.current.width = width;
    canvasRef.current.height = height;
    const ctx = canvasRef.current.getContext('2d');
    ctx.drawImage(videoEl, 0, 0, width, height);

    const imageUrl = canvasRef.current.toDataURL('image/png');

    setLiveVideo(false);
    setCurrent(imageUrl);

    uploadImage(imageUrl, `${uid()}.png`).then(
      (cloudImageUrl) => {
        onChange(cloudImageUrl);
        setCurrent(cloudImageUrl);
        // addMessage('Image uploaded', '200');
      },
      () => {
        addMessage("Couldn't save image to cloud");
      }
    );
  }, [onChange, uploadImage, addMessage]);

  const handleSave = async () => {
    if (onSave && _current) {
      setDisableAllBtns(true);
      await onSave(_current);
      setDisableAllBtns(false);
      cancel();
    }
  };

  const [isImageFullScreen, setImageFullScreen] = useState(false);
  const toggleImageFullScreen = () => setImageFullScreen((v) => !v);

  const showSavedImage = editMode && isCameraOn.value !== true;
  const showCanvasImage = editMode
    ? !isLiveVideoOn && isCameraOn.value === true
    : !isLiveVideoOn || value;

  return (
    <>
      <div className="my-3 flex flex-col items-center justify-center relative">
        {error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <video
            ref={videoRef}
            className="w-64 h-64 bg-gray-900 dark:bg-gray-950 rounded-md"
            autoPlay
            playsInline
            muted
          />
        )}
        <canvas
          ref={canvasRef}
          className={classNames(
            'absolute pointer-events-none',
            showCanvasImage ? '' : 'opacity-0'
          )}
        />
        {showSavedImage && (
          <div
            className="absolute top-0 w-64 h-64 bg-gray-900 rounded-md bg-no-repeat bg-center bg-cover"
            style={{
              backgroundImage: `url(${value})`,
              ...getImageStyle(isImageFullScreen),
            }}
            onClick={toggleImageFullScreen}
          />
        )}
      </div>
      {isLiveVideoOn === true || isLiveVideoOn === undefined ? (
        <>
          {!editMode || isCameraOn.value ? (
            <ModalButton
              disabled={disableAllBtns || !isCameraOn.value}
              className="mt-3"
              onClick={screenshot}
              blue
            >
              Capture
            </ModalButton>
          ) : (
            <ModalButton2
              disabled={disableAllBtns}
              className="mt-3"
              onClick={startCamera}
            >
              Start Camera
            </ModalButton2>
          )}
          {editMode && isCameraOn.value && (
            <ModalButton2
              disabled={disableAllBtns}
              className="mt-3"
              onClick={cancel}
            >
              Cancel
            </ModalButton2>
          )}
        </>
      ) : (
        <>
          <div className="flex gap-4">
            <ModalButton2
              disabled={disableAllBtns}
              className="mt-3"
              onClick={retake}
            >
              Retake
            </ModalButton2>
            {editMode && onSave && (
              <ModalButton
                disabled={disableAllBtns}
                className="mt-3"
                green
                onClick={handleSave}
              >
                Save
              </ModalButton>
            )}
          </div>
          {editMode && (
            <ModalButton2
              disabled={disableAllBtns}
              className="mt-3"
              onClick={cancel}
            >
              Cancel
            </ModalButton2>
          )}
        </>
      )}
    </>
  );
}

function getImageStyle(isFullScreen) {
  if (!isFullScreen) return {};
  return {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: 'auto',
    height: 'auto',
    backgroundSize: 'contain',
    zIndex: 20,
  };
}
