/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import qrcode from 'esqrcode';

import {
  classNames,
  errorLog,
  log,
  matchUrlPattern,
  snag,
  STREAM_CONSTRAINTS,
} from '../utils';
import { QRLoader } from './icons';
import useDataService from '../hooks/useDataService';

export default function QRDecode({ onSuccess = null }) {
  const navigate = useNavigate();

  const videoRef = useRef(null);
  const streamRef = useRef(null); // To store the stream
  const canvasRef = useRef(null);
  const intervalRef = useRef({});
  const timeoutRef = useRef({});

  const [statusText, setStatus] = useState('Starting camera…');
  const [foundQRCode, setFoundQRCode] = useState(false);

  const [error, setError] = useState(null);
  const {
    ui: { isModalOpen },
    closeModal,
  } = useDataService();

  const startCamera = async () => {
    try {
      // Request user media for video
      const stream =
        await navigator.mediaDevices.getUserMedia(STREAM_CONSTRAINTS);
      streamRef.current = stream; // Store the stream reference
      videoRef.current.srcObject = stream; // Set the video source to the stream
      videoRef.current.play();
      setStatus('Looking for QR code');
    } catch (err) {
      setError('Failed to access the camera. Please allow camera permissions.');
      errorLog('Error accessing the camera:', err);
    }

    const videoEl = videoRef.current;
    const { width, height } = getElSize(videoEl);
    canvasRef.current.width = width;
    canvasRef.current.height = height;
  };

  const closeCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks()?.forEach((track) => track.stop());
    }
  };

  const lookForQRCode = async () => {
    try {
      log('looking for qr code...');
      const videoEl = videoRef.current;
      if (!canvasRef.current || !videoEl) return;

      const { width, height } = getElSize(videoEl);
      const ctx = canvasRef.current.getContext('2d');
      ctx.drawImage(videoEl, 0, 0, width, height);

      // try to decode qrcode
      let result;
      try {
        result = qrcode.decode();
        setFoundQRCode(true);
        decodeStr(result);
      } catch (e) {
        /* QR not found in frame */
      }
    } catch (e) {
      // close stream and cleanup
      errorLog('Unexpected camera error:', e);
      clearInterval(intervalRef.i);
      closeCamera();
    }
  };

  const decodeStr = (str) => {
    setStatus('Decoding QR code');
    clearTimeout(timeoutRef.t);
    const isValidRegex = [/^boxitup\/.+\/container\/.+$/];

    const isValid = isValidRegex.some((regex) => regex.test(str));
    let result;
    if (isValid)
      result = matchUrlPattern(str, 'boxitup/:user/container/:container');
    if (!isValid || !result) {
      setStatus(`Not a valid Box-it-up URL`);
      log('Not a valid Box-it-up URL:', str);
      setFoundQRCode('red');
      timeoutRef.t = setTimeout(resetState, 2000);
      return;
    }

    //go to container page
    clearInterval(intervalRef.i);
    closeModal();
    setTimeout(() => {
      if (onSuccess) onSuccess(result.container, str);
      else navigate(`/boxes/${result.container}?tab=2`);
    }, 20);
  };

  const resetState = () => {
    setStatus('Looking for QR code');
    setFoundQRCode(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      startCamera();
      intervalRef.i = setInterval(() => lookForQRCode(), 1000);
      return () => clearInterval(intervalRef.i);
    } else {
      try {
        closeCamera();
      } catch (e) {
        log('could not close camera');
        snag(e);
      }
      setFoundQRCode(false);
      setStatus('Starting camera…');
    }
    return () => {
      try {
        closeCamera();
      } catch (e) {
        log('could not close camera');
        snag(e);
      }
    };
  }, [isModalOpen]);

  return (
    <div className="min-h-[80vh] flex flex-col">
      <div className="-mt-6 -mx-6 shrink-0 flex flex-col items-center justify-center relative">
        {error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <video
            ref={videoRef}
            className="w-full h-[400px] max-h-[90vh] bg-black  rounded-t-md"
            autoPlay
            playsInline
            muted
          />
        )}
        <VideoOverlay green={!!foundQRCode} red={foundQRCode === 'red'} />
        <canvas
          ref={canvasRef}
          id="qr-canvas"
          className={classNames('absolute pointer-events-none opacity-0')}
        />
      </div>
      <div className="-mb-6 -mx-6 p-6 flex-1 bg-black text-gray-100 flex flex-col items-center">
        <p>{statusText}</p>
        {'Looking for QR code' === statusText && <QRLoader className="mt-3" />}
      </div>
    </div>
  );
}

const VideoOverlay = ({ green, red }) => {
  const _class = red
    ? 'border-red-400'
    : green
      ? 'border-lime-400'
      : 'border-white';
  return (
    <div className="absolute inset-0 flex items-stretch">
      <div className="flex-1 bg-black/25" />
      <div className="w-[60vmin] shrink-0 flex flex-col items-stretch">
        <div className="flex-1 bg-black/25" />
        <div className={classNames('h-[60vmin] shrink-0 relative')}>
          <Corner tl className={_class} />
          <Corner tr className={_class} />
          <Corner bl className={_class} />
          <Corner br className={_class} />
        </div>
        <div className="flex-1 bg-black/25" />
      </div>
      <div className="flex-1 bg-black/25" />
    </div>
  );
};

const Corner = ({
  className = '',
  tl = false,
  tr = false,
  bl = false,
  br = false,
}) => (
  <div
    className={classNames(
      'absolute w-8 h-8',
      tl ? 'top-[-4px] left-[-4px] border-t-4 border-l-4' : '',
      tr ? 'top-[-4px] right-[-4px] border-t-4 border-r-4' : '',
      bl ? 'bottom-[-4px] left-[-4px] border-b-4 border-l-4' : '',
      br ? 'bottom-[-4px] right-[-4px] border-b-4 border-r-4' : '',
      className
    )}
  />
);

function getElSize(el) {
  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
  };
}
