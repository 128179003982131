import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { classNames } from '../utils';
import Page from '../components/Page';
import { CheckIcon } from '../components/icons';
import usePageTitle from '../hooks/usePageTitle';

export default function PaymentSuccess() {
  usePageTitle('Box it up - Payment Success');
  const navigate = useNavigate();
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setAnimate(true);
    setTimeout(() => {
      navigate('/');
    }, 5300);
  }, [navigate]);

  return (
    <Page className="px-5 py-6 flex flex-col h-full dark:bg-gray-950 items-center">
      <div className="relative flex-1 max-h-[50%] flex flex-col items-center justify-end">
        <div
          className={classNames(
            'ball relative bottom-10 w-28 h-28 box-border flex items-center justify-center',
            'transition duration-[250ms] ease-out',
            animate
              ? 'opacity-100 translate-y-0'
              : 'opacity-0 -translate-y-full'
          )}
        >
          <div className="ball_body rounded-full absolute inset-0 bg-lime-500" />
          <div
            className={classNames(
              'ball_shadow-1 rounded-full absolute inset-0 bg-lime-500/20',
              'transition duration-[400ms] ease-in-out delay-[400ms]',
              animate ? 'scale-[130%]' : 'scale-100'
            )}
          />
          <div
            className={classNames(
              'ball_shadow-2 rounded-full absolute inset-0 bg-lime-500/20',
              'transition duration-[400ms] ease-in-out delay-[250ms]',
              animate ? 'scale-[160%]' : 'scale-100'
            )}
          />
          <div
            className={classNames(
              'ball_shadow-3 rounded-full absolute inset-0 bg-lime-500/20',
              'transition duration-[400ms] ease-in-out delay-[100ms]',
              animate ? 'scale-[190%]' : 'scale-100'
            )}
          />
          <CheckIcon className="relative w-16 h-16 text-white stroke-[5px]" />
        </div>
      </div>
      <h6
        className={classNames(
          'mt-8 px-4 text-center text-4xl font-medium text-lime-600 dark:text-lime-500 transition duration-500 ease-out',
          animate ? 'opacity-100 scale-100' : 'opacity-0 scale-[70%]'
        )}
      >
        Thank you!
      </h6>
      <h5
        className={classNames(
          'mt-6 px-8 text-center text-2xl font-medium  text-gray-800 dark:text-gray-200'
        )}
      >
        Payment done Successfully
      </h5>
      <div className="text-center">
        <a
          href="/"
          className={classNames(
            'inline-block mt-10 relative',
            'items-center justify-between border-0 shadow-sm text-sm font-medium text-white bg-lime-500',
            'rounded-full py-3 px-5 relative box-border overflow-hidden',
            'hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500'
          )}
        >
          Back to home page
          <span
            className={classNames(
              'progress absolute h-full w-full bottom-0 left-0 bg-lime-700/10',
              'transition delay-[300ms] duration-[5s] ease-linear',
              animate ? 'translate-x-0' : '-translate-x-full'
            )}
          />
        </a>
      </div>
    </Page>
  );
}
