import React from 'react';
import { classNames, PAGE_SCROLL } from '../utils';

function Header({ scroll }) {
  return (
    <div
      className={classNames(
        'flex items-center justify-between px-5 pt-6',
        'sticky top-0 z-[2]',
        'bg-white dark:bg-gray-900 dark:text-gray-200'
      )}
    >
      <div
        className={classNames(
          'text-2xl font-bold transition-transform',
          scroll >= PAGE_SCROLL.header ? 'translate-y-3 pb-5' : ''
        )}
      >
        B
        <span
          className={classNames(
            'transition-opacity',
            scroll >= PAGE_SCROLL.header ? 'opacity-0' : ''
          )}
        >
          ox<span className="text-orange-500 relative mx-1">it</span>up
          <br />
        </span>
      </div>
      {/*<ProfileAvatar />*/}
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
function ProfileAvatar() {
  return (
    <div className="w-10 h-10 rounded-full overflow-hidden">
      <img src="https://via.placeholder.com/40" alt="Profile" />
    </div>
  );
}

export default Header;
