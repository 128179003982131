import { errorLog } from '../utils';

function useLocalStorage() {
  return [store, retrieve];
}

export const store = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    errorLog('Error setting localStorage', error);
  }
};

export const retrieve = (key, initialValue) => {
  try {
    const item = window.localStorage.getItem(key);
    // Parse stored JSON or return initialValue if none is stored
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    errorLog('Error reading localStorage', error);
    return initialValue;
  }
};

export default useLocalStorage;
