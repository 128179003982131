import { useCallback, useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAnRM5KRf59Io73RrbbeyPXjQK7ZFEHVGk',
  authDomain: 'boxitup-c65de.firebaseapp.com',
  projectId: 'boxitup-c65de',
  storageBucket: 'boxitup-c65de.appspot.com',
  messagingSenderId: '617399145793',
  appId: '1:617399145793:web:85bbb58f4f4216463ebdc0',
  measurementId: 'G-YW1FF3M6KF',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default function useAnalytics() {
  const _logEvent = useCallback((event, data) => {
    if (window.location.hostname === 'localhost') {
      console.log('analytics: logEvent', event, data);
    } else {
      logEvent(analytics, event, data);
    }
  }, []);

  const [once, setOnce] = useState({});
  useEffect(() => {
    const handler = () => setOnce({});

    window.addEventListener('blur', handler);
    return () => window.removeEventListener('blur', handler);
  }, []);

  return {
    logEvent: useCallback(
      (event, data) => {
        _logEvent(event, data);
      },
      [_logEvent]
    ),
    logOnce: useCallback(
      (event, data) => {
        if (once[event]) return;
        _logEvent(event, data);
        setOnce((prev) => ({ ...prev, [event]: true }));
      },
      [_logEvent, once]
    ),
    logPageView: useCallback(
      (data) => {
        _logEvent('page_view', data);
      },
      [_logEvent]
    ),
  };
}
