import React, { useMemo, useRef } from 'react';
import { uid } from 'uid';
import { genQRCodeStr } from '../../utils';
import useDataService from '../../hooks/useDataService';
import usePageTitle from '../../hooks/usePageTitle';
import QRCodeComponent from '../../components/QRCodeComponent';
import Page from '../../components/Page';

const generate10Ids = () => {
  const ids = [];
  for (let i = 0; i < 10; i++) {
    ids.push(uid());
  }
  return ids;
};

export default function PrintQRPage() {
  usePageTitle('Print 10 QR Codes list');
  const { getQRUserId } = useDataService();
  const boxIds = useRef(generate10Ids());

  const qrStrings = useMemo(
    () => boxIds.current.map((id) => genQRCodeStr(getQRUserId(), id)),
    [getQRUserId]
  );

  return (
    <Page className="print:flex-wrap print:h-auto print:overflow-y-visible">
      <div className="pt-4">
        {qrStrings.map((qrStr, index) => (
          <div
            onClick={() => window.print()}
            key={qrStr}
            className="flex flex-col items-center justify-center text-center print:break-before mb-6 font-bold text-lg"
          >
            <QRCodeComponent
              value={qrStr}
              className="w-[180px] h-[180px]"
              rectClassName="fill-white"
            />
            BOX {index + 1}
          </div>
        ))}
      </div>
    </Page>
  );
}
