import React, { useMemo, useState } from 'react';

import Page from '../components/Page';
import {
  frequencies,
  RadioOptions,
  StyledPrice,
  CTA,
  FeatureItem,
  FeatureList,
  Description,
  TierContent,
  TierHeader,
  Frequency,
} from '../components/pricing';
import { TopBackButton } from '../components/icons';
import {
  classNames,
  getLongestExpiryDate,
  isSubcriptionRecurring,
} from '../utils';
import useLocalStorage from '../hooks/useLocalStorage';
import usePageTitle from '../hooks/usePageTitle';
import useDataService from '../hooks/useDataService';
import { useInVewAnalytics } from '../hooks/useIntersectionObserver';
import useAnalytics from '../hooks/useAnalytics';

export default function Pricing() {
  usePageTitle('Box it up - Pricing page');
  const { logEvent } = useAnalytics();
  const standardRef = useInVewAnalytics('view_pricing', { price: '49.99' });
  const subscriptionRef = useInVewAnalytics('view_pricing', { price: '39.99' });

  const {
    state: { profile },
  } = useDataService();
  const [, find] = useLocalStorage();
  const [frequency, setFrequency] = useState(frequencies[1]);
  const showRadio = false;

  const email = useMemo(() => {
    const user = find('user');
    return atob(user).split(':')[0];
  }, [find]);

  const subscriptions = profile?.subscriptions;
  const subType = useMemo(() => {
    if (!subscriptions) return undefined;
    const [, subType] = getLongestExpiryDate(subscriptions);
    if (!subType.id) return 'free';
    return isSubcriptionRecurring(subType.id) ? 'yearly' : 'upfront';
  }, [subscriptions]);

  const tiers = getTiers(email, subType);

  return (
    <Page className="px-5 py-6 flex flex-col md:relative">
      <div className="shrink-0">
        <TopBackButton />
      </div>
      <div
        className={classNames(
          'absolute -z-[1] bg-orange-200 bg-gradient-to-br from-red-300 to-yellow-200',
          'top-0 left-0 right-0 h-1/2 md:top-1 md:left-1 md:right-1 md:h-2/3'
        )}
      />
      <div className="content flex-1 mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2
            className={classNames(
              // 'text-base font-semibold leading-7 text-white',
              'text-3xl sm:text-4xl font-bold tracking-wide text-gray-900'
            )}
          >
            Pricing
          </h2>
          {/*<p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">*/}
          {/*  Super Simple Pricing*/}
          {/*</p>*/}
        </div>

        {showRadio && (
          <RadioOptions frequency={frequency} setFrequency={setFrequency} />
        )}

        <div
          className={classNames(
            'isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none',
            'lg:grid-cols-3'
          )}
        >
          {tiers.map((tier, index) => {
            const ref =
              index === 1 ? standardRef : index === 2 ? subscriptionRef : null;
            const onClick = () => {
              if (index === 1) logEvent('begin_checkout', { price: '49.99' });
              if (index === 2) logEvent('begin_checkout', { price: '39.99' });
              return true;
            };

            return (
              <TierContent key={tier.id}>
                <TierHeader>{tier.name}</TierHeader>
                <Description>{tier.description}</Description>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <StyledPrice
                    ref={ref}
                    price={
                      typeof tier.price === 'string'
                        ? tier.price
                        : tier.price[frequency.value]
                    }
                  />
                  {typeof tier.price !== 'string' ? (
                    <Frequency>{frequency.priceSuffix}</Frequency>
                  ) : null}
                </p>
                <CTA tier={tier} subType={subType} onClick={onClick} />
                <FeatureList>
                  {tier.features.map((feature) => (
                    <FeatureItem key={feature}>{feature}</FeatureItem>
                  ))}
                </FeatureList>
              </TierContent>
            );
          })}
        </div>
      </div>
    </Page>
  );
}

/**
 * @param {string} email
 * @param {string} subType - Subscription type one of 'free', 'upfront', 'yearly'
 */
const getTiers = (email, subType) => [
  {
    name: 'Free plan',
    id: 'tier-free',
    price: 'Free',
    description: 'Free forever, no credit card required. Try it now!',
    features: ['free forever', '2 free boxes', 'Up to 100 items saved'],
    featured: false,
    cta: subType === 'free' ? 'Current plan' : 'Unavailable',
  },
  {
    name: 'One Year Plan',
    id: 'tier-paid',
    href:
      subType && subType === 'free'
        ? `https://api.boxitup.me/payment/standard_upfront/${email}`
        : undefined,
    price: { monthly: '$' + (50 / 12).toFixed(2), annually: '$49.99' },
    description: 'Pay once, update for 1 year and keep your data after that.',
    features: [
      'Unlimited boxes',
      'Unlimited items saved',
      // '24-hour support response time',
      'Unlimited changes for 1 year',
      'Charge only 1 time',
      'Keep your data for free after the plan expires',
    ],
    featured: true,
    cta:
      subType && subType !== 'free'
        ? subType === 'upfront'
          ? 'Current plan'
          : 'Unavailable'
        : 'Buy plan',
  },
  {
    name: 'Subscription',
    id: 'tier-sub',
    href:
      subType && subType === 'free'
        ? `https://api.boxitup.me/payment/standard_yearly/${email}`
        : undefined,
    price: { monthly: '$' + (40 / 12).toFixed(2), annually: '$39.99' },
    description: 'Fully featured plan with unlimited boxes and items.',
    features: [
      'Unlimited boxes',
      'Unlimited items saved',
      // '24-hour support response time',
      'Unlimited changes anytime',
      'Yearly subscription',
      'Keep your data for free after the plan expires',
    ],
    featured: true,
    cta:
      subType && subType !== 'free'
        ? subType === 'yearly'
          ? 'Current plan'
          : 'Unavailable'
        : 'Buy plan',
  },
];
