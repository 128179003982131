import React, { useState } from 'react';

import GalleryList from '../GalleryList';
import { Item } from '../../pages/Storage';
import { Button } from '../CategoryTabs';
import { GalleryIcon, ListIcon } from '../icons';
import ItemList from '../ItemList';
export default function ContentsTab({ box, type, items, boxes, onChangeTab }) {
  return (
    <>
      {box && box.name && (
        <h2 className="text-2xl mt-4 text-center dark:text-gray-200">
          {box.name}
        </h2>
      )}
      {boxes && boxes.length > 0 && (
        <>
          <h6 className="text-2xl mt-4 text-center dark:text-gray-200">
            Boxes ({boxes.length})
          </h6>
          <div className="-mx-5">
            <GalleryList>
              {boxes.map((box, i) => (
                <Item
                  key={`${box.id}-${i}`}
                  {...box}
                  hideCount
                  onClick={onChangeTab}
                />
              ))}
            </GalleryList>
          </div>
        </>
      )}
      <ItemListing items={items} />
      {items.length === 0 && (!boxes || boxes.length === 0) && (
        <div className="h-2/3 flex flex-col justify-center items-center text-lg dark:text-gray-100">
          <div
            className="top-0 w-40 h-40 mb-6 rounded-md bg-no-repeat bg-center bg-cover"
            style={{ backgroundImage: 'url(/empty-box.png)' }}
          />
          {type} is empty :)
        </div>
      )}
    </>
  );
}

function ItemListing({ items }) {
  const [isListingView, setListingView] = useState(true);

  if (items.length <= 0) return null;
  return (
    <>
      <h6 className="text-2xl mt-4 text-center dark:text-gray-200">
        Items ({items.length})
        <Button
          isSlim
          className="absolute right-[62px] z-[1]"
          isActive={!isListingView}
          onClick={() => setListingView(false)}
        >
          <GalleryIcon className="w-[20px]" />
        </Button>
        <Button
          isSlim
          className="absolute right-[20px] z-[1]"
          isActive={isListingView}
          onClick={() => setListingView(true)}
        >
          <ListIcon className="w-[20px]" />
        </Button>
      </h6>

      <div className="-mx-5">
        {isListingView ? (
          <ItemList items={items} />
        ) : (
          <GalleryList items={items} />
        )}
      </div>
    </>
  );
}
