import React, { useState, useEffect, useCallback } from 'react';

import { classNames } from '../../utils';
import useDataService from '../../hooks/useDataService';
import {
  CheckCircleIcon,
  InfoCircleIcon,
  TriangleCircleIcon,
  CloseIcon,
} from '../icons';

export default function AppMessaging() {
  const { messages, closeMessage } = useDataService();

  return (
    <div
      id="appMessaging"
      className="absolute pointer-events-none inset-0 z-20"
    >
      {messages.map((message, index) =>
        message?.onConfirm ? (
          <Confirmation
            onClose={closeMessage}
            key={`${message.id}-message-${index}`}
            {...message}
          />
        ) : (
          <Message
            onClose={closeMessage}
            key={`${message.id}-message-${index}`}
            {...message}
          />
        )
      )}
    </div>
  );
}

function Message({ onClose, message, desc, type, id }) {
  const positive = ['good', '200', 200].includes(type);
  const info = ['info', 'information'].includes(type);
  const [enter, setEnter] = useState(false);
  const [leave, setLeave] = useState(false);

  const handleClose = useCallback(() => {
    setLeave(true);
    setTimeout(() => onClose(id), 300);
  }, [onClose, id]);

  useEffect(() => {
    setTimeout(() => setEnter(true), 0);
    const timeout = setTimeout(() => handleClose(), 3400); //3.3s
    return () => clearTimeout(timeout);
  }, [handleClose]);

  const Icon = positive
    ? CheckCircleIcon
    : info
      ? InfoCircleIcon
      : TriangleCircleIcon;

  return (
    <div
      className={classNames(
        'w-full max-w-xs sm:max-w-sm overflow-hidden rounded-lg shadow-lg mx-auto mt-5',
        'pointer-events-auto ring-1 ring-opacity-15',
        positive
          ? 'bg-green-100 ring-green-700'
          : info
            ? 'bg-white ring-yellow-800'
            : 'bg-red-100 ring-red-700',
        'transition duration-300 ease-in',
        enter
          ? 'translate-y-0 sm:translate-x-0'
          : 'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2',
        leave ? 'opacity-0' : '',
        enter && !leave ? 'opacity-100' : ''
      )}
    >
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <Icon
              aria-hidden="true"
              className={classNames(
                'h-6 w-6',
                positive
                  ? 'text-green-600'
                  : info
                    ? 'text-yellow-500'
                    : 'text-red-500'
              )}
            />
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-black/80">{message}</p>
            {desc && <p className="mt-1 text-sm text-black/60">{desc}</p>}
          </div>
          <div className="ml-4 flex flex-shrink-0">
            <button
              type="button"
              onClick={handleClose}
              className={classNames(
                'text-black/40 hover:text-black/80',
                positive
                  ? 'focus:ring-green-500'
                  : info
                    ? 'focus:ring-gray-300'
                    : 'focus:ring-red-300',
                'inline-flex rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2'
              )}
            >
              <span className="sr-only">Close</span>
              <CloseIcon aria-hidden="true" className="h-5 w-5" />
            </button>
          </div>
          <div
            className={classNames(
              'absolute bottom-0 left-0 w-full h-1',
              'transition-transform duration-[3s] delay-300 ease-linear',
              enter ? '' : '-translate-x-full',
              positive ? 'bg-green-500' : info ? 'bg-gray-300' : 'bg-red-400'
            )}
          />
        </div>
      </div>
    </div>
  );
}

function Confirmation({
  onClose,
  message,
  desc,
  id,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}) {
  const [enter, setEnter] = useState(false);
  const [leave, setLeave] = useState(false);

  const handleClose = useCallback(() => {
    setLeave(true);
    setTimeout(() => onClose(id), 300);
  }, [onClose, id]);

  const handleConfirm = useCallback(() => {
    onConfirm();
    setLeave(true);
    setTimeout(() => onClose(id), 300);
  }, [onConfirm, onClose, id]);

  useEffect(() => {
    setTimeout(() => setEnter(true), 0);
  }, [handleClose]);

  const Icon = InfoCircleIcon;

  return (
    <div
      className={classNames(
        'w-full max-w-xs sm:max-w-sm overflow-hidden rounded-lg shadow-lg mx-auto mt-5',
        'pointer-events-auto ring-1 ring-opacity-15',
        'bg-white ring-gray-800',
        'transition duration-300 ease-in',
        enter
          ? 'translate-y-0 sm:translate-x-0'
          : 'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2',
        leave ? 'opacity-0' : '',
        enter && !leave ? 'opacity-100' : ''
      )}
    >
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <Icon aria-hidden="true" className={classNames('h-6 w-6')} />
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-black/80">{message}</p>
            {desc && <p className="mt-1 text-sm text-black/60">{desc}</p>}
            <div className="mt-3 flex space-x-7">
              <Button onClick={handleConfirm} isConfirm>
                {confirmText}
              </Button>
              {cancelText && (
                <Button onClick={handleClose}>{cancelText}</Button>
              )}
            </div>
          </div>
          <div className="ml-4 flex flex-shrink-0">
            <button
              type="button"
              onClick={handleClose}
              className={classNames(
                'text-black/40 hover:text-black/80',
                'focus:ring-gray-300',
                'inline-flex rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2'
              )}
            >
              <span className="sr-only">Close</span>
              <CloseIcon aria-hidden="true" className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Button({ className, children, isConfirm, ...props }) {
  return (
    <button
      type="button"
      className={classNames(
        isConfirm
          ? 'rounded-md bg-white text-sm font-medium text-orange-600 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
          : 'rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2',
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
}
