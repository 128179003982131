import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '../utils';
import useDataService from '../hooks/useDataService';

function Item({ id, image, name, container, path = '/items' }) {
  const { state } = useDataService();
  const cName = useMemo(
    () => state.containers?.[container]?.name,
    [state.containers, container]
  );

  return (
    <Link
      to={`${path}/${id}`}
      className={classNames(
        'flex items-center justify-between p-4 rounded-md mt-4 shadow',
        'bg-white dark:bg-gray-800 dark:text-gray-200'
      )}
    >
      <div className="flex items-center">
        <img src={image} alt={name} className="w-16 h-16 rounded-md" />
        <div className="ml-4">
          <div className="text-lg font-bold">{name}</div>
          <div className="text-sm text-gray-500">{cName || container}</div>
        </div>
      </div>
      {/*<div className="text-lg font-bold">{price}</div>*/}
    </Link>
  );
}

const Container = ({ children, ...props }) => (
  <div
    className="mx-5 flex-1 md:grid md:gap-4 md:grid-cols-2 2xl:grid-cols-3"
    {...props}
  >
    {children}
  </div>
);
function ItemList({ children, path, minItems, items = [] }) {
  if (items.length) {
    let fillers = [];
    if (minItems && items.length < minItems) {
      fillers = new Array(minItems - items.length).fill({});
    }
    return (
      <Container>
        {items.map((item, index) => (
          <Item key={index} path={path} {...item} />
        ))}
        {fillers.map((_, index) => (
          <span key={index} className="inline-block h-24" />
        ))}
      </Container>
    );
  }
  return <Container>{children}</Container>;
}

ItemList.Item = Item;

export default ItemList;
