// useIntersectionObserver.js
import { useEffect, useState, useRef } from 'react';
import useAnalytics from './useAnalytics';
import { log, snag } from '../utils';

const useIntersectionObserver = (options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    const _ref = ref.current;
    return () => {
      if (_ref) {
        observer.unobserve(_ref);
      }
    };
  }, [options]);

  return [ref, isIntersecting];
};

export default useIntersectionObserver;

export const useInVewAnalytics = (
  event,
  data,
  options = { threshold: 1.0 }
) => {
  const [ref, isIntersecting] = useIntersectionObserver(options);
  const { logOnce } = useAnalytics();

  useEffect(() => {
    if (isIntersecting) logOnce(event, data);
  }, [isIntersecting, logOnce, event, data]);

  return ref;
};

export const useViewportObserver = () => {
  const ref = useRef(null);

  useEffect(() => {
    const listener = () => {
      const windowHeight = window.innerHeight;

      const root = document.getElementById('root');
      const rootHeight = root?.offsetHeight;
      const rootClientHeight = root?.clientHeight;

      const el = ref.current;
      const elHeight = el?.offsetHeight;
      const appHeight = document.getElementById('app-container')?.offsetHeight;

      if (rootHeight !== rootClientHeight) {
        const msg = `root height mismatch: ${rootHeight}, ${rootClientHeight}`;
        log(msg);
        snag(new Error(msg));
      }
      if (!el) return;

      const isCorrect =
        windowHeight === rootHeight &&
        rootHeight === elHeight &&
        appHeight === elHeight;
      if (!isCorrect) {
        const msg = `Bad viewport height mismatch: window: ${windowHeight}, #root: ${rootHeight}, element: ${elHeight}, app: ${appHeight}`;
        log(msg);
        snag(new Error(msg));
      }
    };
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return [ref];
};
