import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import { API_ENDPOINT, classNames, errorLog } from '../../utils';
import { retrieve } from '../../hooks/useLocalStorage';
import usePageTitle from '../../hooks/usePageTitle';

export default function VerifyEmail() {
  usePageTitle('Box it up - Verify Email');
  const { token } = useParams();
  const [animate, setAnimate] = useState(false);
  const [timeToRedirect, setTimeToRedirect] = useState(undefined);
  const isDarkMode = useMemo(() => retrieve('isDarkMode'), []);

  const location = useLocation();
  const navigate = useNavigate();
  const { email } = parseSearch(location.search);

  useEffect(() => {
    setAnimate(true);
  }, []);

  useEffect(() => {
    let mounted = true;
    async function callApi() {
      try {
        const res = await fetch(
          `${API_ENDPOINT.prod}/api/users/confirm-email`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/ld+json',
            },
            body: JSON.stringify({ token, email }),
          }
        );
        return await res.json();
      } catch (error) {
        errorLog(error);
      }
    }

    if (token && email) {
      callApi().then(() => {
        setInterval(() => {
          if (mounted) {
            setTimeToRedirect((prev) => (prev ? prev - 1 : 3));
          }
        }, 1000);
        setTimeout(() => navigate('/'), 3000);
      });
    }
    return () => {
      mounted = false;
    };
  }, [token, email, navigate]);

  return (
    <div className={classNames(isDarkMode ? 'dark' : 'light', 'h-full w-full')}>
      <Page className="px-5 py-6 flex flex-col h-full dark:bg-gray-950">
        <div className="flex-1 flex flex-col items-center justify-center">
          <div
            className={classNames(
              'top-0 w-32 h-32 rounded-md bg-no-repeat bg-center bg-cover',
              'transition duration-300 ease-in-out delay-300',
              animate ? 'opacity-100 scale-100' : 'opacity-0 scale-50'
            )}
            style={{ backgroundImage: 'url(/party-popper.png)' }}
          />
          <h6
            className={classNames(
              'mt-8 text-4xl font-medium mb-3 text-orange-400 dark:text-orange-600 transition duration-500 ease-out',
              animate ? 'opacity-100 scale-100' : 'opacity-0 scale-[70%]'
            )}
          >
            Email Verified!
          </h6>

          <p className="dark:text-gray-200">
            <span className="opacity-0">.</span>
            {timeToRedirect !== undefined &&
              `redirecting in ${timeToRedirect}s…`}
          </p>
          <a
            href="/public"
            className={classNames(
              'inline-block mt-5 relative',
              'items-center justify-between border-0 shadow-sm text-sm font-medium text-white bg-cyan-500',
              'rounded-full py-3 px-5 relative box-border overflow-hidden',
              'hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500'
            )}
          >
            Back to home page
          </a>
        </div>
      </Page>
    </div>
  );
}

const parseSearch = (search) => {
  return search
    .substring(1)
    .split('&')
    .reduce((acc, curr) => {
      const [key, value] = curr.split('=');
      acc[key] = value;
      return acc;
    }, {});
};
