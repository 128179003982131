import React from 'react';
import { NavLink } from 'react-router-dom';

import { tabs } from './common';
import { classNames } from '../../utils';
import { ChevronLeft, ChevronRight, PlusIcon } from '../icons';
import useDataService from '../../hooks/useDataService';

export default function Sidebar({ children }) {
  const {
    state,
    ui: {
      isSidebarOpen,
      modalContent,
      isModalOpen,
      seenEmailReminder,
      isPricingPage,
    },
    setSidebar,
    openModal,
  } = useDataService();
  const hasBanner =
    !seenEmailReminder && state?.profile?.emailConfirmed === false;

  return (
    <>
      <div
        className={classNames(
          'hidden print:hidden md:fixed md:inset-y-0 md:left-0 md:z-50 md:overflow-y-auto md:pb-4',
          'pl-4 md:bg-orange-400 dark:md:bg-orange-600',
          isSidebarOpen ? 'md:w-52' : 'md:w-20',
          isPricingPage ? '' : 'md:block'
        )}
      >
        <div className="flex h-16 shrink-0 items-center ml-1 mt-[18px]">
          <img
            alt="Box it up"
            src="/logo512.png"
            className="h-auto w-10 bg-white dark:bg-gray-900 rounded-lg p-1.5"
          />
          <h1
            className={classNames(
              isSidebarOpen
                ? 'ml-3 text-white dark:text-orange-900 font-bold text-xl'
                : 'hidden'
            )}
          >
            Box<span className="mx-0.5 text-yellow-200">it</span>up
          </h1>
        </div>
        <div className="relative flex justify-end mt-2">
          <button
            onClick={() => setSidebar(!isSidebarOpen)}
            className="w-8 h-8 rounded-tl-full rounded-bl-full bg-orange-100 hover:bg-orange-50 text-orange-600 dark:text-orange-100 dark:bg-gray-800 dark:hover:bg-gray-900 select-none flex items-center justify-center"
          >
            {isSidebarOpen ? (
              <ChevronLeft className="w-5 h-5" />
            ) : (
              <ChevronRight className="w-5 h-5" />
            )}
          </button>
        </div>
        <nav className="mt-8">
          <ul className="flex flex-col items-start space-y-1">
            {tabs.map((tab) => (
              <li key={tab.name} className="self-stretch mr-4">
                {isSidebarOpen ? (
                  <NavLink
                    to={tab.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? 'text-white font-medium bg-orange-600 dark:bg-gray-900'
                          : 'text-orange-100 hover:bg-orange-500 dark:hover:bg-orange-700 hover:text-white',
                        'flex items-center',
                        'h-12 px-3 flex-grow w-full rounded-md'
                      )
                    }
                  >
                    {tab.name === 'Storage' && (
                      <span className="inline-block w-[2px]" />
                    )}
                    {tab.icon}
                    <span className="inline-block w-4" />
                    {tab.name}
                  </NavLink>
                ) : (
                  <NavLink
                    to={tab.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? 'bg-orange-600 dark:bg-gray-900 text-white'
                          : 'text-orange-50 hover:bg-orange-500 dark:hover:bg-orange-700 hover:text-white',
                        'group flex gap-x-3 rounded-md p-3 text-sm font-semibold leading-6',
                        'w-12 h-12 items-center justify-center'
                      )
                    }
                  >
                    {tab.icon}
                    <span className="sr-only">{tab.name}</span>
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div
          tabIndex={-1}
          className={classNames(
            'mt-8 mr-4 cursor-pointer transition',
            'flex items-center gap-x-3 rounded-md p-3 text-sm font-semibold',
            'text-orange-700 hover:bg-orange-50 bg-orange-100 dark:bg-gray-800 dark:hover:bg-gray-900 dark:text-orange-100',
            modalContent
              ? 'opacity-100 scale-100'
              : 'opacity-0 scale-50 pointer-events-none'
          )}
          onClick={() => !isModalOpen && openModal()}
        >
          <PlusIcon className="w-6" />
          {isSidebarOpen ? 'Add New' : ''}
        </div>
      </div>
      <div
        className={classNames(
          'flex-1 w-full min-h-svh h-dvh flex flex-col items-stretch',
          'print:h-auto print:min-h-0',
          isPricingPage ? '' : isSidebarOpen ? 'md:pl-52' : 'md:pl-20',
          isPricingPage ? '' : 'pb-[65px] md:pb-0',
          hasBanner ? 'pt-[25px]' : 'pt-0'
        )}
      >
        {children}
      </div>
    </>
  );
}
