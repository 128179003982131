import { useEffect } from 'react';
import useAnalytics from './useAnalytics';

export default function usePageTitle(title) {
  const { logPageView } = useAnalytics();

  useEffect(() => {
    document.title = title;
    logPageView({ page_title: title.replaceAll(' ', '_') });
  }, [title, logPageView]);
}
