import React from 'react';
import { classNames } from '../utils';

const Page = React.forwardRef(({ children, className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={classNames(
        'flex-1 flex flex-col items-stretch overflow-y-scroll',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
});

export default Page;

export function ProcessingScreen({ isVisible = false }) {
  return (
    <div
      className={classNames(
        'z-50 absolute inset-0 bg-white/50 dark:bg-black/50',
        isVisible ? 'block' : 'hidden'
      )}
    />
  );
}
