import React, { useMemo } from 'react';
import { classNames } from '../../utils';
import useDataService from '../../hooks/useDataService';

const Modal = () => {
  const {
    ui: { isModalOpen, modalContent },
    closeModal,
  } = useDataService();

  return (
    <div
      onClick={() => closeModal()}
      id={'modal'}
      className={classNames(
        'absolute w-full h-dvh box-border top-0 z-10',
        'transition duration-200 ease-in-out',
        'md:p-5 flex items-end md:items-center justify-center',
        'bg-black/50 dark:bg-gray-600/75',
        isModalOpen
          ? 'opacity-1'
          : 'opacity-0 user-select-none pointer-events-none'
      )}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNames(
          'p-6 rounded-t-xl md:rounded-b-xl shadow-lg w-full max-w-lg',
          'transition duration-200 ease-in-out',
          'max-h-[90%] overflow-y-auto',
          'bg-white dark:bg-gray-900',
          isModalOpen ? 'translate-y-[0]' : 'translate-y-[100%]'
        )}
      >
        {modalContent}
      </div>
    </div>
  );
};

export default Modal;

export const BottomBtnsContainer = ({ children }) => (
  <div className="flex gap-4 sticky bottom-0 bg-white/90 dark:bg-gray-900/90">
    {children}
  </div>
);

export function ModalButton({
  children,
  className,
  disabled,
  red,
  green,
  blue,
  ...props
}) {
  const {
    ui: { isDarkMode },
  } = useDataService();
  const style = useMemo(() => {
    if (!isDarkMode) return {};
    return {
      '--tw-ring-offset-color': '#000',
    };
  }, [isDarkMode]);

  const orange = !red && !green && !blue;
  return (
    <button
      {...props}
      disabled={disabled}
      style={style}
      className={classNames(
        'w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white',
        disabled
          ? 'opacity-50'
          : 'focus:outline-none focus:ring-2 focus:ring-offset-2',
        orange
          ? `bg-orange-500 ${disabled ? '' : 'hover:bg-orange-600 focus:ring-orange-500'}`
          : '',
        red
          ? `bg-red-500 ${disabled ? '' : 'hover:bg-red-600 focus:ring-red-500'}`
          : '',
        green
          ? `bg-lime-500 dark:bg-lime-600 ${disabled ? '' : 'hover:bg-lime-600 dark:hover:bg-lime-500 focus:ring-lime-500'}`
          : '',
        blue
          ? `bg-cyan-500 ${disabled ? '' : 'hover:bg-cyan-600 focus:ring-cyan-500'}`
          : '',
        className
      )}
    >
      {children}
    </button>
  );
}
export function ModalButton2({
  children,
  className,
  disabled,
  red = false,
  ...props
}) {
  const {
    ui: { isDarkMode },
  } = useDataService();
  const style = useMemo(() => {
    if (!isDarkMode) return {};
    return {
      '--tw-ring-offset-color': '#000',
    };
  }, [isDarkMode]);

  const original = !red;

  return (
    <button
      {...props}
      disabled={disabled}
      className={classNames(
        'box-border w-full py-2 px-4 border rounded-md shadow-sm text-sm font-medium',
        disabled
          ? 'opacity-70'
          : 'hover:bg-gray-50 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2',
        original
          ? 'border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-900'
          : '',
        original && !disabled
          ? 'focus:ring-gray-200 dark:focus:ring-gray-700 text-gray-700 dark:text-gray-200'
          : 'text-gray-400 dark:text-gray-300',
        red ? 'border-red-500 text-red-500 bg-white dark:bg-gray-900' : '',
        red && !disabled ? 'focus:ring-red-500 dark:focus:ring-red-600' : '',
        className
      )}
      style={style}
    >
      {children}
    </button>
  );
}
export function InputLine({ children, label, htmlFor }) {
  return (
    <div className="mb-4">
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-gray-700 dark:text-gray-400"
      >
        {label}
      </label>
      {children}
    </div>
  );
}

export function Input({
  className,
  overrideClassName,
  type = 'text',
  viewMode = false,
  value,
  emptyPlaceholder = 'N/A',
  ...props
}) {
  if (viewMode) {
    return (
      <div
        className={classNames(
          'mt-1 py-2 text-lg border-b dark:text-gray-100 dark:border-gray-700',
          value ? '' : 'italic text-gray-400'
        )}
      >
        {value ? value : emptyPlaceholder}
      </div>
    );
  }
  if (type === 'textarea') {
    return (
      <textarea
        {...props}
        value={value}
        className={classNames(
          'mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm',
          'dark:bg-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-700',
          className
        )}
      />
    );
  }

  return (
    <input
      {...props}
      type={type}
      value={value}
      className={
        overrideClassName ||
        classNames(
          'mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm',
          'dark:bg-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-700',
          className
        )
      }
    />
  );
}
