import React from 'react';
import { Radio, RadioGroup } from '@headlessui/react';

import { classNames } from '../utils';
import { CheckIcon } from './icons';

export const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
];

export function RadioOptions({ frequency, setFrequency }) {
  return (
    <div className="mt-16 flex justify-center">
      <fieldset aria-label="Payment frequency">
        <RadioGroup
          value={frequency}
          onChange={setFrequency}
          className={classNames(
            'grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200',
            'bg-white'
          )}
        >
          {frequencies.map((option) => (
            <Radio
              key={option.value}
              value={option}
              className="cursor-pointer rounded-full px-2.5 py-1 text-gray-500 data-[checked]:bg-orange-500 data-[checked]:text-white"
            >
              {option.label}
            </Radio>
          ))}
        </RadioGroup>
      </fieldset>
    </div>
  );
}

export const StyledPrice = React.forwardRef(({ price }, ref) => {
  const [whole, decimal] = price.split('.');
  return (
    <span ref={ref} className="flex items-end text-gray-900">
      <span className="text-4xl font-bold tracking-tight">{whole}</span>
      {decimal && (
        <span className="text-lg font-semibold leading-6">.{decimal}</span>
      )}
    </span>
  );
});

export function CTA({ tier, subType, onClick }) {
  const Tag = tier.href ? 'a' : 'button';
  const isLoading = !subType;
  return (
    <Tag
      href={tier.href}
      aria-describedby={tier.id}
      onClick={tier.onClick}
      className={classNames(
        tier.cta.includes('Unavailable')
          ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
          : tier.cta.includes('Current plan')
            ? 'border-gray-200 box-border cursor-default'
            : 'bg-green-500 text-white shadow-sm hover:bg-green-600 focus-visible:outline-green-600',
        isLoading ? 'cursor-wait animate-pulse' : '',
        'w-full border',
        'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
      )}
    >
      {tier.cta}
    </Tag>
  );
}

export const FeatureItem = ({ children }) => (
  <li className="flex gap-x-3">
    <CheckIcon
      aria-hidden="true"
      className={classNames(
        'text-lime-600 bg-lime-200 rounded-full py-1',
        'h-6 w-6 flex-none stroke-[3px]'
      )}
    />
    {children}
  </li>
);

export const FeatureList = ({ children }) => (
  <ul
    className={classNames(
      'text-gray-600',
      'mt-8 space-y-3 text-sm leading-6 xl:mt-10'
    )}
  >
    {children}
  </ul>
);

export const Description = ({ children }) => (
  <p className={classNames('text-gray-600', 'mt-4 text-sm leading-6')}>
    {children}
  </p>
);

export const TierContent = ({ children }) => (
  <div
    className={classNames(
      'ring-gray-200',
      'rounded-3xl p-8 ring-1 xl:p-10',
      'bg-white shadow-md'
    )}
  >
    {children}
  </div>
);

export const TierHeader = ({ children }) => (
  <h3
    className={classNames('text-gray-900', 'text-lg font-semibold leading-8')}
  >
    {children}
  </h3>
);

export const Frequency = ({ children }) => (
  <span
    className={classNames('text-gray-600', 'text-sm font-semibold leading-6')}
  >
    {children}
  </span>
);
