import React, { useEffect } from 'react';
import { Outlet, redirect } from 'react-router-dom';
import { classNames } from './utils';

import './index.css';
import BottomBar from './components/navbar/BottomBar';
import Sidebar from './components/navbar/SideBar';
import Modal from './components/modals/Modal';
import useLocalStorage, { retrieve } from './hooks/useLocalStorage';
import { endpoints } from './utils';
import useDataService, {
  DataServiceContextProvider,
} from './hooks/useDataService';
import AppMessaging from './components/messaging/AppMessaging';
import { useViewportObserver } from './hooks/useIntersectionObserver';

export async function loader() {
  const user = retrieve('user');
  // check that user is logged in
  if (!user) {
    return redirect('/login');
  }
  const res = await fetch(endpoints.auth, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/ld+json',
      authorization: `Basic ${user}`,
    },
  });

  if (!res.ok) {
    return redirect('/login');
  }

  return null;
}

function App() {
  return (
    <DataServiceContextProvider>
      <Container>
        <div
          className={classNames(
            'CONTENT_CONTAINER',
            'min-h-svh h-dvh w-full flex flex-col items-stretch dark:bg-gray-900',
            'print:h-auto print:min-h-0'
          )}
          id={'app-container'}
        >
          <EmailVerification />
          <Sidebar>
            <Outlet />
          </Sidebar>
          <BottomBar />
        </div>
        <Modal />
        <AppMessaging />
      </Container>
    </DataServiceContextProvider>
  );
}

const EmailVerification = () => {
  const { state, ui, addMessage, setUIKV: set } = useDataService();
  const emailConfirmed = state?.profile?.emailConfirmed;
  const seenEmailReminder = ui?.seenEmailReminder;
  const isSidebarOpen = ui?.isSidebarOpen;
  const isPricingPage = ui?.isPricingPage;

  if (emailConfirmed || seenEmailReminder) {
    return null;
  }

  const onClick = () => {
    addMessage({
      message:
        'Check your email for a verification link to confirm your email address',
      onConfirm: () => {
        set('seenEmailReminder', true);
      },
      confirmText: 'OK',
      cancelText: '',
    });
  };

  return (
    <div
      tabIndex={-1}
      className={classNames(
        isPricingPage ? '' : isSidebarOpen ? 'md:pl-52' : 'md:pl-20',
        emailConfirmed === false ? 'h-[25px]' : 'h-[0px]',
        'absolute w-full top-0 left-0 overflow-hidden',
        'box-border flex justify-center items-center text-sm bg-gray-200 dark:bg-gray-700 dark:text-orange-300 transition-all duration-500'
      )}
      onClick={onClick}
    >
      Please verify your email
    </div>
  );
};

const Container = ({ children }) => {
  const [, retrieve] = useLocalStorage();
  const {
    ui: { isDarkMode },
    setDarkMode,
  } = useDataService();
  const [ref] = useViewportObserver();

  useEffect(() => {
    setDarkMode(!!retrieve('isDarkMode'));
  }, [retrieve, setDarkMode]);

  return (
    <div
      ref={ref}
      className={classNames(
        'APP_CONTAINER',
        'overflow-hidden w-full h-dvh',
        'print:h-auto',
        isDarkMode ? 'dark' : 'light'
      )}
    >
      {children}
    </div>
  );
};

export default App;
