import React, { useMemo } from 'react';
import { Link as RLink } from 'react-router-dom';
import { classNames } from '../../utils';
import { retrieve } from '../../hooks/useLocalStorage';

export const Page = ({ children, className }) => {
  const isDarkMode = useMemo(() => retrieve('isDarkMode'), []);

  return (
    <div className={classNames(isDarkMode ? 'dark' : 'light', 'h-full w-full')}>
      <div
        className={classNames(
          'h-full overflow-y-scroll',
          'text-gray-800 bg-gray-100 dark:bg-gray-950 dark:text-gray-200',
          className
        )}
      >
        <div className="container mx-auto px-4 max-w-screen-lg pt-10 pb-20">
          {children}
        </div>
      </div>
    </div>
  );
};

export const Title = ({ children, className }) => {
  return (
    <h2 className={classNames('mt-12 mb-8 text-4xl font-medium', className)}>
      {children}
    </h2>
  );
};

export const Heading = ({ children, className }) => {
  return (
    <h2
      className={classNames(
        'mt-8 mb-4 text-2xl font-medium text-orange-600 dark:text-orange-400',
        className
      )}
    >
      {children}
    </h2>
  );
};

export const SubHeading = ({ children, className }) => {
  return (
    <h2 className={classNames('mt-4 mb-2 text-xl font-medium', className)}>
      {children}
    </h2>
  );
};

export const Link = ({ children, className, asA = false, ...props }) => {
  const Tag = asA ? 'a' : RLink;
  return (
    <Tag
      className={classNames(
        'underline text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300',
        className
      )}
      {...props}
    >
      {children}
    </Tag>
  );
};
