import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  InputLine,
  ModalButton,
  ModalButton2,
  BottomBtnsContainer,
} from './Modal';
import { Tag } from '../../pages/Item';
import StorageModal from './StorageModal';

import useDataService from '../../hooks/useDataService';
import { useForm } from '../../hooks/useForms';
import {
  sortObjNameCaseIns,
  BOX_TYPES,
  reduceById,
  sameIds,
} from '../../utils';

export default function AddBox() {
  const { boxId } = useParams();
  const {
    state,
    closeModal,
    setModalContent,
    addContainer: editContainer,
    addCloseModalListener,
    removeCloseModalListener,
    useAdvancedFeatures: advanced,
  } = useDataService();

  const [box, containers, boxes] = useMemo(() => {
    const _box = state.containers?.[boxId];
    if (!_box) return [];

    const _containers = Object.values(state.containers || {}).filter(
      ({ type, location, container, hidden, id }) =>
        !hidden &&
        ((_box.type === BOX_TYPES.location && type !== BOX_TYPES.location) ||
          (_box.type === BOX_TYPES.superContainer && !type && boxId !== id) ||
          (advanced &&
            (!type || type === BOX_TYPES.superContainer) &&
            boxId !== id))
    );
    _containers.sort(sortObjNameCaseIns);

    let _boxes = {};
    if (_box.type === BOX_TYPES.location) {
      _boxes = _containers
        .filter((c) => c.location === _box.id)
        .reduce(reduceById, {});
    } else if (
      _box.type === BOX_TYPES.superContainer ||
      (advanced && !_box.type)
    )
      _boxes = _containers
        .filter((c) => c.container === _box.id)
        .reduce(reduceById, {});

    return [_box, _containers, _boxes];
  }, [state.containers, boxId, advanced]);

  const [
    selectedBoxes,
    setSelectedBoxes,
    ,
    ,
    setInitialBoxes,
    isProcessing,
    setIsProcessing,
  ] = useForm(undefined);

  useEffect(() => {
    const listener = () => {
      setTimeout(() => setInitialBoxes(boxes), 0);
      removeCloseModalListener(listener);
    };
    addCloseModalListener(listener);
    return () => removeCloseModalListener(listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (id) => () => {
    const childBox = state.containers[id];
    if (!childBox) return;
    setSelectedBoxes({
      ...selectedBoxes,
      [childBox.id]: selectedBoxes[childBox.id] ? undefined : childBox,
    });
  };

  useEffect(() => {
    if (!selectedBoxes && boxes) {
      setInitialBoxes(boxes);
    }
  }, [boxes, selectedBoxes, setInitialBoxes]);

  if (!box) return <div></div>;

  const handleAddNewBox = () => {
    setInitialBoxes(boxes);
    setTimeout(
      () =>
        setModalContent(
          <StorageModal
            type={box.type || (advanced ? 'advanced' : '')}
            onCancel={() => setModalContent(<AddBox />)}
          />
        ),
      10
    );
  };

  const handleSave = () => {
    const updatedAt = +Date.now();
    const key = box.type === BOX_TYPES.location ? 'location' : 'container';
    const update = Object.keys(selectedBoxes).map((id) => {
      const _box = state.containers[id];
      return {
        ..._box,
        [key]: selectedBoxes[id] ? boxId : undefined,
        updatedAt,
      };
    });
    setIsProcessing(true);
    editContainer(update);
    closeModal();
    setIsProcessing(false);
  };

  return (
    <>
      <h2 className="text-2xl font-bold mb-5 dark:text-gray-200">
        Choose which box to add
      </h2>
      <div className="mb-4">
        <ModalButton green onClick={handleAddNewBox}>
          + Add New Box
        </ModalButton>
      </div>

      <InputLine label="Place stored" htmlFor="container">
        <div className="flex flex-wrap gap-2 mt-2">
          {containers.map((c) => (
            <Tag
              key={c.id}
              name={c.name}
              id={c.id}
              active={() => {
                return !!selectedBoxes?.[c.id];
              }}
              onClick={handleClick}
            >
              {c.name}
            </Tag>
          ))}
        </div>
      </InputLine>
      <BottomBtnsContainer>
        <ModalButton
          onClick={handleSave}
          disabled={isProcessing || sameIds(boxes, selectedBoxes)}
        >
          Save
        </ModalButton>
        <ModalButton2 onClick={closeModal} disabled={isProcessing}>
          Cancel
        </ModalButton2>
      </BottomBtnsContainer>
    </>
  );
}
