import { useRef, useState, useEffect } from 'react';
import { PAGE_SCROLL } from '../utils';

function useScrollTracker() {
  const scrollRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(PAGE_SCROLL.top);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const scrollTop = scrollRef.current.scrollTop;
        switch (true) {
          case scrollTop >= 110: {
            setIsScrolled(PAGE_SCROLL.categories);
            break;
          }
          case scrollTop >= 50: {
            setIsScrolled(PAGE_SCROLL.search);
            break;
          }
          case scrollTop >= 10: {
            setIsScrolled(PAGE_SCROLL.header);
            break;
          }
          default:
            setIsScrolled(PAGE_SCROLL.top);
        }
      }
    };

    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return [scrollRef, isScrolled];
}

export default useScrollTracker;
