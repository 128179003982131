import React from 'react';
import {
  Heading,
  Page,
  SubHeading,
  Title,
  Link,
} from '../../components/site/terms';
import usePageTitle from '../../hooks/usePageTitle';
import '../../components/site/terms.css';

export default function TermsOfService() {
  usePageTitle('Box it up - Terms of service');

  return (
    <Page>
      <Title>Terms of Service</Title>
      <Heading>Introduction</Heading>
      <p>
        Welcome to BoxItUp! These Terms of Service ("Terms") govern your use of
        the BoxItUp application ("App"), operated by BoxItUp Inc. ("we," "our,"
        "us"). By accessing or using our App, you agree to comply with and be
        bound by these Terms. If you do not agree to these Terms, please do not
        use the App.
      </p>
      <Heading>1. Use of the App</Heading>
      <SubHeading>Eligibility</SubHeading>
      <p>
        You must be at least 13 years old to use the App. By using the App, you
        represent and warrant that you meet this age requirement.
      </p>
      <SubHeading>License</SubHeading>
      <p>
        We grant you a limited, non-exclusive, non-transferable, revocable
        license to use the App solely for your personal, non-commercial use.
      </p>
      <SubHeading>Account Registration</SubHeading>
      <p>
        You may be required to create an account to access certain features of
        the App. You agree to provide accurate, complete, and current
        information during the registration process and to update such
        information as necessary.
      </p>
      <Heading>2. User Responsibilities</Heading>
      <SubHeading>Proper Use</SubHeading>
      <p>
        You agree to use the App only for lawful purposes and in accordance with
        these Terms. You will not use the App:
        <ul>
          <li>
            To upload, post, or otherwise transmit any content that is unlawful,
            harmful, or otherwise objectionable.
          </li>
          <li>
            To impersonate any person or entity or falsely state or otherwise
            misrepresent your affiliation with a person or entity.
          </li>
          <li>
            To interfere with or disrupt the operation of the App or the servers
            or networks used to make the App available.
          </li>
        </ul>
      </p>
      <SubHeading>Content</SubHeading>
      <p>
        You are responsible for all content that you upload, post, or otherwise
        transmit via the App. You retain all rights to your content, but by
        uploading, posting, or otherwise transmitting content to the App, you
        grant us a non-exclusive, worldwide, royalty-free license to use,
        reproduce, modify, adapt, publish, and display such content.
      </p>
      <Heading>3. Intellectual Property</Heading>
      <p>
        All intellectual property rights in the App, including but not limited
        to software, design, text, graphics, and interfaces, are owned by us or
        our licensors. You may not use, reproduce, or distribute any content
        from the App without our prior written permission.
      </p>
      <Heading>4. Privacy</Heading>
      <p>
        Your use of the App is also governed by our Privacy Policy, which can be
        found{' '}
        <Link to="../privacy-policy" relative>
          here
        </Link>
        . By using the App, you consent to the collection and use of your
        information as described in the Privacy Policy.
      </p>
      <Heading>5. Termination</Heading>
      <p>
        We reserve the right to terminate or suspend your access to the App,
        without prior notice or liability, for any reason, including if you
        breach these Terms.
      </p>
      <Heading>6. Disclaimers</Heading>
      <SubHeading>No Warranty</SubHeading>
      <p>
        The App is provided "as is" and "as available" without warranties of any
        kind, either express or implied, including but not limited to implied
        warranties of merchantability, fitness for a particular purpose, or
        non-infringement.
      </p>
      <SubHeading>Limitation of Liability</SubHeading>
      <p>
        To the fullest extent permitted by applicable law, we shall not be
        liable for any indirect, incidental, special, consequential, or punitive
        damages, or any loss of profits or revenues, whether incurred directly
        or indirectly, or any loss of data, use, goodwill, or other intangible
        losses, resulting from (a) your use or inability to use the App; (b) any
        unauthorized access to or use of our servers and/or any personal
        information stored therein; (c) any interruption or cessation of
        transmission to or from the App; (d) any bugs, viruses, trojan horses,
        or the like that may be transmitted to or through our App by any third
        party; (e) any errors or omissions in any content or for any loss or
        damage incurred as a result of the use of any content posted, emailed,
        transmitted, or otherwise made available through the App; and/or (f) the
        defamatory, offensive, or illegal conduct of any third party.
      </p>
      <Heading>7. Governing Law</Heading>
      <p>
        These Terms shall be governed by and construed in accordance with the
        laws of the jurisdiction in which BoxItUp parent company, Crystal High
        Tech ltd. is headquartered, without regard to its conflict of law
        principles.
      </p>
      <Heading>8. Changes to Terms</Heading>
      <p>
        We reserve the right to modify or replace these Terms at any time. If we
        make material changes, we will provide notice through the App or by
        other means to provide you the opportunity to review the changes before
        they become effective. Your continued use of the App after any such
        changes constitutes your acceptance of the new Terms.
      </p>
      <Heading>9. Contact Us</Heading>
      <p>
        If you have any questions about these Terms, please contact us at{' '}
        <Link href="mailto:lauriane.kayungu+boxitupsupport@gmail.com" asA>
          support@boxitup.me
        </Link>
        .
      </p>
      <p>
        By using the BoxItUp app, you acknowledge that you have read,
        understood, and agree to be bound by these Terms of Service.
      </p>
    </Page>
  );
}
