import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useForm } from '../../hooks/useForms';
import { API_ENDPOINT, classNames } from '../../utils';
import {
  Corner,
  CornerContainer,
  FieldError,
  FieldLine,
  Input,
  is8Char,
  LoginBtn,
  Panel,
  PasswordBtn,
  signupData,
} from '../../components/login';
import { CheckIcon } from '../../components/icons';
import usePageTitle from '../../hooks/usePageTitle';

export default function ResetPassword() {
  usePageTitle('Box it up - Reset Password your password');
  const { token } = useParams();
  const [form, , handleChange, , , , , , change] = useForm({});
  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    change('error', '');
    setSubmitting(true);

    let isValid = ['password-login', 'password-verify']
      .map((key, i) => {
        const errorKey = `error-reset-${i}`;
        const value = form[key];
        const v = !!value && is8Char(value);
        if (!v) change(errorKey, 'Password must be at least 8 characters.');
        else change(errorKey, '');
        return v;
      })
      .reduce((a, b) => a && b);

    if (isValid) {
      const v = form['password-login'] === form['password-verify'];
      if (!v) change('error-reset-0', 'Passwords do not match.');
      isValid = v && isValid;
    }

    if (!isValid) {
      return setSubmitting(false);
    }

    const password = form['password-login'];
    const url = `${API_ENDPOINT.prod}/api/forgot-password/${token}`;
    let res = await fetch(url);

    if (!res.ok) {
      change(
        'error',
        <span>
          Your reset link has expired. Please request a new one{' '}
          <a
            href="/auth/Login?reset-password=true"
            className="underline hover:text-orange-700"
          >
            here
          </a>
        </span>
      );
      return setSubmitting(false);
    }

    res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    });

    if (!res.ok) {
      change('error-login', 'Could not reset password. Please try again.');
      return setSubmitting(false);
    }
    // else
    navigate('/login');
    setSubmitting(false);
  };

  return (
    <div className="min-h-svh h-dvh bg-gradient-to-br from-red-300 to-yellow-200">
      <div
        id="main-login-content"
        className={classNames(
          'w-full h-full flex flex-col items-center p-4',
          'overflow-y-scroll snap-y snap-mandatory scroll-pt-4',
          'transition-transform duration-[0.8s] ease-in-out',
          'md:justify-center'
        )}
      >
        <Panel
          className={classNames(
            'pt-1 snap-normal snap-start md:transition md:duration-500'
          )}
        >
          <h2
            className={classNames(
              'flex text-xl font-bold my-6 flex-col justify-center transition-opacity text-gray-900'
            )}
          >
            Reset Password
          </h2>
          {signupData(form)
            .slice(2)
            .map(({ id, ...f }, i) => (
              <FieldLine
                key={id}
                label={i === 0 ? 'Type your new password' : f.label}
                htmlFor={id}
              >
                <Input
                  type={f.type}
                  id={id}
                  name={f.name}
                  value={form[f.name] || ''}
                  onChange={handleChange}
                  placeholder={f.placeholder}
                  required
                />
                {f.hasBtn && (
                  <PasswordBtn
                    viewPassword={form[`viewPassword-${i + 2}`]}
                    onClick={() =>
                      change(
                        `viewPassword-${i + 2}`,
                        !form[`viewPassword-${i + 2}`]
                      )
                    }
                  />
                )}
                <FieldError form={form} index={i} type="reset" />
              </FieldLine>
            ))}
          {form['error'] && (
            <div className="my-3 text-center">
              <p className="text-sm text-red-500">{form['error']}</p>
            </div>
          )}
          <div className="mt-1">
            <p
              className={classNames(
                'text-sm flex items-center gap-2',
                is8Char(form['password-login']) ? 'text-green-600' : ''
              )}
            >
              {is8Char(form['password-login']) ? (
                <CheckIcon className="w-3 h-3 stroke-[3px]" />
              ) : (
                '-'
              )}{' '}
              8 characters minimum
            </p>
          </div>
          <LoginBtn
            looksDisabled={isSubmitting}
            onClick={handleResetPassword}
            className="flex w-full mt-6 z-[2]"
          >
            Reset Password
          </LoginBtn>
          <div className="hidden md:block mb-8" />
        </Panel>
        <div
          className={classNames(
            'flex flex-col h-[35dvh] w-full relative px-6 pt-6 md:hidden'
          )}
          style={{ transform: 'rotateY(180deg)' }}
        >
          <CornerContainer top>
            <Corner className={classNames('w-full')} />
          </CornerContainer>
        </div>
      </div>
    </div>
  );
}
