export const PAGE_SCROLL = {
  top: 0,
  header: 1,
  search: 2,
  categories: 3,
};

export const STREAM_CONSTRAINTS = {
  video: {
    width: { min: 576, ideal: 720, max: 1080 },
    height: { min: 576, ideal: 720, max: 1080 },
    facingMode: 'environment',
  },
};

export const BOX_TYPES = {
  superContainer: 'superContainer',
  location: 'location',
};

export const SELECT_PROPS = {
  isClearable: true,
  isSearchable: true,
  blurInputOnSelect: false,
  menuPortalTarget: null,
  menuIsOpen: true,
  getOptionLabel: (option) => option.name,
  getOptionValue: (option) => option.id,
  classNames: {
    menu: () => `relative`,
    option: () =>
      `inline-flex items-center rounded-lg py-1.5 px-2 box-border text-base bg-gray-100 dark:bg-gray-800 
                  dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 user-select-none cursor-default`,
    menuList: () => `flex flex-wrap gap-2 mt-2`,
  },
  styles: {
    menu: () => ({}),
    option: (provided, { isSelected }) => {
      return {
        backgroundColor: isSelected ? '#f97316' : undefined,
        color: isSelected ? '#fff' : undefined,
        ':hover': {
          backgroundColor: isSelected ? '#d97706' : undefined,
          color: isSelected ? '#fff' : undefined,
        },
      };
    },
  },
};

export const API_ENDPOINT = {
  dev: 'http://localhost:5001/storehi',
  stage:
    'https://everything-everywhere-all-once-6b8bcc92b667.herokuapp.com/storehi',
  prod: 'https://api.boxitup.me',
};

const _endpoints = {
  db: {
    dev: `${API_ENDPOINT.dev}/api/data`,
    prod: `${API_ENDPOINT.prod}/api/content`,
  },
  dbBackup: {
    dev: `${API_ENDPOINT.dev}/api/data`,
    prod: `${API_ENDPOINT.stage}/api/data`,
  },
  upload: {
    dev: `${API_ENDPOINT.dev}/api/upload`,
    prod: `${API_ENDPOINT.prod}/api/files`,
  },
  auth: {
    dev: `${API_ENDPOINT.dev}/api/auth/login`,
    prod: `${API_ENDPOINT.prod}/api/content`,
  },
  user: {
    prod: `${API_ENDPOINT.prod}/api/users`,
  },
};
export const endpoints = {
  db: _endpoints.db.prod,
  dbBackup: _endpoints.dbBackup.prod,
  upload: _endpoints.upload.prod,
  auth: _endpoints.auth.prod,
  user: _endpoints.user.prod,
};

export const initialAppState = {
  items: {
    e1: {
      id: 'e1',
      image: '/my-first-item.png',
      icon: 'ClotheIcon',
      name: 'My brown shoes',
      notes: `Store your items away that you won’t need for a while. 
        Keep record of what you store here and where. 
        Enjoy finding your items back in a few touches.`,
      container: 'c1',
    },
  },
  containers: {
    c1: {
      id: 'c1',
      name: 'Winter clothes & shoes',
      icon: 'clotheHanger',
      useQrCode: false,
    },
  },
  profile: {
    // name
    // darkMode: true, //dark mode should stay device specific
    emailConfirmed: false,
  },
  _tier: {
    type: 'free',
    maxBoxes: 2,
    maxItems: 100,
  },
};
