import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  Outlet,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import './index.css';

import App, { loader as appLoader } from './App';
import Home, { loader as homeLoader } from './pages/Home';
import Profile from './pages/Profile';
import Storage from './pages/Storage';
import Login, { loader as loginLoader } from './pages/auth/Login';
import Onboarding, {
  loader as OnboardingLoader,
} from './pages/auth/Onboarding';
import Item, { ItemNotFound } from './pages/Item';
import Box, { BoxNotFound, loader as boxLoader } from './pages/Box';
import Pricing from './pages/Pricing';
import PaymentSuccess from './pages/PaymentSuccess';
import PrintQRPage from './pages/app/PrintQRPage';

import VerifyEmail from './pages/auth/VerifyEmail';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';

import ErrorBoundary from './components/ErrorCatching';
import TermsOfService from './pages/site/TermsOfService';
import PrivacyPolicy from './pages/site/PrivacyPolicy';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary FallbackComponent={() => <ItemNotFound errorCode="500" />}>
        <App />
      </ErrorBoundary>
    ),
    loader: appLoader,
    errorElement: <ItemNotFound errorCode="404" />,
    children: [
      { index: true, element: <Home />, loader: homeLoader },
      {
        path: 'boxes',
        element: <Storage />,
      },
      {
        path: 'boxes/:boxId',
        loader: boxLoader,
        element: <Box />,
        errorElement: <BoxNotFound />,
      },
      {
        path: 'items/:itemId',
        element: <Item />,
        errorElement: <ItemNotFound />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'pricing',
        element: <Pricing />,
      },
      {
        path: 'payment/success/*',
        element: <PaymentSuccess />,
      },
      {
        path: 'payment/canceled/*',
        loader: () => {
          return redirect('/pricing');
        },
      },
      {
        path: 'printing/qrlist',
        element: <PrintQRPage />,
      },
    ],
  },
  {
    path: '/site',
    element: <Outlet />,
    children: [
      {
        path: 'terms',
        element: <TermsOfService />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    path: '/login',
    element: (
      <ErrorBoundary>
        <Login />
      </ErrorBoundary>
    ),
    loader: loginLoader,
  },
  {
    path: '/onboarding',
    element: (
      <ErrorBoundary>
        <Onboarding />
      </ErrorBoundary>
    ),
    loader: OnboardingLoader,
  },
  {
    path: '/verify-email/:token',
    element: (
      <ErrorBoundary>
        <VerifyEmail />
      </ErrorBoundary>
    ),
  },
  {
    path: '/forgot-password',
    element: (
      <ErrorBoundary>
        <ForgotPassword />
      </ErrorBoundary>
    ),
  },
  {
    path: '/reset-password/:token?',
    element: (
      <ErrorBoundary>
        <ResetPassword />
      </ErrorBoundary>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
