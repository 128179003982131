import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import SearchBar from '../components/SearchBar';
import GalleryList from '../components/GalleryList';
import Page from '../components/Page';
import useDataService from '../hooks/useDataService';
import StorageModal from '../components/modals/StorageModal';
import {
  BOX_TYPES,
  classNames,
  searchItems,
  shallowCopy,
  sortByCreatedAt,
} from '../utils';
import {
  nameToElementMap,
  ChevronRight,
  WarehouseSm,
  LocationIcon,
  WarehouseMd,
} from '../components/icons';
import QRDecode from '../components/QRDecode';

function Storage() {
  const {
    setModalContent,
    state,
    openModal,
    addCloseModalListener,
    removeCloseModalListener,
  } = useDataService();
  const profileUseQrCode = state?.profile?.useQrCode || false;
  const [query, setQuery] = useState('');

  const containerMap = useMemo(() => {
    const containerMap = shallowCopy(state.containers);
    const containerList = Object.values(containerMap);

    Object.values(state.items || {})
      .filter((item) => !item.hidden)
      .forEach((item) => {
        containerMap[item.container] = shallowCopy(
          containerMap[item.container]
        );
        const c = containerMap[item.container];
        c.items = c.items || [];
        c.items.push(item);
      });

    containerList.forEach((cItem) => {
      ['location', 'container'].forEach((key) => {
        if (cItem[key]) {
          containerMap[cItem[key]] = shallowCopy(containerMap[cItem[key]]);
          const c = containerMap[cItem[key]];
          c.boxes = c.boxes || {};
          c.boxes[cItem.id] = cItem;
        }
      });
    });

    return containerMap;
  }, [state.containers, state.items]);

  const [boxes, containers, locations] = useMemo(() => {
    const [_boxes, _containers, _locations] = Object.values(containerMap)
      .filter((c) => !!c.id)
      .reduce(
        ([_boxes, _containers, _locations], c) => {
          if (c.type === BOX_TYPES.superContainer) _containers.push(c);
          else if (c.type === BOX_TYPES.location) _locations.push(c);
          else _boxes.push(c);
          return [_boxes, _containers, _locations];
        },
        [[], [], []]
      );

    _boxes.sort(sortByCreatedAt);
    _containers.sort(sortByCreatedAt);
    _locations.sort(sortByCreatedAt);

    return [_boxes, _containers, _locations];
  }, [containerMap]);

  const filtered = useMemo(() => {
    if (!query || query.length < 1) return { boxes, containers, locations };
    return {
      boxes: searchItems(boxes, query),
      containers: searchItems(containers, query),
      locations: searchItems(locations, query),
    };
  }, [boxes, containers, locations, query]);

  useEffect(() => {
    setModalContent(<StorageModal />);
    return () => setModalContent(null);
  }, [setModalContent]);

  const handleQRClick = () => {
    setModalContent(<QRDecode />);
    const listener = () => {
      setTimeout(() => setModalContent(<StorageModal />), 100);
      removeCloseModalListener(listener);
    };
    addCloseModalListener(listener);
    setTimeout(() => openModal(), 10);
  };

  return (
    <Page id={'homepage'}>
      <SearchBar
        placeholder={'Which box are you looking for?'}
        value={query}
        onChangeValue={setQuery}
        onClick={handleQRClick}
      />
      {filtered.locations.length > 0 && (
        <SubHeading>
          <LocationIcon className="h-5 w-5 mr-2" />
          Locations
        </SubHeading>
      )}
      {filtered.locations.map((c) => (
        <BoxLink key={c.id} to={`/boxes/${c.id}`}>
          {c.name}
        </BoxLink>
      ))}

      {filtered.containers.length > 0 && (
        <SubHeading>
          <WarehouseMd className="h-5 w-5 mr-2" />
          Containers
        </SubHeading>
      )}
      {filtered.containers.map((c) => (
        <BoxLink key={c.id} to={`/boxes/${c.id}`}>
          {c.name}
        </BoxLink>
      ))}

      {filtered.boxes.length > 0 && (
        <SubHeading>
          <WarehouseSm className="h-5 w-5 mr-2" />
          Boxes
        </SubHeading>
      )}
      <GalleryList>
        {filtered.boxes.map((c) => (
          <Item key={c.id} {...c} profileUseQrCode={profileUseQrCode} />
        ))}
      </GalleryList>
    </Page>
  );
}

const SubHeading = ({ children }) => (
  <h4
    className={classNames(
      'pl-5 mr-[30%] mt-4 mb-2 text-lg border-b-2 text-orange-600 dark:text-orange-400 border-orange-600 dark:border-orange-400',
      'md:pl-0 md:ml-5 flex items-center'
    )}
  >
    {children}
  </h4>
);

const BoxLink = ({ children, ...props }) => (
  <Link
    {...props}
    className="mx-5 h-10 flex-shrink-0 flex items-center text-cyan-700 dark:text-cyan-200"
  >
    {children}
    <ChevronRight className="w-4 h-4 ml-1 relative top-[1px]" />
  </Link>
);

export function Item({
  id,
  name,
  icon,
  qrCodeStr,
  idNumber,
  useQrCode,
  items,
  boxes,
  type,
  hideCount = false,
  onClick,
  profileUseQrCode,
}) {
  const path = '/boxes';
  const Icon = nameToElementMap[icon];
  const hasSmallerIcon = ['medicine', 'temporary'].includes(icon);

  return (
    <Link
      to={`${path}/${id}`}
      className={classNames(
        'flex flex-col rounded-lg text-center items-center shadow',
        'bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200'
      )}
      onClick={onClick}
    >
      <div className="w-full flex items-center justify-center">
        {Icon && (
          <Icon
            className={classNames('w-2/3 h-2/3', hasSmallerIcon ? 'p-2' : '')}
          />
        )}
      </div>
      <div className="my-2 mx-2 dark:text-gray-200">
        <div className="text-md">{name}</div>
        {!hideCount && (
          <>
            {type ? (
              <div className="text-sm text-gray-500">
                {Object.keys(boxes || {}).length || 0} boxes
              </div>
            ) : (
              <div className="text-sm text-gray-500">
                {items?.length || 0} items
              </div>
            )}
          </>
        )}
        {profileUseQrCode || (useQrCode !== false && qrCodeStr) ? (
          <div className="text-sm text-green-500">QR code enabled</div>
        ) : (
          !!idNumber && (
            <div className="text-sm text-green-500">#{idNumber}</div>
          )
        )}
      </div>
    </Link>
  );
}

export default Storage;
