import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '../utils';
import useDataService from '../hooks/useDataService';

const Container = ({ children, cols3 = false, ...props }) => (
  <div className="mx-5 flex-1 py-4">
    <div
      className={classNames(
        'grid gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6',
        cols3 ? 'grid-cols-3' : 'grid-cols-2'
      )}
      {...props}
    >
      {children}
    </div>
  </div>
);

const GalleryList = ({ path, children, cols3, items = [] }) => {
  if (items.length) {
    return (
      <Container cols3={cols3}>
        {items.map((item, index) => (
          <Item key={index} path={path} {...item} />
        ))}
      </Container>
    );
  }
  return <Container cols3={cols3}>{children}</Container>;
};

function Item({
  id,
  image,
  name,
  container,
  showContainer = true,
  isLink = true,
  className,
  path = '/items',
  onClick,
}) {
  const { state } = useDataService();
  const cName = useMemo(
    () => state.containers?.[container]?.name,
    [state.containers, container]
  );
  const El = isLink ? Link : 'div';

  return (
    <El
      to={`${path}/${id}`}
      className={classNames(
        'flex flex-col rounded-lg text-center items-center shadow',
        className ? className : 'bg-white dark:bg-gray-800'
      )}
      onClick={onClick}
    >
      <div className="aspect-w-1 aspect-h-1 w-full">
        <img src={image} alt={name} className="object-cover rounded-md" />
      </div>
      <div className="my-2 dark:text-gray-200">
        <div className="text-md">{name}</div>
        {showContainer && container && (
          <div className="text-sm text-gray-500">{cName || container}</div>
        )}
      </div>
    </El>
  );
}

GalleryList.Item = Item;

export default GalleryList;
