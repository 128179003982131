import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

Bugsnag.start({
  apiKey: '2f4f4ee38794df8c142bfc109b842b71',
  plugins: [new BugsnagPluginReact()],
  enabledBreadcrumbTypes: ['error', 'navigation'],
});
BugsnagPerformance.start({ apiKey: '2f4f4ee38794df8c142bfc109b842b71' });

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export default ErrorBoundary;
export { Bugsnag };
