import React from 'react';
import { Heading, Page, SubHeading, Title } from '../../components/site/terms';
import usePageTitle from '../../hooks/usePageTitle';
import '../../components/site/terms.css';

export default function PrivacyPolicy() {
  usePageTitle('Box it up - Privacy Policy');

  return (
    <Page>
      <Title>Privacy Policy</Title>
      <Heading>Introduction</Heading>
      <p>
        Welcome to BoxItUp! We are committed to protecting your privacy. This
        Privacy Policy explains how BoxItUp Inc. ("we," "our," "us") collects,
        uses, discloses, and safeguards your information when you use the
        BoxItUp application ("App"). Please read this Privacy Policy carefully.
        If you do not agree with the terms of this Privacy Policy, please do not
        use the App.
      </p>
      <Heading>1. Information We Collect</Heading>
      <SubHeading>Personal Information</SubHeading>
      <p>
        When you register for an account or use the App, we may collect personal
        information that can identify you, such as your name, email address, and
        other contact details.
      </p>
      <SubHeading>Usage Data</SubHeading>
      <p>
        We may collect information about your use of the App, such as the types
        of content you view or engage with, the features you use, and the
        actions you take. This data helps us understand how users interact with
        the App and improve our services.
      </p>
      <SubHeading>Device Information</SubHeading>
      <p>
        We may collect information about the device you use to access the App,
        including the device's IP address, operating system, and browser type.
        This information helps us optimize the App for different devices and
        enhance your user experience.
      </p>
      <SubHeading>Location Data</SubHeading>
      <p>
        With your consent, we may collect and process information about your
        device's precise location. This information is used to provide
        location-based services within the App.
      </p>
      <Heading>2. How We Use Your Information</Heading>
      <SubHeading>To Provide and Improve the App</SubHeading>
      <p>
        We use your information to operate, maintain, and enhance the App,
        including providing customer support and personalizing your experience.
      </p>
      <SubHeading>To Communicate with You</SubHeading>
      <p>
        We may use your personal information to send you updates, security
        alerts, and support messages. We may also send you promotional messages
        and other information that may be of interest to you.
      </p>
      <SubHeading>To Analyze and Develop Services</SubHeading>
      <p>
        We use usage data and other information to analyze trends and develop
        new features and services.
      </p>
      <SubHeading>For Security and Legal Purposes</SubHeading>
      <p>
        We may use your information to protect our rights and property, comply
        with legal obligations, and prevent fraud or other illegal activities.
      </p>
      <Heading>3. Sharing Your Information</Heading>
      <SubHeading>Service Providers</SubHeading>
      <p>
        We may share your information with third-party service providers that
        perform services on our behalf, such as hosting, data analysis, and
        customer support. These service providers are contractually obligated to
        protect your information and use it only for the purposes for which we
        disclose it to them.
      </p>
      <SubHeading>Legal Compliance</SubHeading>
      <p>
        We may disclose your information if required to do so by law or in
        response to valid requests by public authorities (e.g., a court or
        government agency).
      </p>
      <SubHeading>Business Transfers</SubHeading>
      <p>
        If we are involved in a merger, acquisition, or asset sale, your
        information may be transferred as part of that transaction. We will
        provide notice before your information is transferred and becomes
        subject to a different privacy policy.
      </p>
      <Heading>4. Your Choices</Heading>
      <SubHeading>Access and Update Your Information</SubHeading>
      <p>
        You can access and update your account information by logging into the
        App and navigating to your account settings. If you need assistance,
        please contact us at support@boxitup.me.
      </p>
      <SubHeading>Opt-Out of Communications</SubHeading>
      <p>
        You can opt-out of receiving promotional communications from us by
        following the unsubscribe instructions included in each communication or
        by contacting us at support@boxitup.com.
      </p>
      <SubHeading>Location Data</SubHeading>
      <p>
        You can opt-out of receiving promotional communications from us by You
        can disable location tracking on your device at any time through your
        device settings.
      </p>
      <Heading>5. Data Security</Heading>
      <p>
        You can opt-out of receiving promotional communications from us by We
        implement appropriate technical and organizational measures to protect
        your information from unauthorized access, use, or disclosure. However,
        no method of transmission over the Internet or electronic storage is
        completely secure, and we cannot guarantee absolute security.
      </p>
      <Heading>6. Children's Privacy</Heading>
      <p>
        You can opt-out of receiving promotional communications from us by The
        App is not intended for use by individuals under the age of 13. We do
        not knowingly collect personal information from children under 13. If we
        become aware that we have collected personal information from a child
        under 13, we will take steps to delete such information from our
        records.
      </p>
      <Heading>7. Changes to This Privacy Policy</Heading>
      <p>
        You can opt-out of receiving promotional communications from us by We
        may update this Privacy Policy from time to time. We will notify you of
        any changes by posting the new Privacy Policy on the App and updating
        the "Effective Date" at the top of this Privacy Policy. Your continued
        use of the App after any such changes constitutes your acceptance of the
        revised Privacy Policy.
      </p>
      <Heading>8. Contact Us</Heading>
      <p>
        You can opt-out of receiving promotional communications from us by If
        you have any questions about this Privacy Policy, please contact us at:
        BoxItUp Inc. Email: support@boxitup.me By using the BoxItUp app, you
        acknowledge that you have read, understood, and agree to be bound by
        this Privacy Policy.
      </p>
    </Page>
  );
}
