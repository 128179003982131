import React from 'react';
import { Box2Icon, HomeIcon, ProfileIcon } from '../icons';

export const tabs = [
  {
    name: 'Home',
    href: '/',
    icon: <HomeIcon className="w-[20px] md:w-6 shrink-0" aria-hidden="true" />,
  },
  {
    name: 'Storage',
    href: '/boxes',
    icon: <Box2Icon className="w-[20px] md:w-5 shrink-0" aria-hidden="true" />,
  },
  {
    name: 'Profile',
    href: '/profile',
    icon: (
      <ProfileIcon className="w-[24px] md:w-6 shrink-0" aria-hidden="true" />
    ),
  },
];
