import React from 'react';
import { useNavigate } from 'react-router-dom';

import BoxIcon from '@heroicons/react/24/outline/ArchiveBoxIcon';
import WarehouseIcon from '@heroicons/react/24/outline/HomeModernIcon';
import CubeIcon from '@heroicons/react/24/outline/CubeIcon';
import FilesIcon from '@heroicons/react/24/outline/InboxStackIcon';
import QrCodeIcon from '@heroicons/react/24/outline/QrCodeIcon';
import HomeIcon from '@heroicons/react/20/solid/HomeIcon';
import ProfileIcon from '@heroicons/react/24/outline/UserCircleIcon';
import GalleryIcon from '@heroicons/react/24/outline/Squares2X2Icon';
import ListIcon from '@heroicons/react/24/outline/Bars3Icon';
import ArrowLeft from '@heroicons/react/24/outline/ArrowLeftIcon';
import ArrowRight from '@heroicons/react/24/outline/ArrowRightIcon';
import ArrowDown from '@heroicons/react/24/outline/ArrowDownIcon';
import ArrowUp from '@heroicons/react/24/outline/ArrowUpIcon';
import CodeIcon from '@heroicons/react/24/outline/CodeBracketIcon';
import PlusIcon from '@heroicons/react/24/outline/PlusIcon';
import CheckCircleIcon from '@heroicons/react/24/outline/CheckCircleIcon';
import CheckIcon from '@heroicons/react/24/outline/CheckIcon';
import InfoCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import TriangleCircleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon';
import CloseIcon from '@heroicons/react/24/outline/XMarkIcon';
import ChevronLeft from '@heroicons/react/24/outline/ChevronLeftIcon';
import ChevronRight from '@heroicons/react/24/outline/ChevronRightIcon';

import EyeIcon from '@heroicons/react/24/solid/EyeIcon';
import EyeSlashIcon from '@heroicons/react/24/solid/EyeSlashIcon';
import PencilIcon from '@heroicons/react/24/solid/PencilIcon';
import PaperClipIcon from '@heroicons/react/24/solid/PaperClipIcon';
import Envelope from '@heroicons/react/24/solid/EnvelopeIcon';

import './icons.css';
import { classNames } from '../utils';

export {
  BoxIcon,
  WarehouseIcon,
  CubeIcon,
  FilesIcon,
  QrCodeIcon,
  HomeIcon,
  ProfileIcon,
  GalleryIcon,
  ListIcon,
  ArrowLeft,
  ArrowRight,
  ArrowDown,
  ArrowUp,
  CodeIcon,
  PlusIcon,
  CheckCircleIcon,
  CheckIcon,
  InfoCircleIcon,
  TriangleCircleIcon,
  CloseIcon,
  EyeIcon,
  EyeSlashIcon,
  PencilIcon,
  PaperClipIcon,
  ChevronLeft,
  ChevronRight,
  Envelope,
};

export function Box2Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M23.576,6.429l-1.91-3.171L12,.036,2.334,3.258,.442,6.397c-.475,.792-.563,1.742-.243,2.607,.31,.839,.964,1.488,1.8,1.793l-.008,9.844,10,3.333,10-3.333,.008-9.844c.846-.296,1.507-.946,1.819-1.788,.317-.857,.229-1.797-.242-2.582Zm-5.737-2.338l-5.831,1.946-5.833-1.951,5.825-1.942,5.839,1.946ZM2.156,7.428l1.292-2.145,7.048,2.357-1.529,2.549c-.239,.398-.735,.581-1.173,.434l-5.081-1.693c-.297-.099-.53-.324-.639-.618-.108-.293-.079-.616,.082-.883Zm1.843,4.038l3.163,1.054c1.343,.448,2.792-.088,3.521-1.302l.316-.526-.005,10.843-7-2.333,.006-7.735Zm8.994,10.068l.005-10.849,.319,.532c.556,.928,1.532,1.459,2.561,1.459,.319,0,.643-.051,.96-.157l3.161-1.053-.006,7.734-7,2.333Zm8.95-13.216c-.105,.285-.331,.503-.619,.599l-5.118,1.706c-.438,.147-.934-.035-1.173-.434l-1.526-2.543,7.051-2.353,1.305,2.167c.156,.26,.186,.573,.08,.858Z"
      />
    </svg>
  );
}

export function S(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="m23.249,13.015c-.569-.645-1.389-1.015-2.249-1.015H2v-7.518c0-.841.38-1.673,1.093-2.12,1.089-.683,2.419-.347,3.107.571l.259.345-.483,2.771c-.058.334.011.678.194.963.425.662,1.323.824,1.952.352l3.606-2.704c.567-.425.71-1.217.327-1.814l-.013-.021c-.237-.37-.64-.602-1.079-.622l-2.906-.129-.174-.232c-.658-.877-1.593-1.542-2.669-1.755C2.44-.462,0,1.656,0,4.333c0,0,.004,5.71.006,7.923,0,.576.037,1.145.108,1.717.101.809.237,1.9.237,1.9.251,2.005,1.223,3.767,2.635,5.037l-.457,1.85c-.133.536.194,1.078.73,1.211.081.02.161.029.241.029.449,0,.857-.305.97-.76l.277-1.121c1.109.564,2.36.881,3.676.881h7.417c1.223,0,2.39-.273,3.44-.765l.249,1.005c.112.455.521.76.97.76.08,0,.16-.01.241-.029.536-.133.863-.675.73-1.211l-.41-1.66c1.53-1.282,2.591-3.12,2.854-5.226l.062-.501c.106-.854-.158-1.712-.728-2.357Zm-1.257,2.109l-.062.501c-.383,3.064-3.001,5.375-6.089,5.375h-7.417c-3.088,0-5.705-2.311-6.088-5.375l-.203-1.625h18.867c.291,0,.558.12.75.338.192.219.278.497.242.786Z"
      />
    </svg>
  );
}

export function WarehouseSm(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(10 14)" stroke="currentColor">
          <g transform="translate(0 100.042)">
            <path
              strokeWidth="6.827"
              strokeLinecap="round"
              d="M22.063 89.43h17.742"
            />
            <rect
              strokeWidth="12"
              x="6"
              y="6"
              width="104.457"
              height="99.958"
              rx="13.653"
            />
            <path
              d="M71.335 6v42.242a7.63 7.63 0 01-2.242 5.412 7.63 7.63 0 01-5.412 2.242h0-7.72a7.63 7.63 0 01-5.413-2.242 7.63 7.63 0 01-2.241-5.412h0V6h23.028z"
              strokeWidth="12"
            />
          </g>
          <g transform="translate(104.543 100.042)">
            <path
              strokeWidth="6.827"
              strokeLinecap="round"
              d="M22.063 89.43h17.742"
            />
            <path
              d="M102.81 6a7.62 7.62 0 015.406 2.24 7.632 7.632 0 012.24 5.413h0v84.652a7.632 7.632 0 01-2.24 5.413 7.62 7.62 0 01-5.406 2.24h0-89.163a7.62 7.62 0 01-5.406-2.24A7.632 7.632 0 016 98.305h0V13.653c0-2.113.856-4.027 2.24-5.412A7.62 7.62 0 0113.648 6h0z"
              strokeWidth="12"
            />
            <path
              d="M71.335 6v42.242a7.632 7.632 0 01-2.241 5.413 7.62 7.62 0 01-5.407 2.24h0-7.733a7.62 7.62 0 01-5.407-2.24 7.632 7.632 0 01-2.24-5.413h0V6h23.028z"
              strokeWidth="12"
            />
          </g>
          <g transform="translate(55.044)">
            <path
              strokeWidth="6.827"
              strokeLinecap="round"
              d="M22.063 89.43h17.742"
            />
            <rect
              strokeWidth="12"
              x="6"
              y="6"
              width="104.457"
              height="99.958"
              rx="13.653"
            />
            <path
              d="M71.335 6v42.242a7.63 7.63 0 01-2.242 5.412 7.63 7.63 0 01-5.412 2.242h0-7.72a7.63 7.63 0 01-5.413-2.242 7.63 7.63 0 01-2.241-5.412h0V6h23.028z"
              strokeWidth="12"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export function WarehouseMd(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <g stroke="currentColor" strokeWidth="13" fill="none" fillRule="evenodd">
        <rect x="6.5" y="29.5" width="31" height="453" rx="8" />
        <rect x="474.5" y="29.5" width="31" height="453" rx="8" />
        <rect x="37.5" y="150.5" width="437" height="31" rx="8" />
        <rect x="37.5" y="428.5" width="437" height="31" rx="8" />
        <g transform="translate(328 321)">
          <rect x="6.5" y="6.5" width="106" height="101" rx="13.945" />
          <path d="M72.523 6.5v42.58a7.422 7.422 0 01-2.18 5.264 7.422 7.422 0 01-5.265 2.18h0-7.902a7.422 7.422 0 01-5.265-2.18 7.422 7.422 0 01-2.18-5.265h0V6.5h22.792z" />
        </g>
        <g transform="translate(197 321)">
          <rect x="6.5" y="6.5" width="106" height="101" rx="13.945" />
          <path d="M72.523 6.5v42.58a7.422 7.422 0 01-2.18 5.264 7.422 7.422 0 01-5.265 2.18h0-7.902a7.422 7.422 0 01-5.265-2.18 7.422 7.422 0 01-2.18-5.265h0V6.5h22.792z" />
        </g>
        <g transform="translate(124 220)">
          <rect x="6.5" y="6.5" width="106" height="101" rx="13.945" />
          <path d="M72.523 6.5v42.58a7.422 7.422 0 01-2.18 5.264 7.422 7.422 0 01-5.265 2.18h0-7.902a7.422 7.422 0 01-5.265-2.18 7.422 7.422 0 01-2.18-5.265h0V6.5h22.792z" />
        </g>
        <g transform="translate(264 220)">
          <rect x="6.5" y="6.5" width="106" height="101" rx="13.945" />
          <path d="M72.523 6.5v42.58a7.422 7.422 0 01-2.18 5.264 7.422 7.422 0 01-5.265 2.18h0-7.902a7.422 7.422 0 01-5.265-2.18 7.422 7.422 0 01-2.18-5.265h0V6.5h22.792z" />
        </g>
        <g transform="translate(65 321)">
          <rect x="6.5" y="6.5" width="106" height="101" rx="13.945" />
          <path d="M72.523 6.5v42.58a7.422 7.422 0 01-2.18 5.264 7.422 7.422 0 01-5.265 2.18h0-7.902a7.422 7.422 0 01-5.265-2.18 7.422 7.422 0 01-2.18-5.265h0V6.5h22.792z" />
        </g>
        <g transform="translate(328 43)">
          <rect x="6.5" y="6.5" width="106" height="101" rx="13.945" />
          <path d="M72.523 6.5v42.58a7.422 7.422 0 01-2.18 5.264 7.422 7.422 0 01-5.265 2.18h0-7.902a7.422 7.422 0 01-5.265-2.18 7.422 7.422 0 01-2.18-5.265h0V6.5h22.792z" />
        </g>
        <g transform="translate(197 43)">
          <rect x="6.5" y="6.5" width="106" height="101" rx="13.945" />
          <path d="M72.523 6.5v42.58a7.422 7.422 0 01-2.18 5.264 7.422 7.422 0 01-5.265 2.18h0-7.902a7.422 7.422 0 01-5.265-2.18 7.422 7.422 0 01-2.18-5.265h0V6.5h22.792z" />
        </g>
        <g transform="translate(65 43)">
          <rect x="6.5" y="6.5" width="106" height="101" rx="13.945" />
          <path d="M72.523 6.5v42.58a7.422 7.422 0 01-2.18 5.264 7.422 7.422 0 01-5.265 2.18h0-7.902a7.422 7.422 0 01-5.265-2.18 7.422 7.422 0 01-2.18-5.265h0V6.5h22.792z" />
        </g>
      </g>
    </svg>
  );
}

export function WarehouseLg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <g stroke="currentColor" strokeWidth="13" fill="none" fillRule="evenodd">
        <path d="M256 7.085l249.5 108.181V497a8.473 8.473 0 01-2.49 6.01 8.473 8.473 0 01-6.01 2.49h0-39a8.473 8.473 0 01-6.01-2.49 8.473 8.473 0 01-2.49-6.01h0V204c0-3.176-1.287-6.05-3.368-8.132A11.464 11.464 0 00438 192.5h0H74c-3.176 0-6.05 1.287-8.132 3.368A11.464 11.464 0 0062.5 204h0v293a8.473 8.473 0 01-2.49 6.01A8.473 8.473 0 0154 505.5h0-39a8.473 8.473 0 01-6.01-2.49A8.473 8.473 0 016.5 497h0V115.266L256 7.085z" />
        <rect x="212.5" y="112.5" width="87" height="37" rx="10" />
        <g transform="translate(315 398)">
          <rect x="6.5" y="6.5" width="106" height="101" rx="13.945" />
          <path d="M72.523 6.5v42.58a7.422 7.422 0 01-2.18 5.264 7.422 7.422 0 01-5.265 2.18h0-7.902a7.422 7.422 0 01-5.265-2.18 7.422 7.422 0 01-2.18-5.265h0V6.5h22.792z" />
        </g>
        <g transform="translate(209 398)">
          <rect x="6.5" y="6.5" width="106" height="101" rx="13.945" />
          <path d="M72.523 6.5v42.58a7.422 7.422 0 01-2.18 5.264 7.422 7.422 0 01-5.265 2.18h0-7.902a7.422 7.422 0 01-5.265-2.18 7.422 7.422 0 01-2.18-5.265h0V6.5h22.792z" />
        </g>
        <g transform="translate(315 297)">
          <rect x="6.5" y="6.5" width="106" height="101" rx="13.945" />
          <path d="M72.523 6.5v42.58a7.422 7.422 0 01-2.18 5.264 7.422 7.422 0 01-5.265 2.18h0-7.902a7.422 7.422 0 01-5.265-2.18 7.422 7.422 0 01-2.18-5.265h0V6.5h22.792z" />
        </g>
        <path d="M63.142 231.084H450.06m-386.918 34H450.06" />
      </g>
    </svg>
  );
}

export function WarehouseFull(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <g stroke="currentColor" fill="none" fillRule="evenodd">
        <g strokeWidth="13">
          <path d="M256 7.085l249.5 108.181V497a8.473 8.473 0 01-2.49 6.01 8.473 8.473 0 01-6.01 2.49h0-39a8.473 8.473 0 01-6.01-2.49 8.473 8.473 0 01-2.49-6.01h0V204c0-3.176-1.287-6.05-3.368-8.132A11.464 11.464 0 00438 192.5h0H74c-3.176 0-6.05 1.287-8.132 3.368A11.464 11.464 0 0062.5 204h0v293a8.473 8.473 0 01-2.49 6.01A8.473 8.473 0 0154 505.5h0-39a8.473 8.473 0 01-6.01-2.49A8.473 8.473 0 016.5 497h0V115.266L256 7.085z" />
          <rect x="212.5" y="112.5" width="87" height="37" rx="10" />
        </g>
        <g transform="translate(183 372)">
          <path
            strokeWidth="10"
            strokeLinecap="round"
            d="M27.66 111.829h22.242"
          />
          <rect
            strokeWidth="13"
            x="6.5"
            y="6.5"
            width="133"
            height="127"
            rx="17.126"
          />
          <path
            d="M90.453 6.5v53.773c0 2.934-1.19 5.59-3.112 7.513a10.593 10.593 0 01-7.514 3.112h0-9.662c-2.934 0-5.59-1.189-7.514-3.112a10.593 10.593 0 01-3.112-7.513h0V6.5h30.914z"
            strokeWidth="13"
          />
        </g>
        <g transform="translate(316 372)">
          <path
            strokeWidth="10"
            strokeLinecap="round"
            d="M27.66 111.829h22.242"
          />
          <rect
            strokeWidth="13"
            x="6.5"
            y="6.5"
            width="127"
            height="127"
            rx="17.126"
          />
          <path
            d="M90.453 6.5v53.773c0 2.934-1.19 5.59-3.112 7.513a10.593 10.593 0 01-7.514 3.112h0-9.662c-2.934 0-5.59-1.189-7.514-3.112a10.593 10.593 0 01-3.112-7.513h0V6.5h30.914z"
            strokeWidth="13"
          />
        </g>
        <g transform="translate(56 372)">
          <path
            strokeWidth="10"
            strokeLinecap="round"
            d="M21.66 111.829h22.242"
          />
          <rect
            strokeWidth="13"
            x="6.5"
            y="6.5"
            width="127"
            height="127"
            rx="17.126"
          />
          <path
            d="M78.453 6.5v53.773c0 2.934-1.19 5.59-3.112 7.513a10.593 10.593 0 01-7.514 3.112h0-9.662c-2.934 0-5.59-1.189-7.514-3.112a10.593 10.593 0 01-3.112-7.513h0V6.5h30.914z"
            strokeWidth="13"
          />
        </g>
        <g transform="translate(117 245)">
          <path
            strokeWidth="10"
            strokeLinecap="round"
            d="M27.66 111.829h22.242"
          />
          <rect
            strokeWidth="13"
            x="6.5"
            y="6.5"
            width="133"
            height="127"
            rx="17.126"
          />
          <path
            d="M90.453 6.5v53.773c0 2.934-1.19 5.59-3.112 7.513a10.593 10.593 0 01-7.514 3.112h0-9.662c-2.934 0-5.59-1.189-7.514-3.112a10.593 10.593 0 01-3.112-7.513h0V6.5h30.914z"
            strokeWidth="13"
          />
        </g>
        <g transform="translate(250 245)">
          <path
            strokeWidth="10"
            strokeLinecap="round"
            d="M27.66 111.829h22.242"
          />
          <rect
            strokeWidth="13"
            x="6.5"
            y="6.5"
            width="133"
            height="127"
            rx="17.126"
          />
          <path
            d="M90.453 6.5v53.773c0 2.934-1.19 5.59-3.112 7.513a10.593 10.593 0 01-7.514 3.112h0-9.662c-2.934 0-5.59-1.189-7.514-3.112a10.593 10.593 0 01-3.112-7.513h0V6.5h30.914z"
            strokeWidth="13"
          />
        </g>
      </g>
    </svg>
  );
}

export function LocationEarth(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,22c-5.514,0-10-4.486-10-10,0-3.474,1.781-6.539,4.477-8.332l1.247,1.091c.175.153.276.375.276.608v.826c0,.446.362.808.808.808h.726c.305,0,.605-.083.867-.24l1.546-.928c.325-.195.742-.144,1.01.124l.801.801c.155.155.242.365.242.585v.831c0,.457-.37.827-.827.827h-2.633c-.352,0-.697.104-.99.3l-1.756,1.17c-.497.331-.795.888-.795,1.485v1.045c0,1.105.895,2,2,2h1.427c.337,0,.63.229.712.556l.693,2.774c.099.394.453.671.859.671h.807c.311,0,.599-.163.759-.43l2.495-4.159c.161-.269.247-.577.247-.891v-1.303c0-.459-.182-.9-.507-1.224l-.993-.993h2.257c.796,0,1.559-.316,2.121-.879l1.235-1.235c.569,1.255.886,2.648.886,4.114,0,5.514-4.486,10-10,10Z"
      />
    </svg>
  );
}

export function LocationMap(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M10,8a2,2,0,1,1,2,2A2,2,0,0,1,10,8ZM7.97,24.06,0,21.608V12A3.5,3.5,0,0,1,4.032,8.544a7.992,7.992,0,1,1,15.807,1.03l1.686.564A3.493,3.493,0,0,1,24,13.483V24.114l-8.483-2.066Zm.494-12.524L12,14.993l3.547-3.469a5,5,0,1,0-7.083.012ZM3,19.392l5.03,1.547,7.453-1.987L21,20.3V13.483a.5.5,0,0,0-.362-.48l-1.931-.645a8.032,8.032,0,0,1-1.05,1.3L12,19.189,6.355,13.668a8,8,0,0,1-1.236-1.61L3.76,11.567a.54.54,0,0,0-.537.019A.489.489,0,0,0,3,12Z"
      />
    </svg>
  );
}

export function LocationHome(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12,.007A10,10,0,0,0,4.941,17.089L12,23.994l7.071-6.917A10,10,0,0,0,12,.007Zm4.961,14.937L12,19.8,7.05,14.956a7,7,0,0,1,9.9-9.9h0a7,7,0,0,1,.011,9.887ZM16,9.409V13H13V11H11v2H8V9.409a1.5,1.5,0,0,1,.589-1.192l2.5-1.909a1.5,1.5,0,0,1,1.822,0l2.5,1.909A1.5,1.5,0,0,1,16,9.409Z"
      />
    </svg>
  );
}

export function LocationIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12,.021A10.077,10.077,0,0,0,1.935,10.087c0,5.274,8.166,12.329,9.1,13.117l.967.817.967-.817c.932-.788,9.1-7.843,9.1-13.117A10.077,10.077,0,0,0,12,.021Zm0,20.043c-3.065-2.792-7.067-7.328-7.067-9.977a7.067,7.067,0,1,1,14.134,0C19.067,12.731,15.064,17.269,12,20.064Z"
      />
      <circle fill="currentColor" cx="12" cy="10.002" r="2.997" />
    </svg>
  );
}

export function Makeup(props) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="m23.229.771a2.637 2.637 0 0 0 -3.591-.12l-14.166 12.482c-2.335.44-5.472 1.741-5.472 4.467a6.407 6.407 0 0 0 6.4 6.4c2.725 0 4.027-3.136 4.467-5.471l12.485-14.171a2.634 2.634 0 0 0 -.123-3.587zm-13.088 15.556-2.468-2.468 2.005-1.767 2.23 2.23zm-3.741 5.673a4.405 4.405 0 0 1 -4.4-4.4c0-1.508 2.639-2.287 4.049-2.537l2.888 2.887c-.251 1.411-1.029 4.05-2.537 4.05zm15.449-18.96-8.616 9.778-2.052-2.051 9.775-8.613a.631.631 0 0 1 .893.886z"
      />
    </svg>
  );
}

export function Medicine(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="m23.204,14.384l-5.327-10.009c-1.479-2.696-4.635-3.966-7.531-3.112-1.078-.794-2.408-1.263-3.846-1.263C2.916,0,0,2.916,0,6.5v11C0,21.084,2.916,24,6.5,24c2.312,0,4.346-1.214,5.499-3.038.846,1.348,2.14,2.327,3.681,2.777.603.176,1.218.263,1.829.263,1.078,0,2.145-.271,3.117-.804,1.522-.835,2.628-2.212,3.115-3.879.486-1.667.294-3.423-.535-4.935Zm-7.087-9.058l2.273,4.27-5.39,2.956v-6.053c0-1.286-.375-2.486-1.022-3.496,1.669-.073,3.3.792,4.14,2.322ZM6.5,2c2.481,0,4.5,2.019,4.5,4.5v4.5H2v-4.5c0-2.481,2.019-4.5,4.5-4.5Zm0,20c-2.481,0-4.5-2.019-4.5-4.5v-4.5h9v4.5c0,2.481-2.019,4.5-4.5,4.5Zm15.319-3.241c-.337,1.154-1.103,2.108-2.157,2.686s-2.271.71-3.423.375c-1.154-.337-2.108-1.103-2.68-2.146l-.634-1.19c.049-.321.074-.649.074-.983v-2.666l6.33-3.471,2.114,3.972c.578,1.054.711,2.27.375,3.424Z"
      />
    </svg>
  );
}

export function Temporary(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="m17.776,21.334c-.564,1.595-2.085,2.666-3.781,2.666H4.005c-1.17,0-2.279-.51-3.044-1.398-.753-.875-1.087-2.025-.917-3.158.405-2.698,1.885-5.198,4.401-7.444C1.929,9.754.449,7.254.044,4.556c-.17-1.132.164-2.283.917-3.157C1.726.51,2.835,0,4.005,0h9.99c1.696,0,3.217,1.071,3.781,2.666.185.521-.088,1.092-.608,1.276-.521.187-1.092-.088-1.276-.608-.283-.798-1.045-1.334-1.896-1.334H4.005c-.587,0-1.145.257-1.528.703-.378.439-.539.991-.454,1.555.375,2.499,1.922,4.84,4.598,6.958.24.189.38.479.38.784s-.14.595-.38.784c-2.676,2.118-4.223,4.458-4.598,6.957-.085.564.076,1.117.454,1.556.384.446.941.703,1.528.703h9.99c.852,0,1.613-.536,1.896-1.333.185-.521.758-.796,1.276-.608.521.184.793.756.608,1.276Zm-8.15-7.39l.508.405c1.172.932,2.727,2.431,3.474,4.275.125.309.088.659-.098.935-.186.275-.496.441-.829.441h-7.362c-.332,0-.643-.166-.829-.441-.186-.275-.223-.625-.099-.933.746-1.853,2.3-3.346,3.472-4.273l.52-.411c.364-.289.88-.288,1.244.002Zm-.627,2.06c-.631.509-1.371,1.194-1.965,1.996h3.928c-.594-.798-1.332-1.484-1.963-1.996Zm15.001-4.003c0,3.309-2.691,6-6,6s-6-2.691-6-6,2.691-6,6-6,6,2.691,6,6Zm-2,0c0-2.206-1.794-4-4-4s-4,1.794-4,4,1.794,4,4,4,4-1.794,4-4Zm-3-.414v-1.586c0-.552-.447-1-1-1s-1,.448-1,1v2c0,.265.105.52.293.707l1,1c.195.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023,0-1.414l-.707-.707Z"
      />
    </svg>
  );
}

export function Misc(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"
      />
    </svg>
  );
}

export function ClotheHanger(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="m22.751,17.411l-9.108-6.47c2.639-2.166,3.844-3.16,3.175-6.087-.417-1.822-1.862-3.268-3.684-3.684-1.514-.345-3.068,0-4.259.952-1.192.95-1.875,2.369-1.875,3.891,0,.552.448,1,1,1s1-.448,1-1c0-.911.409-1.759,1.122-2.328.724-.578,1.635-.779,2.568-.565,1.061.242,1.937,1.118,2.179,2.179.383,1.678.148,1.927-2.505,4.106l-.968.799L1.222,17.431c-.765.583-1.222,1.506-1.222,2.467,0,1.71,1.392,3.102,3.102,3.102h17.796c1.71,0,3.102-1.392,3.102-3.102,0-.961-.457-1.884-1.249-2.487Zm-1.853,3.589H3.102c-.608,0-1.102-.494-1.102-1.102,0-.341.163-.669.407-.856l9.593-6.815,9.565,6.795c.272.207.435.535.435.876,0,.608-.494,1.102-1.102,1.102Z"
      />
    </svg>
  );
}

export function Bulb(props) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="m17.994 2.286a9 9 0 0 0 -14.919 5.536 8.938 8.938 0 0 0 2.793 7.761 6.263 6.263 0 0 1 2.132 4.566v.161a3.694 3.694 0 0 0 3.69 3.69h.62a3.694 3.694 0 0 0 3.69-3.69v-.549a5.323 5.323 0 0 1 1.932-4 8.994 8.994 0 0 0 .062-13.477zm-5.684 19.714h-.62a1.692 1.692 0 0 1 -1.69-1.69s-.007-.26-.008-.31h4.008v.31a1.692 1.692 0 0 1 -1.69 1.69zm4.3-7.741a7.667 7.667 0 0 0 -2.364 3.741h-1.246v-7.184a3 3 0 0 0 2-2.816 1 1 0 0 0 -2 0 1 1 0 0 1 -2 0 1 1 0 0 0 -2 0 3 3 0 0 0 2 2.816v7.184h-1.322a8.634 8.634 0 0 0 -2.448-3.881 7 7 0 0 1 3.951-12.073 7.452 7.452 0 0 1 .828-.046 6.921 6.921 0 0 1 4.652 1.778 6.993 6.993 0 0 1 -.048 10.481z"
      />
    </svg>
  );
}

export function Bath(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="m23.249,13.015c-.569-.645-1.389-1.015-2.249-1.015H2v-7.518c0-.841.38-1.673,1.093-2.12,1.089-.683,2.419-.347,3.107.571l.259.345-.483,2.771c-.058.334.011.678.194.963.425.662,1.323.824,1.952.352l3.606-2.704c.567-.425.71-1.217.327-1.814l-.013-.021c-.237-.37-.64-.602-1.079-.622l-2.906-.129-.174-.232c-.658-.877-1.593-1.542-2.669-1.755C2.44-.462,0,1.656,0,4.333c0,0,.004,5.71.006,7.923,0,.576.037,1.145.108,1.717.101.809.237,1.9.237,1.9.251,2.005,1.223,3.767,2.635,5.037l-.457,1.85c-.133.536.194,1.078.73,1.211.081.02.161.029.241.029.449,0,.857-.305.97-.76l.277-1.121c1.109.564,2.36.881,3.676.881h7.417c1.223,0,2.39-.273,3.44-.765l.249,1.005c.112.455.521.76.97.76.08,0,.16-.01.241-.029.536-.133.863-.675.73-1.211l-.41-1.66c1.53-1.282,2.591-3.12,2.854-5.226l.062-.501c.106-.854-.158-1.712-.728-2.357Zm-1.257,2.109l-.062.501c-.383,3.064-3.001,5.375-6.089,5.375h-7.417c-3.088,0-5.705-2.311-6.088-5.375l-.203-1.625h18.867c.291,0,.558.12.75.338.192.219.278.497.242.786Z"
      />
    </svg>
  );
}

export function TopBackButton({ children, onClick, ...props }) {
  const navigate = useNavigate();
  if (!onClick) {
    onClick = () => navigate(-1);
  }

  return (
    <button
      className="text-gray-700 dark:text-gray-400 inline-flex items-center gap-1"
      onClick={onClick}
      {...props}
    >
      <ArrowLeft className="w-4 h-4" /> Back
    </button>
  );
}

export const nameToElementMap = {
  bath: Bath,
  bulb: Bulb,
  container: CubeIcon,
  clotheHanger: ClotheHanger,
  temporary: Temporary,
  medicine: Medicine,
  makeup: Makeup,
  test: CodeIcon,
  location: LocationIcon,
  lHome: LocationHome,
  lMap: LocationMap,
  lEarth: LocationEarth,
  warehouseSm: WarehouseSm,
  warehouseMd: WarehouseMd,
  warehouseLg: WarehouseLg,
  warehouseFull: WarehouseFull,
  //   film: 'film',
  //   guitars: 'guitars',
  //   medicine: 'medicine',
  //   paw: 'paw',
  //   plug: 'plug-alt',
  //   screwAlt: 'screw-alt',
  //   shoePrints: 'shoe-prints',
  //   shovel: 'shovel',
  misc: Misc,
  //   tools: 'tools',
};

export function QRLoader({ className }) {
  return (
    <div className={classNames('animated-lds-grid', className)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
