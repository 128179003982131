import { Bugsnag } from '../components/ErrorCatching';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function dataURLToBlob(dataURL) {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeString });
}

export function genQRCodeStr(user, containerId) {
  return `boxitup/${user}/container/${containerId}`;
}

export function matchUrlPattern(url, pattern) {
  // Convert the pattern to a regular expression
  const regexPattern = pattern.replace(/:[^\s/]+/g, '([^/]+)');
  const regex = new RegExp(`^${regexPattern}$`);

  // Extract parameter names from the pattern
  const paramNames = (pattern.match(/:[^\s/]+/g) || []).map((name) =>
    name.substring(1)
  );

  // Match the URL against the regular expression
  const matches = url.match(regex);

  if (!matches) {
    return null;
  }

  // Create an object with the parameter names and values
  const params = {};
  paramNames.forEach((name, index) => {
    params[name] = matches[index + 1];
  });

  return params;
}

export function shallowCopy(obj) {
  const copy = { ...(obj || {}) };
  return copy;
}

/**
 *
 * @param data - the data to search
 * @param query - the search query
 * @param extraFilter - a function or an array of functions to filter the data
 * @returns {*|*[]} - the filtered data
 */
export function searchItems(data, query, extraFilter = []) {
  const lowerCaseQuery = query.toLowerCase();
  const refilter = (initData) => {
    const filter = Array.isArray(extraFilter) ? extraFilter : [extraFilter];
    if (filter.length === 0) return initData;
    return filter.reduce((_data, fn) => fn(_data), initData);
  };

  if (lowerCaseQuery.length === 0) return refilter(data);

  if (lowerCaseQuery.length === 1) {
    const result = data.filter(({ idNumber, _containerIdNumber }) =>
      [idNumber, _containerIdNumber].some(
        (value) =>
          typeof value !== 'undefined' &&
          String(value).toLowerCase().includes(lowerCaseQuery)
      )
    );
    if (result.length > 0) return result;
    return refilter(data);
  }

  // Filter the data based on the search query
  return refilter(
    data.filter(
      ({ name, notes, tags, idNumber, _containerName, _containerIdNumber }) =>
        [name, notes, tags, idNumber, _containerName, _containerIdNumber].some(
          (value) =>
            typeof value !== 'undefined' &&
            String(value).toLowerCase().includes(lowerCaseQuery)
        )
    )
  );
}

export const sortObjNameCaseIns = (a, b) =>
  a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());

export const sortByLatestModified = (a, b) => {
  const aTX = a.updatedAt || a.createdAt;
  const bTX = b.updatedAt || b.createdAt;
  if (!aTX) return 1;
  if (!bTX) return -1;
  return bTX - aTX;
};

export const sortByCreatedAt = (a, b) => {
  if (!a.createdAt) return 1;
  if (!b.createdAt) return -1;
  return b.createdAt - a.createdAt;
};

export const reduceById = (acc, c) => {
  acc[c.id] = c;
  return acc;
};

export const sameIds = (objA, objB) => {
  if (!objB || !objA) return false;
  const notSameLength = Object.keys(objA).length !== Object.keys(objB).length;
  if (notSameLength) return false;
  return Object.keys(objA).every((id) => !!objB[id]);
};

export function validateSubscriptions(subscriptions) {
  return Object.values(subscriptions).some(({ period_start, period_end }) => {
    const now = Date.now() / 1000;
    return period_start <= now && now <= period_end;
  });
}

export const getLongestExpiryDate = (subscriptions) => {
  if (!subscriptions) return [0, {}];
  return Object.keys(subscriptions)
    .map((k) => ({ ...subscriptions[k], id: k }))
    .reduce(
      ([max, maxSub], sub) => {
        if (Math.max(max, sub.period_end) === max) return [max, maxSub];
        else return [sub.period_end, sub];
      },
      [0, {}]
    );
};

export const isSubcriptionRecurring = (subKey = '') => {
  if (typeof subKey !== 'string') return false;
  return ['sub_', 'standard_yearly'].some((k) => subKey.includes(k));
};

export function defer(t) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, t);
  });
}

export const log = (...args) => {
  window.location.hostname === 'localhost' && console.log(...args);
};
export const errorLog = (...args) => {
  console.error(...args);
  Bugsnag.notify(args[1]);
};
export const snag = (e) => {
  Bugsnag.notify(e);
};
