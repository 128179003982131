import {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
} from 'react';
import { uid } from 'uid';
import { retrieve } from './useLocalStorage';
import {
  initialAppState,
  endpoints,
  dataURLToBlob,
  validateSubscriptions,
  snag,
  log,
  errorLog,
} from '../utils';

const LOAD_FROM_BACKUP = false;
const isPowerUser = (user, email) => {
  if (!user && email) return email === 'lauriane.kayungu@gmail.com';
  return (atob(user) || '').split(':')[0] === 'lauriane.kayungu@gmail.com';
};

// async function debugSavedValues(data) {
//   console.log('overriding to cloud...');
//   const user = retrieve('user');
//   let res = await fetch(endpoints.db, {
//     method: 'PUT',
//     headers: {
//       'Content-Type': 'application/ld+json',
//       authorization: `Basic ${user}`,
//     },
//     body: JSON.stringify({ ...data }),
//   });
//   if (!res.ok) console.error('Failed to debug cloud values...');
//   console.log('Saved to cloud!');
// }
// window.__debugSavedValues = debugSavedValues;
// window.__fetchDataFromCloud = fetchDataFromCloud;

async function getUserStatus() {
  const user = retrieve('user');
  const res = await fetch(`${endpoints.user}/profile`, {
    headers: {
      'Content-Type': 'application/ld+json',
      authorization: `Basic ${user}`,
    },
  });

  if (!res.ok) {
    log('Failed to get profile');
    snag('Failed to get profile');
  }
  const profile = await res.json();
  log('Checked email status', profile?.emailConfirmed);
  return profile;
}

export async function saveDataToCloud(data) {
  log('Saving to cloud...');

  const user = retrieve('user');
  let res = await fetch(endpoints.db, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/ld+json',
      authorization: `Basic ${user}`,
    },
    body: JSON.stringify({ ...data }),
  });

  if (isPowerUser(user)) {
    const powerUser = retrieve('testUser');
    if (!powerUser) return;
    res = await fetch(endpoints.dbBackup, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...powerUser, data }),
    });

    if (!res.ok) {
      // console.error('Failed to save power user data to cloud');
    }
  }

  if (!res.ok) {
    log('Failed to save data to cloud');
    throw new Error('Failed to save data to cloud');
  }
  log('Saved to cloud');
}

export async function fetchDataFromCloud(loadFromBackup = LOAD_FROM_BACKUP) {
  log('Getting cloud data...');

  let data;
  const user = retrieve('user');
  const powerUser = retrieve('testUser');
  if (!loadFromBackup) {
    const res = await fetch(endpoints.db, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/ld+json',
        authorization: `Basic ${user}`,
      },
    });

    if (!res.ok) {
      log('Failed retrieve data from cloud');
      throw new Error('Failed retrieve data from cloud');
    }
    data = await res.json();
    data = { data };
  }

  if (isPowerUser(user) && loadFromBackup && powerUser) {
    const res = await fetch(endpoints.dbBackup, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...powerUser }),
    });

    if (!res.ok) {
      log('Failed retrieve data from backup');
      throw new Error('Failed retrieve data from backup');
    }
    data = await res.json();
  }

  log('Got data from cloud', data);
  return data;
}

export async function uploadImageToCloud(image, fileName = 'a_picture.png') {
  log('Uploading image to cloud...');

  const user = retrieve('user');
  const formData = new FormData();
  const blob = dataURLToBlob(image);
  // const blob = new Blob([image], { type: image.type });
  formData.append('file', blob, fileName);

  const res = await fetch(endpoints.upload, {
    method: 'POST',
    headers: {
      authorization: `Basic ${user}`,
    },
    body: formData,
  });

  if (!res.ok) {
    log('Failed to upload image');
    throw new Error('Failed to upload image');
  }

  const data = await res.json();
  log('Uploaded image to cloud', data);
  return data.url;
}

export async function deleteUploadedImageFromCloud(imageUrl) {
  log('Deleting image from cloud...');

  const user = retrieve('user');
  const res = await fetch(`${endpoints.upload}/remove`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/ld+json',
      authorization: `Basic ${user}`,
    },
    body: JSON.stringify({ url: imageUrl }),
  });

  if (!res.ok && !isPowerUser(user)) {
    log('Failed to delete image from cloud');
    throw new Error('Failed to delete image from cloud');
  } else if (!res.ok && isPowerUser(user)) {
    log('Failed to delete image from cloud');
    return;
  }

  const data = await res.json();
  log('Deleted image from cloud storage', data);
}

function getQRUserId() {
  const user = retrieve('user');
  if (isPowerUser(user)) return 'power_user';
  const userQrId = atob(user || '').split('@')[0];
  return encodeURIComponent(userQrId);
}

const DataServiceContext = createContext();

export const DataServiceContextProvider = ({ children }) => {
  const dataService = _useDataService();
  return (
    <DataServiceContext.Provider value={dataService}>
      {children}
    </DataServiceContext.Provider>
  );
};

export const useDataService = () => useContext(DataServiceContext);
function _useDataService() {
  const [appState, setAppState] = useState({});
  const [appUIState, setAppUIState] = useState({
    featureFlagAI: false,
    isSidebarOpen: true,
  });
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  const addMessage = useCallback(
    (message, type = undefined, desc = undefined) => {
      setMessages((messages) => {
        let msg = { id: uid() };
        if (typeof message === 'string') {
          msg.message = message;
          msg.type = type;
          msg.desc = desc;
        } else {
          msg = { ...message, ...msg };
        }
        return [...messages, msg];
      });
    },
    []
  );

  const closeMessage = (id) => {
    setMessages((_messages) => [..._messages].filter((msg) => msg.id !== id));
  };

  const clearMessages = () => {
    setMessages([]);
  };

  useEffect(() => {
    const _addMessage = (message) => {
      setMessages((messages) => [...messages, message]);
    };
    async function init() {
      try {
        const [{ data }, userStatus] = await Promise.all([
          fetchDataFromCloud(),
          getUserStatus(),
        ]);
        const { email, emailConfirmed, subscriptions } = userStatus;
        data.profile = {
          ...(Array.isArray(data.profile) ? {} : data.profile),
          email,
          emailConfirmed,
          subscriptions,
        };
        if (
          typeof data.items !== 'object' ||
          typeof data.containers !== 'object' ||
          Array.isArray(data.items) ||
          Array.isArray(data.containers)
        ) {
          const _init = { ...initialAppState, profile: data.profile };
          setAppState(_init);
          await saveDataToCloud(_init);
        } else {
          const isSub = validateSubscriptions(data.profile.subscriptions);
          if (isSub) {
            data._tier = {
              type: 'paid',
              maxBoxes: -1,
              maxItems: -1,
            };
          } else
            data._tier = {
              type: 'free',
              maxBoxes: 2,
              maxItems: 100,
            };
          setAppState(data);
        }
        setLoading(false);
      } catch (error) {
        errorLog('Failed to fetch data from cloud:', error);
        _addMessage({
          id: uid(),
          type: 'error',
          message: 'Failed to fetch data from cloud',
        });
        setLoading(false);
      }
    }
    init();
  }, []);

  const totalRegularBoxes = useCallback(() => {
    const { containers } = appState;
    return Object.values(containers).filter(({ type }) => !type).length;
  }, [appState]);

  const totalItems = useCallback(() => {
    const { items } = appState;
    return Object.values(items).length;
  }, [appState]);

  const uploadImage = useCallback(
    async (image, fileName) => {
      try {
        return await uploadImageToCloud(image, fileName);
      } catch (error) {
        errorLog('Failed to upload image:', error);
        addMessage({
          id: uid(),
          type: 'error',
          message: 'Failed to upload image',
        });
      }
    },
    [addMessage]
  );

  const deleteUploadedImage = useCallback(
    async (imageUrl) => {
      try {
        return await deleteUploadedImageFromCloud(imageUrl);
      } catch (error) {
        errorLog('Failed to upload image:', error);
        addMessage({
          id: uid(),
          type: 'error',
          message: 'Failed to upload image',
        });
      }
    },
    [addMessage]
  );

  const _update_ = useCallback(
    (collectionKey) => (c) => {
      setAppState((state) => {
        const collection = { ...state[collectionKey] };
        if (Array.isArray(c)) {
          c.forEach((item) => (collection[item.id] = item));
        } else {
          collection[c.id] = c;
        }
        const newState = { ...state, [collectionKey]: collection };
        saveDataToCloud(newState).catch((e) => {
          errorLog('Failed to save data to server:', e);
          addMessage('Failed to save data to server');
        });
        return newState;
      });
    },
    [addMessage]
  );
  const _delete_ = useCallback(
    (collectionKey) => (c) => {
      setAppState((state) => {
        const collection = { ...state[collectionKey] };
        // collection[c.id] = { ...collection[c.id], hidden: true };
        delete collection[c.id];
        const newState = { ...state, [collectionKey]: collection };
        saveDataToCloud(newState).catch((e) => {
          errorLog('Failed to save data to server:', e);
          addMessage('Failed to save data to server');
        });
        return newState;
      });
    },
    [addMessage]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const addItem = useCallback(_update_('items'), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deleteItem = useCallback(_delete_('items'), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const addContainer = useCallback(_update_('containers'), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deleteContainer = useCallback(_delete_('containers'), []);

  const editProfile = useCallback(
    (obj) => {
      setAppState((state) => {
        let profile = { ...state.profile, ...obj };
        const newState = { ...state, profile };
        saveDataToCloud(newState).catch((e) => {
          errorLog('Failed to save data to server:', e);
          addMessage('Failed to save data to server');
        });
        return newState;
      });
    },
    [addMessage]
  );

  const openModal = useCallback(() => {
    setAppUIState((state) => ({
      ...state,
      isModalOpen: true,
      tempModalId: uid(),
    }));
  }, []);
  const closeModal = useCallback(() => {
    setAppUIState((state) => {
      state.closeModalListeners?.forEach((listener) =>
        setTimeout(() => listener(), 0)
      );
      return { ...state, isModalOpen: false, tempModalId: undefined };
    });
  }, []);
  const addCloseModalListener = useCallback((listener) => {
    setAppUIState((state) => ({
      ...state,
      closeModalListeners: [...(state.closeModalListeners || []), listener],
    }));
  }, []);
  const removeCloseModalListener = useCallback((listener) => {
    setAppUIState((state) => ({
      ...state,
      closeModalListeners: state.closeModalListeners.filter(
        (l) => l !== listener
      ),
    }));
  }, []);
  const setModalContent = useCallback((element) => {
    setAppUIState((state) => ({
      ...state,
      modalContent: element,
    }));
  }, []);
  const setModals = useCallback((modals = []) => {
    setAppUIState((state) => ({
      ...state,
      modals,
    }));
  }, []);
  const unsetModalContent = useCallback(
    () => setModalContent(null),
    [setModalContent]
  );
  const setUIKV = useCallback((key, value) => {
    setAppUIState((state) => ({
      ...state,
      [key]: value,
    }));
  }, []);
  const setDarkMode = useCallback((value) => {
    setAppUIState((state) => ({
      ...state,
      isDarkMode: value,
    }));
  }, []);

  const setSidebar = useCallback((value) => {
    setAppUIState((state) => ({
      ...state,
      isSidebarOpen: value,
    }));
  }, []);

  return {
    state: appState,
    loading,
    isPageLoading: loading,
    messages,
    addMessage,
    closeMessage,
    clearMessages,
    editProfile,
    addItem, //same code for editItem
    deleteItem,
    addContainer, //same code for editContainer
    deleteContainer,
    uploadImage,
    deleteUploadedImage,
    ui: appUIState,
    openModal,
    closeModal,
    setModalContent,
    setModals,
    unsetModalContent,
    addCloseModalListener,
    removeCloseModalListener,
    setDarkMode,
    getQRUserId,
    totalRegularBoxes,
    totalItems,
    setSidebar,
    setUIKV,
    useAdvancedFeatures: appState?.profile?.advanced || false,
  };
}

export default useDataService;
