import React from 'react';
import { classNames } from '../utils';
import { ArrowLeft, EyeIcon, EyeSlashIcon } from './icons';

export const Corner = ({ className, ...props }) => (
  <svg
    viewBox="0 0 1206 672"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <g>
      <path d="M0,20.5621191 L0,0.53281382 L0,0.53281382 L1206,0.53281382 L1206,596.650095 C1206,638.071452 1172.42136,671.650095 1131,671.650095 C1118.44945,671.650095 1106.09955,668.500524 1095.08186,662.489957 L39.0818628,86.401981 C14.9896612,73.258773 7.44152053e-14,48.0062095 0,20.5621191 Z" />
    </g>
  </svg>
);

export const CornerContainer = ({
  children,
  className,
  top = false,
  ...props
}) => (
  <div
    className={classNames(
      'corner pointer-events-none fill-white absolute left-0 w-full',
      top ? 'top-[-10px]' : 'bottom-[-10px]',
      className
    )}
    {...props}
  >
    {children}
  </div>
);

export const Panel = ({ children, className }) => (
  <div
    className={classNames(
      'bg-white px-6 rounded-lg shadow-md w-full max-w-md',
      className
    )}
  >
    {children}
  </div>
);

export const BackButton = ({ onClick, className }) => (
  <button
    className={classNames(
      'text-gray-500 mb-4 flex items-center gap-1',
      className
    )}
    onClick={onClick}
  >
    <ArrowLeft className={'w-4 h-4'} /> Back
  </button>
);

export const Terms = ({ acceptedTerms, setAcceptedTerms }) => (
  <div className="flex items-center mb-4">
    <input
      type="checkbox"
      id="terms"
      checked={acceptedTerms}
      onChange={(e) => setAcceptedTerms(e.target.checked)}
      className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
      required
    />
    <label htmlFor="terms" className="ml-2 block text-sm text-gray-900">
      I agree to the{' '}
      <a href="/" className="text-orange-500 hover:underline">
        Terms & Conditions
      </a>
    </label>
  </div>
);

const ArrowRight = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 5l7 7-7 7"
    />
  </svg>
);

export const LoginBtn = ({
  children,
  className,
  looksDisabled,
  isInvisible,
  ...props
}) => {
  return (
    <button
      className={classNames(
        'items-center justify-between border-0 shadow-sm text-sm font-medium text-white bg-gradient-to-r from-red-400 to-yellow-400',
        'rounded-full py-3 px-5',
        'relative box-border',
        isInvisible
          ? 'opacity-0'
          : looksDisabled
            ? 'opacity-40 cursor-not-allowed'
            : 'hover:from-red-500 hover:to-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
        className
      )}
      {...props}
    >
      {children}
      <span className="ml-2">
        <ArrowRight className="h-5 w-5" />
      </span>
    </button>
  );
};

export const ForgotPasswordBtn = ({
  children,
  className,
  disabled,
  ...props
}) => {
  return (
    <button
      className={classNames(
        'items-center justify-between border-0 shadow-sm text-sm font-medium text-white bg-gradient-to-r from-red-400 to-yellow-400',
        'rounded-full py-3 px-5',
        'absolute box-border z-10 top-8 w-full flex',
        disabled
          ? 'opacity-40 cursor-not-allowed'
          : 'hover:from-red-500 hover:to-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
        className
      )}
      disabled={disabled}
      {...props}
    >
      {children}
      <span className="ml-2">
        <ArrowRight className="h-5 w-5" />
      </span>
    </button>
  );
};

export const RoundBtn = ({ onClick, children, className = 'w-full' }) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'flex items-center justify-center border-0 border-transparent shadow-sm text-white bg-gradient-to-r from-red-400 to-yellow-400 hover:from-red-500 hover:to-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
        'rounded-full absolute p-2 transition-opacity duration-500',
        'box-border pointer-events-auto',
        className
      )}
    >
      {children}
    </button>
  );
};

export const Input = ({ type = 'text', className, ...props }) => (
  <input
    type={type}
    {...props}
    className={classNames(
      'mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm',
      className
    )}
  />
);

export const PasswordBtn = ({ onClick, viewPassword }) => (
  <div
    tabIndex={-1}
    className={classNames(
      'absolute right-0 top-[24px] h-[42px] w-10 p-2 flex justify-center items-center',
      viewPassword ? 'text-orange-500' : 'text-gray-400'
    )}
    onClick={onClick}
  >
    <span className="sr-only">View password toggle button</span>
    {viewPassword ? (
      <EyeIcon className="w-6 h-6" />
    ) : (
      <EyeSlashIcon className="w-6 h-6" />
    )}
  </div>
);

export const Label = ({ children, ...props }) => (
  <label className="block text-sm font-medium text-gray-700" {...props}>
    {children}
  </label>
);

export const FieldLine = ({ children, htmlFor, label, ...props }) => (
  <div className="mb-4 relative" {...props}>
    <Label htmlFor={htmlFor}>{label}</Label>
    {children}
  </div>
);

export const signupData = (form) => [
  {
    type: 'email',
    id: 'email-signup',
    name: 'email-login',
    label: 'Email',
    placeholder: 'Enter your email',
    hasBtn: false,
  },
  {
    type: 'email',
    id: 'email-verify',
    name: 'email-verify',
    label: 'Re-enter Email',
    placeholder: 'Re-enter your email',
    hasBtn: false,
  },
  {
    type: form[`viewPassword-2`] ? 'text' : 'password',
    id: 'password-signup',
    name: 'password-login',
    label: 'Password',
    placeholder: 'Enter your password',
    hasBtn: true,
  },
  {
    type: form[`viewPassword-3`] ? 'text' : 'password',
    id: 'password-verify',
    name: 'password-verify',
    label: 'Re-enter your password',
    placeholder: 'Re-enter your password',
    hasBtn: true,
  },
];

export const is8Char = (value = '') =>
  typeof value === 'number' ? value >= 8 : value.length >= 8;

export const FieldError = ({ form, index, type = 'signup' }) => {
  const error = form[`error-${type}-${index}`];
  if (!error) return null;
  return (
    <div className="mt-1">
      <p className="text-sm text-red-500">{error}</p>
    </div>
  );
};
