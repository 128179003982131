import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  InputLine,
  ModalButton,
  ModalButton2,
  BottomBtnsContainer,
} from './Modal';
import HomeModal from './HomeModal';

import useDataService from '../../hooks/useDataService';
import { useForm } from '../../hooks/useForms';
import { sortObjNameCaseIns, classNames, reduceById } from '../../utils';
import GalleryList from '../GalleryList';

export default function AddItem() {
  const { boxId } = useParams();
  const {
    state,
    closeModal,
    setModalContent,
    addItem: editItem,
    ui: { isModalOpen },
    addCloseModalListener,
    removeCloseModalListener,
  } = useDataService();
  const [edited, setEdited] = useState(false);

  const [box, itemList, items] = useMemo(() => {
    const _box = state.containers?.[boxId];
    if (!_box) return [];

    const _items = Object.values(state.items || {}).filter(
      (item) => !item.hidden && (!item.container || item.container === _box.id)
    );
    _items.sort(sortObjNameCaseIns);
    const _items2 = _items.reduce(reduceById, {});

    return [_box, _items, _items2];
  }, [state.containers, state.items, boxId]);

  const [
    selectedItems,
    setSelectedItems,
    ,
    ,
    setInitialItems,
    isProcessing,
    setIsProcessing,
  ] = useForm(undefined);

  useEffect(() => {
    const listener = () => {
      setTimeout(() => {
        setInitialItems(undefined);
        setEdited(false);
      }, 0);
      removeCloseModalListener(listener);
    };
    addCloseModalListener(listener);
    return () => removeCloseModalListener(listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (id) => {
    const item = selectedItems[id];
    if (!item) return;
    setEdited(true);
    setSelectedItems({
      ...selectedItems,
      [item.id]: {
        ...item,
        container: selectedItems[id].container ? undefined : boxId,
      },
    });
  };

  useEffect(() => {
    if (!selectedItems && items && isModalOpen) {
      setInitialItems(items);
    }
  }, [items, selectedItems, isModalOpen, setInitialItems]);

  if (!box) return <div></div>;

  const handleAddNewItem = () => {
    setTimeout(
      () =>
        setModalContent(
          <HomeModal
            type={box.type}
            onCancel={() => setModalContent(<AddItem />)}
          />
        ),
      10
    );
  };

  const handleSave = () => {
    const updatedAt = +Date.now();
    const update = Object.values(selectedItems)
      .map((_item) => ({
        ..._item,
        updatedAt,
      }))
      .filter((_item) => _item.container !== state.items[_item.id].container);
    setIsProcessing(true);
    editItem(update);
    closeModal();
    setIsProcessing(false);
  };

  return (
    <>
      <h2 className="text-2xl font-bold mb-5 dark:text-gray-200">
        Choose which items to add
      </h2>
      <div className="mb-4">
        <ModalButton green onClick={handleAddNewItem}>
          + Add New Item
        </ModalButton>
      </div>

      <InputLine label="Not in a box">
        <div className="-mx-5">
          <GalleryList cols3>
            {itemList.map((i) => (
              <GalleryList.Item
                key={i.id}
                {...i}
                showContainer={false}
                isLink={false}
                tabIndex={-1}
                onClick={() => handleClick(i.id)}
                className={classNames(
                  isActive(boxId, i.id, selectedItems)
                    ? 'bg-lime-200 border-lime-500 border'
                    : 'bg-white dark:bg-gray-800 border border-transparent'
                )}
              />
            ))}
          </GalleryList>
        </div>
      </InputLine>

      <BottomBtnsContainer>
        <ModalButton onClick={handleSave} disabled={isProcessing || !edited}>
          Save
        </ModalButton>
        <ModalButton2 onClick={closeModal} disabled={isProcessing}>
          Cancel
        </ModalButton2>
      </BottomBtnsContainer>
    </>
  );
}

const isActive = (container, id, items) => items?.[id]?.container === container;
