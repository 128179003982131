import React from 'react';
import useQRCodeGenerator from 'react-hook-qrcode-svg';

const QRCODE_LEVEL = 'L';
const QRCODE_BORDER = 2;

const QRCodeComponent = ({ value, rectClassName, ...props }) => {
  const { path, viewBox } = useQRCodeGenerator(
    value,
    QRCODE_LEVEL,
    QRCODE_BORDER
  );

  return (
    <svg {...props} viewBox={viewBox} stroke="none">
      <rect width="100%" height="100%" className={rectClassName} />
      <path d={path} fill="currentColor" />
    </svg>
  );
};

export default QRCodeComponent;
