import React from 'react';
import { QrCodeIcon } from './icons';
import { classNames, PAGE_SCROLL } from '../utils';

function SearchBar({
  scroll = 0,
  placeholder = 'Search for your stuff',
  value,
  onChangeValue,
  onClick = () => {},
}) {
  return (
    <div
      className={classNames(
        'mx-5 py-5 flex items-stretch sticky top-3 z-[3]',
        scroll >= PAGE_SCROLL.search ? 'pl-7' : ''
      )}
    >
      <label htmlFor="search" className="sr-only">
        {placeholder}
      </label>
      <input
        type="search"
        name="search"
        id="home-search"
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
        className={classNames(
          'w-full min-w-0 sm:w-56 text-base appearance-none px-3 py-1.5',
          'rounded-lg border shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset outline-0',
          'text-black dark:text-gray-200 placeholder:text-gray-400 border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 ring-white/10 focus:ring-orange-200 dark:focus:ring-orange-600'
        )}
        placeholder={placeholder}
      />
      <button
        className={classNames(
          'ml-3 flex-shrink-0 flex items-center justify-center py-1 px-1.5 font-semibold',
          'rounded-lg border shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-offset-2',
          'text-orange-600 border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 hover:bg-orange-100 dark:hover:bg-orange-900 focus-visible:outline-orange-200 focus-visible:ring-orange-200 focus:border-orange-400'
        )}
      >
        <QrCodeIcon className="w-[24px]" onClick={onClick} />
      </button>
    </div>
  );
}

export default SearchBar;
