import React, { useEffect, useState } from 'react';

import { Input, InputLine, ModalButton, ModalButton2 } from '../modals/Modal';
import QRCodeComponent from '../QRCodeComponent';
import {
  BOX_TYPES,
  classNames,
  genQRCodeStr,
  log,
  defer,
  SELECT_PROPS,
} from '../../utils';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import useDataService from '../../hooks/useDataService';
import { useForm } from '../../hooks/useForms';
import { useIconContainer } from '../modals/StorageModal';
import { nameToElementMap } from '../icons';
import Select from 'react-select';
import QRDecode from '../QRDecode';

export default function PrimaryTab({
  type,
  box,
  items,
  boxes,
  isLocation,
  superContainers,
  locations,
}) {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const {
    state,
    addContainer: editContainer,
    addItem: editItems,
    deleteContainer,
    getQRUserId,
    addMessage,
    setModalContent,
    openModal,
    addCloseModalListener,
    removeCloseModalListener,
    useAdvancedFeatures: advanced,
  } = useDataService();
  const [editView, setEditView] = useState(false);
  const [working, setWorking] = useState(false);
  const [_box, setUpdatedBox, handleChange, , setInitialBox] = useForm(
    box,
    'image'
  );
  const profileUseQrCode = state?.profile?.useQrCode || false;

  useEffect(() => {
    if (!_box && box) {
      setInitialBox(box);
      return;
    }
    if (_box?.id !== box?.id) {
      setInitialBox(box);
      if (box) document.querySelector('.box-page').scrollTo(0, 0);
    }
  }, [_box, box, setInitialBox]);

  const handleEmptyAndDelete = () => {
    const onConfirm = () => {
      // Empty ==> remove the pointer to this box from items
      const updatedItemList = items.map((_item) => ({
        ..._item,
        container: undefined,
      }));
      if (updatedItemList.length > 0) editItems(updatedItemList);
      // and remove pointers from location and super-container

      //delete the box
      deleteContainer(box);
      navigate(-1);
    };
    addMessage({
      message:
        items.length === 0
          ? `Are you sure to delete this ${type}? You cannot undo this.`
          : `Are you sure to empty and delete this ${type}? You cannot undo this.`,
      onConfirm,
      confirmText: items.length === 0 ? 'Delete' : 'Empty & Delete',
    });
  };

  const generateOrShowQRCode = () => {
    if (_box.qrCodeStr) {
      setUpdatedBox({ ..._box, useQrCode: true });
      setEditView(true);
      return;
    }
    const qrStr = genQRCodeStr(getQRUserId(), _box.id);
    setUpdatedBox({ ..._box, qrCodeStr: qrStr });
    setEditView(true);
  };

  const handleChangeIcon = (icon) => () => {
    setUpdatedBox({
      ..._box,
      icon: _box.icon === icon ? '' : icon,
    });
    setEditView(true);
  };

  const handleChangeIdNumber = (e) => {
    const { value } = e.target;
    if (value.length > 4) return;
    setUpdatedBox({
      ..._box,
      idNumber: value,
    });
  };

  const icons = useIconContainer({
    onClick: handleChangeIcon,
    active: _box?.icon,
    type: _box?.type,
  });

  const handleChangeField = (field) => (object) => {
    setUpdatedBox({
      ..._box,
      [field]: object ? object.id : undefined,
    });
  };

  const onSave = () => {
    editContainer({ ..._box, updatedAt: +Date.now() });
    setEditView(false);
  };

  const resetItem = () => {
    setUpdatedBox(box);
    setEditView(false);
  };

  const handleReadQRCode = () => {
    if (isLocation) return;
    setModalContent(<QRDecode onSuccess={handleChangeQRCode} />);
    setTimeout(() => {
      openModal();
      const listener = () => {
        setModalContent(null);
        removeCloseModalListener(listener);
      };
      addCloseModalListener(listener);
    }, 50);
  };

  const handleChangeQRCode = async (containerId, qrStr) => {
    // check if the qrStr is not already in use
    let c = state.containers[containerId];
    if (containerId === box.id) {
      addMessage('This QR code is used for this box!', 200);
      setModalContent(null);
      return;
    }
    if (c) {
      addMessage(
        'Cannot use this QR code. It is already in assigned to another box.'
      );
      setModalContent(null);
      return;
    }
    setWorking(true);
    // change the qrStr of the current box and its ID
    const oldId = _box.id;
    editContainer({
      ..._box,
      id: containerId,
      qrCodeStr: qrStr,
      updatedAt: +Date.now(),
    });
    await defer(100);
    setEditView(false);

    // change items and box pointers
    const updatedItemList = (items || []).map((_item) => ({
      ..._item,
      container: containerId,
    }));
    const updatedBoxList = (boxes || []).map((__box) => ({
      ...__box,
      container: containerId,
    }));
    if (updatedItemList.length) editItems(updatedItemList);
    if (updatedBoxList.length) editContainer(updatedBoxList);
    await defer(100);

    log('new id', containerId, 'old id', oldId);
    setModalContent(null);

    //delete the old id of the box
    deleteContainer({ id: oldId });
    setWorking(false);
    await defer(200);
    navigate(`/boxes/${containerId}?tab=1`, { replace: true });
  };

  return (
    <>
      <div
        className={classNames(
          'z-50 absolute inset-0 bg-white/50 dark:bg-black/50',
          navigation.state === 'loading' || working ? 'block' : 'hidden'
        )}
      />
      {_box && _box.name && (
        <h2 className="text-2xl mt-4 text-center dark:text-gray-200">
          {_box.name}
        </h2>
      )}

      <div className="mt-4 mb-4 flex gap-4">
        <ModalButton2 red onClick={handleEmptyAndDelete}>
          {items.length > 0 ? 'Empty contents and ' : ''}Delete
        </ModalButton2>
        {/*<ModalButton2 disabled>Transfer contents and Delete</ModalButton2>*/}
      </div>

      {!isLocation && (
        <InputLine label="QR code">
          <div className="mt-4 mb-2 flex flex-col items-center justify-center gap-2">
            {profileUseQrCode ||
            (_box?.qrCodeStr && _box?.useQrCode !== false) ? (
              <>
                <QRCodeComponent
                  value={_box.qrCodeStr}
                  className="w-[180px] h-[180px] dark:text-gray-100"
                  rectClassName="fill-white dark:fill-gray-900"
                />
                {advanced && (
                  <ModalButton2 onClick={handleReadQRCode}>
                    Use a new QR Code
                  </ModalButton2>
                )}
                <ModalButton2
                  onClick={() => {
                    setUpdatedBox({ ..._box, useQrCode: false });
                    setEditView(true);
                  }}
                >
                  Use ID Numbers
                </ModalButton2>
              </>
            ) : (
              <ModalButton2 onClick={generateOrShowQRCode}>
                {_box?.qrCodeStr ? 'Use' : 'Generate'} QR Code
              </ModalButton2>
            )}
          </div>
        </InputLine>
      )}

      <InputLine label={`${type}'s name`} htmlFor="name">
        <Input
          id="name"
          name="name"
          value={_box?.name || ''}
          onChange={handleChange}
          placeholder="Enter the box / location name"
          viewMode={!editView}
          required
        />
      </InputLine>

      {(_box?.idNumber || _box?.useQrCode === false) && (
        <InputLine label={`ID number`} htmlFor="idNumber">
          <Input
            id="idNumber"
            name="idNumber"
            value={_box?.idNumber || ''}
            onChange={handleChangeIdNumber}
            placeholder="Enter the id number"
            viewMode={!editView}
            maxLength={4}
          />
        </InputLine>
      )}

      <InputLine label="Icon">
        <div className="flex gap-2 my-2 flex-wrap">{icons}</div>
      </InputLine>

      <InputLine label="Notes" htmlFor="notes">
        <Input
          id="notes"
          name="notes"
          type="textarea"
          value={_box?.notes || ''}
          onChange={handleChange}
          placeholder="Other notes about this box"
          viewMode={!editView}
        />
      </InputLine>

      <Parent
        _box={_box}
        containers={state.containers}
        editView={editView}
        options={superContainers}
        handleChangeField={handleChangeField}
        field="container"
        emptyPlaceholder="Not inside a box container"
        label="Inside box container"
        advanced={advanced}
      />

      <Parent
        _box={_box}
        containers={state.containers}
        editView={editView}
        options={locations}
        handleChangeField={handleChangeField}
        field="location"
        emptyPlaceholder="Not at a particular location"
        label="At Location"
        advanced={advanced}
      />

      <div
        className={classNames(
          'flex gap-4',
          'sticky -bottom-4 py-4 px-5 -mx-5 -mb-4'
          // 'bg-gradient-to-t from-black/10 to-black/0'
        )}
      >
        {editView ? (
          <>
            {' '}
            <ModalButton green onClick={onSave}>
              Update
            </ModalButton>
            <ModalButton2 onClick={resetItem}>Cancel</ModalButton2>
          </>
        ) : (
          <ModalButton blue onClick={() => setEditView(true)}>
            Edit
          </ModalButton>
        )}
      </div>
    </>
  );
}

function Parent({
  _box,
  containers,
  editView,
  options,
  label = 'Inside box container',
  emptyPlaceholder = 'Not inside a box container or location',
  handleChangeField,
  field = 'container',
  advanced = false,
}) {
  const isContainer = _box?.type === BOX_TYPES.superContainer;
  const isLocation = _box?.type === BOX_TYPES.location;

  if (isLocation) return null;

  if (field === 'container' && isContainer && !advanced) {
    return null;
  }

  const id = _box?.[field];
  const value = containers[id];

  const Icon = nameToElementMap[value?.icon];
  return (
    <InputLine label={label}>
      {editView ? (
        <div className="mt-2">
          <Select
            name="container"
            inputId="container"
            options={options}
            value={value}
            onChange={handleChangeField(field)}
            placeholder={
              editView ? 'Select a box container' : 'No box container selected'
            }
            {...SELECT_PROPS}
          />
        </div>
      ) : value ? (
        <div className="flex flex-wrap gap-2 mt-2 justify-center">
          <TagLink to={`/boxes/${value.id}`}>
            {Icon && <Icon className="w-12 h-12 mx-1" />}
            <span className="mx-1 text-xl">{value.name}</span>
          </TagLink>
        </div>
      ) : (
        <div
          className={classNames(
            'mt-1 py-2 text-lg border-b dark:text-gray-100 dark:border-gray-700',
            'italic text-gray-400 text-center w-full'
          )}
        >
          {emptyPlaceholder}
        </div>
      )}
    </InputLine>
  );
}

function TagLink({ children, className, to }) {
  return (
    <Link
      tabIndex={-1}
      className={classNames(
        'p-1.5 border rounded-lg inline-flex items-center justify-center',
        'text-orange-400 border-orange-500 dark:text-orange-500 dark:border-orange-400 hover:bg-orange-100 dark:hover:bg-orange-900',
        className
      )}
      to={to}
    >
      {children}
    </Link>
  );
}
