import React, { useEffect, useState, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { PlusIcon } from '../icons';
import { classNames, defer } from '../../utils';
import useDataService from '../../hooks/useDataService';
import { tabs } from './common';

function BottomBar() {
  const {
    ui: { isPricingPage },
    setUIKV: set,
  } = useDataService();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/pricing') set('isPricingPage', true);
    else set('isPricingPage', false);
  }, [location, set]);

  return (
    <>
      <div
        className={classNames(
          'box-border md:hidden print:hidden sticky bottom-0 w-full h-[65px] flex-shrink-0',
          'bg-white dark:bg-gray-900',
          isPricingPage ? 'hidden' : ''
        )}
      >
        <AddButton />
        <div
          className={classNames(
            'box-border border-t border-gray-200 h-full relative z-10',
            'border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-900'
          )}
        >
          <nav className="box-border -mb-px flex h-full" aria-label="Tabs">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                className={({ isActive /*, isPending, isTransitioning*/ }) =>
                  classNames(
                    isActive
                      ? 'border-orange-500 text-orange-600 dark:text-orange-500'
                      : 'border-transparent text-gray-500 hover:border-gray-300 dark:hover:border-gray-500 hover:text-gray-700 dark:hover:text-gray-400',
                    'box-border w-1/3 h-full pb-2.5 border-t-2 px-2 text-center text-xs font-medium inline-flex flex-col justify-center items-center'
                  )
                }
              >
                {tab.icon}
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
}

const AddButton = () => {
  const {
    ui: { isModalOpen, modalContent, modals: initModals },
    openModal,
    setModalContent,
  } = useDataService();

  const [showOptions, setShowOptions] = useState(false);
  const modals = useMemo(
    () => (Array.isArray(initModals) ? initModals : []),
    [initModals]
  );
  useEffect(() => {
    if (modals.length === 0) setShowOptions(false);
  }, [modals]);

  let isShown = !!modalContent;
  let onClick = () => openModal();
  let optionOnClick = () => async (e) => e.stopPropagation();

  if (modals.length > 0) {
    isShown = true;
    onClick = () => setShowOptions(!showOptions);
    optionOnClick = (option) => async (e) => {
      e.stopPropagation();
      setShowOptions(false);
      setModalContent(option.modal);
      await defer(50);
      openModal();
    };
  }

  return (
    <button
      onClick={onClick}
      className={classNames(
        'absolute bottom-[85px] right-6 text-white w-14 h-14 shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-center justify-center',
        'transition duration-200 ease-in-out rounded-full',
        'bg-orange-400 dark:bg-orange-500 hover:bg-orange-500 dark:hover:bg-orange-600 focus:ring-orange-300',
        isModalOpen
          ? 'translate-y-[-40px] scale-150 opacity-0'
          : isShown
            ? 'opacity-100'
            : 'translate-y-[40px] opacity-0 pointer-events-none'
      )}
    >
      <PlusIcon
        className={classNames(
          'w-6 h-6 stroke-[3px]',
          'transition-transform',
          showOptions ? 'rotate-[45deg]' : ''
        )}
      />

      {modals.length > 0 && (
        <OptionContainer showOptions={showOptions}>
          {modals.map((m, i) => (
            <Option key={`${m.label}-${m.id}-${i}`} onClick={optionOnClick(m)}>
              {m.label}
            </Option>
          ))}
        </OptionContainer>
      )}
    </button>
  );
};

const Option = ({ children, onClick }) => (
  <div
    tabIndex={-1}
    onClick={onClick}
    className="py-1 px-3 bg-blue-300 rounded-lg cursor-pointer whitespace-nowrap"
  >
    {children}
  </div>
);

const OptionContainer = ({ children, showOptions }) => (
  <div
    className={classNames(
      'absolute right-0 bottom-[100%] pb-4 flex flex-col gap-4 font-medium items-end',
      'transition duration-200 ease-in-out',
      showOptions
        ? 'opacity-100'
        : 'translate-y-1/3 opacity-0 pointer-events-none'
    )}
  >
    {' '}
    {children}
  </div>
);

export default BottomBar;
